import { DocumentType } from "../../../../types";
import { Chip } from "@mui/material";
import React from "react";
import { handleDocumentButtonClick } from "../../utils/document.utils";
import DescriptionIcon from "@mui/icons-material/Description";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__createDocument } from "../../../../libraries/enums/permissionRights";

const DocumentChip = (props: {
  document: DocumentType;
  size?: "small" | "medium" | undefined;
  selectedDocumentDefault?: { unitId: string };
}) => {
  const { document, selectedDocumentDefault, size = "small" } = props;

  const dispatch = useAppDispatch();
  const rightCreateDocument = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createDocument,
    })
  );

  const status =
    document.type === "document" && !!document.files[0].id
      ? "documented"
      : "documentTemplate";

  const handleRowClick = (doc: DocumentType) => {
    if (doc.type === "documentTemplate" && !rightCreateDocument) {
      return;
    }
    if (!!selectedDocumentDefault && !!selectedDocumentDefault.unitId) {
      dispatch(handleDocumentButtonClick(doc, selectedDocumentDefault.unitId));
    } else {
      dispatch(handleDocumentButtonClick(doc));
    }
  };

  return (
    <Chip
      onClick={() => {
        handleRowClick(document);
      }}
      sx={{
        cursor: "pointer",
        my: 0.25,
        opacity: status === "documented" ? 1 : 0.3,
      }}
      color={"primary"}
      icon={
        status === "documented" ? (
          <DescriptionIcon />
        ) : (
          <DescriptionOutlinedIcon />
        )
      }
      label={document.title}
      size={size}
    />
  );
};

export default DocumentChip;
