import { v4 as uuidv4 } from "uuid";
import {
  AssetManufacturerRelation,
  AssetType,
  AssetUnitRelation,
} from "../../../types/asset.types";
import { UnitType } from "../../../types";
import { Manufacturer } from "../../../types/company.types";

export const buildAsset = () => {
  const now = new Date();
  const asset: AssetType = {
    id: uuidv4(),
    createdAt: now.toISOString(),
    title: "",
    category: null,
    description: null,
    enabled: true,
    inventoryNumber: null,
    origin: null,
    ownershipType: { id: "own" },
    producerNumber: null,
    serialNumber: null,
    status: { id: "new" },
    qrCodeRelations: [],
    unitRelations: [],
    files: [],
    manufacturerRelations: [],
    supplierRelations: [],
    assetMovements: [],
    deliveryDate: null,
    deliveryDateFormat: null,
  };

  return asset;
};

export const addUnitIdIntoAsset = (asset: AssetType, unitId: string) => {
  const assetUnitRelation: AssetUnitRelation = {
    confirmed: true,
    description: "",
    enabled: false,
    placement: "",
    date: new Date().toISOString(),
    id: uuidv4(),
    unit: { id: unitId },
  };

  return {
    ...asset,
    unitRelations: [...asset.unitRelations, assetUnitRelation],
  };
};

export const addUnitIntoAsset = (asset: AssetType, unit: UnitType) => {
  return addUnitIdIntoAsset(asset, unit.id);
};

export const addManufacturerIntoAsset = (
  asset: AssetType,
  manufacturer: Manufacturer
) => {
  const relation: AssetManufacturerRelation = {
    description: "",
    enabled: true,
    id: uuidv4(),
    manufacturer: manufacturer,
    date: new Date().toISOString(),
  };
  asset.manufacturerRelations.push(relation);

  return asset;
};
