import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  selectHasSelectedAssetMovementDto,
  selectSelectedAssetMovementDto,
  selectSelectedAssetMovementDtoMode,
} from "../../selectors/assetMovement.selector";
import assetMovementSlice from "../../slices/assetMovement.slice";
import CloseButton from "../../../common/components/CloseButton/CloseButton";
import React, { useState } from "react";
import { selectSelectedAsset } from "../../selectors/asset.selector";
import { useTheme } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";
import FormFieldAutocomplete from "../../../common/components/Form/FormFieldAutocomplete";
import GridField from "../../../common/components/Grid/GridField";
import assetSlice from "../../slices/asset.slice";
import { selectFlattenUnits } from "../../../enum/selectors/enumSelectors";
import FormFieldAutocompleteUnit from "../../../common/components/Form/FormFieldAutocompleteUnit";
import {
  ASSET_MOVEMENT_DIRECTION_CODE__out,
  getAssetMovementDirections,
} from "../../../../libraries/enums/assetMovementDirections";
import {
  ASSET_MOVEMENT_REASON_CODE,
  getAssetMovementReasonLabel,
  getAssetMovementReasons,
} from "../../../../libraries/enums/assetMovementReasons";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__updateAssetMovement } from "../../../../libraries/enums/permissionRights";

const FieldDirection = () => {
  const dispatch = useAppDispatch();
  const assetMovementDto = useAppSelector(selectSelectedAssetMovementDto);
  const options = getAssetMovementDirections().map((d) => ({
    id: d.code,
    title: d.label + " " + d.preposition,
  }));

  return (
    <FormFieldAutocomplete
      property={"directionId"}
      label={"Směr"}
      defaultOptionId={assetMovementDto?.directionId || null}
      creatable={false}
      options={options}
      onChange={(payload) => {
        dispatch(
          assetMovementSlice.actions.selectedAssetMovementDtoPropertiesSet(
            payload
              .filter((p) => !!p)
              .map((p) => ({
                property: p?.property || "",
                value: p?.value?.id || "",
              }))
          )
        );
      }}
    />
  );
};

const FieldUnitStoreNumber = () => {
  const label = "Středisko";
  const property = "unitStoreNumber";

  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => selectFlattenUnits(state));
  const assetMovementDto = useAppSelector(selectSelectedAssetMovementDto);
  const selectedUnitId =
    options.find(
      (o) => o.storeNumber === assetMovementDto?.unitStoreNumber || ""
    )?.id || null;

  const handleChange = (payload: any) => {
    dispatch(
      assetMovementSlice.actions.selectedAssetMovementDtoPropertiesSet(
        payload.map((p: any) => ({
          property: p.property,
          value: p.option.storeNumber,
        }))
      )
    );
  };

  return (
    <FormFieldAutocompleteUnit
      label={label}
      property={property}
      defaultOptionId={selectedUnitId}
      creatable={false}
      options={options}
      onChange={handleChange}
    />
  );
};

const FieldReason = () => {
  const label = "Důvod pohybu zařízení";
  const property = "reasonId";

  const dispatch = useAppDispatch();
  const assetMovementDto = useAppSelector(selectSelectedAssetMovementDto);

  const handleChange = (event: any) => {
    dispatch(
      assetMovementSlice.actions.selectedAssetMovementDtoPropertiesSet([
        {
          property: property,
          value: event.target.value,
        },
      ])
    );
  };

  const value =
    assetMovementDto?.reasonId || ASSET_MOVEMENT_REASON_CODE.UNSPECIFIED;

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label="Důvod pohybu zařízení"
        onChange={handleChange}
      >
        {getAssetMovementReasons().map((code) => {
          return (
            <MenuItem key={code} value={code}>
              {getAssetMovementReasonLabel(code as any)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const AssetMovementDialog = () => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useAppDispatch();
  const open = useAppSelector(selectHasSelectedAssetMovementDto);
  const asset = useAppSelector(selectSelectedAsset);
  const assetMovementDto = useAppSelector(selectSelectedAssetMovementDto);
  const assetMovementDtoMode = useAppSelector(
    selectSelectedAssetMovementDtoMode
  );
  const rightUpdateAssetMovement = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__updateAssetMovement,
    })
  );

  const [errors, setErrors] = useState<any>([]);

  const handleClose = () => {
    dispatch(assetMovementSlice.actions.selectedAssetMovementDtoSet(null));
  };

  const validate = () => {
    // const state = store.getState();
    // if (!!asset) {
    //   if (!asset.input.unitId) {
    //     errors.push("Středisko musí být vyplněno.");
    //   }
    //   if (!asset.title) {
    //     errors.push("Pole Název musí být vyplněno.");
    //   }
    // }

    return [];
  };

  const handleSubmit = () => {
    if (!!asset) {
      const errors = validate();
      if (errors.length === 0) {
        dispatch(
          assetSlice.actions.assetAssetMovementSubmit({
            assetId: asset.id,
            assetMovementDto,
          })
        );
        handleClose();
      } else {
        setErrors(errors);
      }
    }
  };

  if (!asset) {
    return <></>;
  }

  return (
    <Dialog
      open={open && !!asset}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="sm"
      fullScreen={mdUp ? false : true}
      // sx={{
      //   "& .MuiDialog-container": {
      //     alignItems: "flex-start",
      //   },
      // }}
    >
      <DialogTitle sx={{ p: 0 }}>
        <Box
          sx={{
            m: 2,
            display: "flex",
            alignAssets: "center",
          }}
        >
          <Box display="flex" alignItems="center">
            {assetMovementDtoMode === "create" ? "Nový pohyb" : "Upravit pohyb"}
          </Box>
          <CloseButton onClick={handleClose} />
        </Box>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ py: 3 }}>
        <Grid container spacing={2}>
          <GridField gridProps={{ xs: 12 }} component={FieldDirection} />
          <GridField gridProps={{ xs: 12 }} component={FieldUnitStoreNumber} />
          {rightUpdateAssetMovement &&
            (assetMovementDto?.directionId || -1) ===
              ASSET_MOVEMENT_DIRECTION_CODE__out && (
              <GridField gridProps={{ xs: 12 }} component={FieldReason} />
            )}
        </Grid>
        {errors.length > 0 && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {errors.join(" ")}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"primary"}
          startIcon={<DoneIcon />}
          onClick={() => {
            handleSubmit();
          }}
        >
          {assetMovementDtoMode === "create"
            ? "Vytvořit pohyb"
            : "Uložit pohyb"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
