import { useAppDispatch, useAppSelector } from "../../../../app/store";
import React, { useEffect, useState } from "react";
import {
  selectHasSelectedAsset,
  selectSelectedAssetMode,
} from "../../selectors/asset.selector";
import assetSlice from "../../slices/asset.slice";
import AssetDetailModeRead from "./AssetDetailModeRead/AssetDetailModeRead";
import AssetDetailModeUpdate from "./AssetDetailModeUpdate/AssetDetailModeUpdate";

const AssetDetail = () => {
  const dispatch = useAppDispatch();

  const hasAsset = useAppSelector(selectHasSelectedAsset);
  const mode = useAppSelector(selectSelectedAssetMode);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    if (["read", "create"].includes(mode || "")) {
      setOpen(false);
      setTimeout(() => {
        dispatch(assetSlice.actions.selectedAssetSet(null));
        dispatch(assetSlice.actions.selectedAssetModeSet(null));
      }, 250);
    }
    if (["update"].includes(mode || "")) {
      setTimeout(() => {
        dispatch(assetSlice.actions.selectedAssetModeSet("read"));
      }, 250);
    }
  };

  useEffect(() => {
    if (hasAsset) {
      setOpen(true);
    }
  }, [hasAsset]);

  return (
    <>
      <AssetDetailModeUpdate
        open={open && (mode === "create" || mode === "update")}
        handleClose={handleClose}
      />
      <AssetDetailModeRead
        open={open && mode === "read"}
        handleClose={handleClose}
      />
    </>
  );
};

export default AssetDetail;
