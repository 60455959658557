import { useAppSelector } from "../../../../app/store";
import { selectRevisionById } from "../../selectors/revisionSelectors";
import { Box } from "@mui/material";
import React from "react";
import ChipUserType from "../../../issue/components/Chip/ChipUserType";
import { USER_TYPE_CODE__revisionCompany } from "../../../../libraries/enums/userTypes";
import { selectRevisionCompanyById } from "../../../enum/selectors/enumSelectors";

const FieldRevisionCompany = (props: any) => {
  const { revisionId, isDetail = false } = props;

  const revision = useAppSelector((state) =>
    selectRevisionById(state, revisionId)
  );

  const revisionCompany = useAppSelector((state) =>
    !!revision
      ? selectRevisionCompanyById(state, revision.revisionCompanyId)
      : null
  );

  if (!revision || !revisionCompany) {
    return <></>;
  }

  return (
    <Box sx={{ p: 2 }}>
      {!!revisionCompany && (
        <Box sx={{ mb: 1 }}>
          <ChipUserType
            userTypeCode={USER_TYPE_CODE__revisionCompany}
            label={revisionCompany.title}
          />
        </Box>
      )}
    </Box>
  );
};

export const fieldRevisionCompany = {
  code: "fieldRevisionCompany",
  label: "Revizní společnost",
  component: FieldRevisionCompany,
};
