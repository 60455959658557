import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__deleteDocument } from "../../../../libraries/enums/permissionRights";
import React, { useState } from "react";
import documentSlice from "../../slices/document.slice";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

const DocumentActionDelete = (props: any) => {
  const { document } = props;

  const dispatch = useAppDispatch();
  const hasPermissionRightDeleteDocument = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__deleteDocument,
    })
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch(documentSlice.actions.documentDelete(document));
    handleClose();
  };

  const status =
    document.type === "document" && !!document.path
      ? "documented"
      : "documentTemplate";

  if (status === "documented" && hasPermissionRightDeleteDocument) {
    return (
      <>
        <Button
          onClick={() => {
            handleOpen();
          }}
          color={"error"}
          size={"small"}
        >
          <DeleteIcon fontSize={"small"} />
        </Button>
        <Dialog
          open={open}
          onClose={() => {
            handleClose();
          }}
        >
          <DialogContent>
            Opravdu chcete odstranit dokument <strong>{document.title}</strong>?
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              color={"inherit"}
              size={"small"}
              variant={"contained"}
              startIcon={<CloseIcon />}
              onClick={() => {
                handleClose();
              }}
            >
              Neodstraňovat
            </Button>
            <Button
              color={"error"}
              size={"small"}
              variant={"contained"}
              startIcon={<DeleteIcon />}
              onClick={() => {
                handleDelete();
              }}
            >
              Ano, chci odstranit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return <></>;
};

export default DocumentActionDelete;
