import { useAppSelector } from "../../../../app/store";
import { Button } from "@mui/material";
import { selectIssueFilterProperty } from "../../selectors/issueSelectors";
import { loadAuthToken } from "../../../auth/utils/utils";
import { ApiQueryParams } from "../../../../types";
import { buildQueryString } from "../../../common/utils/api";
import GetAppIcon from "@mui/icons-material/GetApp";
import axios from "axios";

const IssueExportButton = () => {
  const showClosed = useAppSelector((state) =>
    selectIssueFilterProperty(state, { property: "showClosed" })
  );
  const showManagedByFieldManagers = useAppSelector((state) =>
    selectIssueFilterProperty(state, { property: "showManagedByFieldManagers" })
  );
  const serviceCompanyIds = useAppSelector((state) =>
    selectIssueFilterProperty(state, { property: "serviceCompanyIds" })
  );

  const handleExport = () => {
    const authToken = loadAuthToken();
    const queryParams: ApiQueryParams = {
      showClosed: {
        operation: "=",
        value: showClosed,
      },
      showManagedByFieldManagers: {
        operation: "=",
        value: showManagedByFieldManagers,
      },
      serviceCompanyId: {
        operation: "in",
        value: serviceCompanyIds,
      },
    };
    const query = buildQueryString(queryParams);
    axios
      .get(process.env.REACT_APP_ENDPOINT_SERVER + "/export/issues?" + query, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data);

        const dt = new Date();

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          "Fixnote - Report požadavků z " + dt.toLocaleDateString() + ".xlsx"
        ); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<GetAppIcon />}
        // sx={{
        //   mt: 2
        // }}
        // fullWidth
        onClick={() => {
          handleExport();
        }}
      >
        Exportovat
      </Button>
    </>
  );
};

export default IssueExportButton;
