import { ApiQueryParams } from "../../../types";

export const buildQueryString = (queryParams: ApiQueryParams): string => {
  let queryStrings: string[] = [];
  Object.entries(queryParams).forEach((p) => {
    if (p[1].operation === "in" && Array.isArray(p[1].value)) {
      p[1].value.forEach((v) => {
        queryStrings.push(p[0] + "[]=" + v);
      });
    } else {
      queryStrings.push(p[0] + "=" + p[1].value);
    }
  });

  return queryStrings.join("&");
};
