import { AnyAction } from "@reduxjs/toolkit";
import enumSlice from "../slices/enumSlice";
import { ListenerApiType } from "../../../types/api.types";
import { enumGetAll } from "../../../api/api";

export const effectEnumsFetch = async (
  action: AnyAction,
  listenerApi: ListenerApiType
) => {
  const requestDefs = [
    { path: "/brands", actionName: "brandSet" },
    { path: "/serviceCompanies", actionName: "serviceCompanySet" },
    { path: "/serviceTechnicians", actionName: "serviceTechnicianSet" },
    { path: "/units", actionName: "unitSet" },
    { path: "/revisionCompanies", actionName: "revisionCompanySet" },
    { path: "/revisionSubjects", actionName: "revisionSubjectSet" },
  ];
  requestDefs.forEach((requestDef) => {
    enumGetAll({ path: requestDef.path }).then((response) => {
      if (response.status === 200) {
        // @ts-ignore
        const action = enumSlice.actions[requestDef.actionName];
        // @ts-ignore
        listenerApi.dispatch(action(response.data));
      }
    });
  });
};

export const effectEnumsFetch_serviceTechnician = async (
  action: AnyAction,
  listenerApi: ListenerApiType
) => {
  const requestDefs = [
    { path: "/serviceTechnicians", actionName: "serviceTechnicianSet" },
  ];
  requestDefs.forEach((requestDef) => {
    enumGetAll({ path: requestDef.path }).then((response) => {
      if (response.status === 200) {
        // @ts-ignore
        const action = enumSlice.actions[requestDef.actionName];
        // @ts-ignore
        listenerApi.dispatch(action(response.data));
      }
    });
  });
};
