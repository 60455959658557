import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Nullable, ProfileType } from "../../../types";

type StateType = {
  authStatus: "init" | "loading" | "success" | "failure";
  profile: Nullable<ProfileType>;
};

const initialState: StateType = {
  authStatus: "init",
  profile: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    statusSet: (
      state: StateType,
      action: PayloadAction<"init" | "loading" | "success" | "failure">
    ) => {
      state.authStatus = action.payload;
    },
    profileSet: (
      state: StateType,
      action: PayloadAction<Nullable<ProfileType>>
    ) => {
      state.profile = action.payload;
    },
  },
});

export default authSlice;
