import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type StateType = {
  entryLocation: {
    pathname: string;
    params: {};
  };
};

const initialState: StateType = {
  entryLocation: {
    pathname: "",
    params: {},
  },
};

const shareEntitySlice = createSlice({
  name: "shareEntity",
  initialState: initialState,
  reducers: {
    entryLocationSave: (
      state: StateType,
      action: PayloadAction<{ pathname: string; params: {} }>
    ) => {
      state.entryLocation = action.payload;
    },
  },
});

export default shareEntitySlice;
