import {
  AnyAction,
  createListenerMiddleware,
  ListenerMiddlewareInstance,
} from "@reduxjs/toolkit";
import { ListenerApiType } from "../../../types/api.types";
import revisionSlice from "../slices/revisionSlice";
import {
  effectRevisionDelete,
  effectRevisionListFetch,
  effectSelectedRevisionSubmit,
} from "./revision.effect";

const revisionMiddleware: ListenerMiddlewareInstance =
  createListenerMiddleware();

//inicializační načtení revizí
revisionMiddleware.startListening({
  type: revisionSlice.actions.revisionListFetch.type,
  effect: async (action: AnyAction, listenerApi: ListenerApiType) => {
    const queryParams = {};
    await effectRevisionListFetch(action, listenerApi, {
      queryParams,
      showFetchingIndicator: true,
      resetList: true,
    });
  },
});

// při odeslání revize
revisionMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    return [revisionSlice.actions.selectedRevisionSubmit.type].includes(
      action.type
    );
  },
  effect: async (action, listenerApi) => {
    await effectSelectedRevisionSubmit(action, listenerApi);
  },
});

//při odstraňování revize
revisionMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    return [revisionSlice.actions.revisionDelete.type].includes(action.type);
  },
  effect: async (action, listenerApi) => {
    await effectRevisionDelete(action, listenerApi);
  },
});

export default revisionMiddleware;
