import StorefrontIcon from "@mui/icons-material/Storefront";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import FaceIcon from "@mui/icons-material/Face";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { DesignServices, Visibility } from "@mui/icons-material";

export const USER_TYPE_CODE__headquarters = "headquarters";
export const USER_TYPE_CODE__brandManager = "brandManager";
export const USER_TYPE_CODE__fieldManager = "fieldManager";
export const USER_TYPE_CODE__unit = "unit";
export const USER_TYPE_CODE__serviceCompany = "serviceCompany";
export const USER_TYPE_CODE__serviceTechnician = "serviceTechnician";
export const USER_TYPE_CODE__revisionCompany = "revisionCompany";
export const USER_TYPE_CODE__revisionTechnician = "revisionTechnician";

const USER_TYPES = {
  [USER_TYPE_CODE__headquarters]: {
    code: USER_TYPE_CODE__headquarters,
    icon: CorporateFareIcon,
  },
  [USER_TYPE_CODE__brandManager]: {
    code: USER_TYPE_CODE__brandManager,
    icon: CorporateFareIcon,
  },
  [USER_TYPE_CODE__fieldManager]: {
    code: USER_TYPE_CODE__fieldManager,
    icon: FaceIcon,
  },
  [USER_TYPE_CODE__unit]: {
    code: USER_TYPE_CODE__unit,
    icon: StorefrontIcon,
  },
  [USER_TYPE_CODE__serviceCompany]: {
    code: USER_TYPE_CODE__serviceCompany,
    icon: SettingsIcon,
  },
  [USER_TYPE_CODE__serviceTechnician]: {
    code: USER_TYPE_CODE__serviceTechnician,
    icon: ManageAccountsIcon,
  },
  [USER_TYPE_CODE__revisionCompany]: {
    code: USER_TYPE_CODE__revisionCompany,
    icon: Visibility,
  },
  [USER_TYPE_CODE__revisionTechnician]: {
    code: USER_TYPE_CODE__revisionTechnician,
    icon: DesignServices,
  },
};

export type userTypeCodeType = keyof typeof USER_TYPES;

export const getUserTypeByCode = (code: userTypeCodeType) => {
  return USER_TYPES[code];
};
