import {
  createListenerMiddleware,
  ListenerMiddlewareInstance,
} from "@reduxjs/toolkit";
import { effectEnumsFetch } from "./enum.effect";
import enumSlice from "../slices/enumSlice";

const enumMiddleware: ListenerMiddlewareInstance = createListenerMiddleware();

//inicializační načtení číselníků
enumMiddleware.startListening({
  type: enumSlice.actions.enumsFetch.type,
  effect: effectEnumsFetch,
});

export default enumMiddleware;
