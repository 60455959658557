import axios, { AxiosRequestConfig } from "axios";
import { loadAuthToken } from "../features/auth/utils/utils";
import { buildQueryString } from "../features/common/utils/api";

const baseUrl = process.env.REACT_APP_ENDPOINT_SERVER;

const buildHeaders = () => {
  const authToken = loadAuthToken();
  return { Authorization: `Bearer ${authToken}` };
};

const enumGetAll = ({ path }: { path: string }): Promise<Response> => {
  return axios.get(baseUrl + path, {
    headers: buildHeaders(),
  });
};

const issueGetAll = (props: any): Promise<Response> => {
  const query = buildQueryString(props.queryParams);
  return axios.get(baseUrl + "/issues?" + query, {
    headers: buildHeaders(),
  });
};

const issuePost = (props: any): Promise<Response> => {
  return axios.post(baseUrl + "/issues", props.data, {
    headers: buildHeaders(),
  });
};

const issueCommentPost = (props: any): Promise<Response> => {
  return axios.post(
    baseUrl + "/issues/" + props.issue.id + "/comment",
    props.data,
    {
      headers: buildHeaders(),
    }
  );
};

const notificationIssueCommentSendPost = (props: any): Promise<Response> => {
  return axios.post(baseUrl + "/notifications/issue-comment/send", props.data, {
    headers: buildHeaders(),
  });
};

const serviceCompaniesByIssuesCountGet = (props: any): Promise<Response> => {
  return axios.get(
    baseUrl + "/units/" + props.unitId + "/serviceCompaniesByIssuesCount",
    {
      headers: buildHeaders(),
    }
  );
};

const revisionGetAll = (props: any): Promise<Response> => {
  const query = ""; //buildQueryString(props.queryParams);
  return axios.get(baseUrl + "/revisions?" + query, {
    headers: buildHeaders(),
  });
};

const revisionGetOne = (props: any): Promise<Response> => {
  const { revisionId } = props;
  return axios.get(baseUrl + "/revisions/" + revisionId, {
    headers: buildHeaders(),
  });
};

const revisionPost = (props: any): Promise<Response> => {
  return axios.post(baseUrl + "/revisions", props.data, {
    headers: buildHeaders(),
  });
};

const revisionTry = (props: any): Promise<Response> => {
  return axios.post(baseUrl + "/revisions/try", props.data, {
    headers: buildHeaders(),
  });
};

const revisionDelete = (props: any): Promise<Response> => {
  const { revisionId } = props;
  return axios.delete(baseUrl + "/revisions/" + revisionId, {
    headers: buildHeaders(),
  });
};

const documentGetAll = (props: any): Promise<Response> => {
  const query = buildQueryString(props.queryParams);
  return axios.get(baseUrl + "/documents" + (!!query ? "?" + query : ""), {
    headers: buildHeaders(),
  });
};

const documentPost = (props: any): Promise<Response> => {
  return axios.post(baseUrl + "/documents", props.data, {
    headers: buildHeaders(),
  });
};

const documentDelete = (props: any): Promise<Response> => {
  const { documentId } = props;
  return axios.delete(baseUrl + "/documents/" + documentId, {
    headers: buildHeaders(),
  });
};

const assetGetAll = (props: any): Promise<Response> => {
  const queryParams = props.queryParams;
  const query = buildQueryString(queryParams);
  return axios.get(baseUrl + "/assets", {
    headers: buildHeaders(),
  });
};

const assetPost = (props: any): Promise<Response> => {
  return axios.post(baseUrl + "/assets", props.data, {
    headers: buildHeaders(),
  });
};

const manufacturerGetAll = (): Promise<Response> => {
  return axios.get(baseUrl + "/manufacturers", {
    headers: buildHeaders(),
  });
};

const supplierGetAll = (): Promise<Response> => {
  return axios.get(baseUrl + "/suppliers", {
    headers: buildHeaders(),
  });
};

const qrCodeCheckPost = (props: any): Promise<Response> => {
  return axios.post(baseUrl + "/qr-code/check", props.data, {
    headers: buildHeaders(),
  });
};

const assetUpsertAssetMovementPost = (props: any): Promise<Response> => {
  return axios.post(
    baseUrl + "/assets/" + props.assetId + "/asset-movements",
    props.data,
    {
      headers: buildHeaders(),
    }
  );
};

const assetUnitRelationPresenceUpsertPost = (props: any): Promise<Response> => {
  return axios.post(
    baseUrl + "/asset-unit-relations/" + props.assetUnitRelationId,
    props.data,
    {
      headers: buildHeaders(),
    }
  );
};

const assetFinderSearchPost = (props: any): Promise<Response> => {
  const config: AxiosRequestConfig = props.data.anonymous
    ? {}
    : {
        headers: buildHeaders(),
      };
  return axios.post(baseUrl + "/asset-finder/search", props.data, config);
};

export {
  enumGetAll,
  issueGetAll,
  issuePost,
  issueCommentPost,
  serviceCompaniesByIssuesCountGet,
  notificationIssueCommentSendPost,
  revisionGetAll,
  revisionGetOne,
  revisionPost,
  revisionTry,
  revisionDelete,
  documentGetAll,
  documentPost,
  documentDelete,
  assetGetAll,
  assetPost,
  manufacturerGetAll,
  supplierGetAll,
  qrCodeCheckPost,
  assetUpsertAssetMovementPost,
  assetFinderSearchPost,
  assetUnitRelationPresenceUpsertPost,
};
