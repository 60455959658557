//@ts-nocheck

import React from "react";
import { Chip } from "@mui/material";
import { getRevisionExpirationStatusByCode } from "../../../../libraries/enums/revisionExpirationStatuses";

const ChipRevisionExpirationStatus = (props) => {
  const {
    revisionExpirationStatusCode,
    label,
    active = true,
    size = "medium",
  } = props;

  const expirationStatus = getRevisionExpirationStatusByCode(
    revisionExpirationStatusCode
  );

  if (!expirationStatus) {
    return <></>;
  }

  return (
    <Chip
      icon={React.createElement(expirationStatus.icon)}
      label={label}
      size={size}
      sx={{
        bgcolor: expirationStatus.bgcolor,
        opacity: active ? 1 : 0.2,
        transition: "all 250ms",
      }}
    />
  );
};

export default ChipRevisionExpirationStatus;
