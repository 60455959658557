//@ts-nocheck

import { Box } from "@mui/material";
import React from "react";
import ChipUserType from "../../Chip/ChipUserType";
import { useAppSelector } from "../../../../../app/store";
import { selectIssueById } from "../../../selectors/issueSelectors";
import {
  USER_TYPE_CODE__serviceCompany,
  USER_TYPE_CODE__serviceTechnician,
} from "../../../../../libraries/enums/userTypes";
import {
  selectServiceCompanyById,
  selectServiceTechnicianById,
} from "../../../../enum/selectors/enumSelectors";

const FieldService = (props) => {
  const { issueId } = props;

  const issue = useAppSelector((state) => selectIssueById(state, issueId));
  const serviceCompany = useAppSelector((state) =>
    selectServiceCompanyById(state, issue.serviceCompanyId)
  );
  const serviceTechnician = useAppSelector((state) =>
    selectServiceTechnicianById(state, issue.serviceTechnicianId)
  );

  return (
    <Box sx={{ p: 2 }}>
      {!!serviceCompany && (
        <Box sx={{ mb: 1 }}>
          <ChipUserType
            userTypeCode={USER_TYPE_CODE__serviceCompany}
            label={serviceCompany.title}
          />
        </Box>
      )}
      {!!serviceTechnician && (
        <Box sx={{ mb: 1 }}>
          <ChipUserType
            userTypeCode={USER_TYPE_CODE__serviceTechnician}
            label={serviceTechnician.title}
          />
        </Box>
      )}
    </Box>
  );
};

export const fieldService = {
  code: "fieldService",
  label: "Servis",
  valueGetterFn: (issueId) => {
    return "";
  },
  component: FieldService,
};
