import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";
import { UnitType } from "../../../../types";

const TabInfo = (props: { unit: UnitType }) => {
  const { unit } = props;
  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell variant="head">Adresa:</TableCell>
              <TableCell>
                <Link
                  href={`http://maps.google.com/?q=${
                    unit?.addressText?.trim() || ""
                  }`}
                  target="_blank"
                >
                  {unit.addressText}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">E-mail:</TableCell>
              <TableCell>
                <Link href={`mailto:${unit?.email?.trim() || ""}`}>
                  {unit?.email?.trim() || ""}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Telefon:</TableCell>
              <TableCell>
                <Link href={`tel:${unit?.telephone?.trim() || ""}`}>
                  {unit.telephone}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Otevírací doba:</TableCell>
              <TableCell>{unit.openingHoursText}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Provozovatel:</TableCell>
              <TableCell>{unit.mandantText}</TableCell>
            </TableRow>
            {unit.noteEmailText && (
              <TableRow>
                <TableCell variant="head">Poznámka:</TableCell>
                <TableCell>{unit.noteEmailText}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <iframe
        src={`https://www.google.com/maps?q=${encodeURI(
          unit?.addressText?.trim() || ""
        )}&output=embed`}
        width="600"
        height="350"
        loading="lazy"
        style={{ border: 0, width: "100%", marginTop: 32, borderRadius: 16 }}
      />
    </>
  );
};

export default TabInfo;
