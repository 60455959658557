import {
  AnyAction,
  createListenerMiddleware,
  ListenerMiddlewareInstance,
} from "@reduxjs/toolkit";
import { ListenerApiType } from "../../../types/api.types";
import issueSlice from "../../issue/slices/issueSlice";
import { RootState } from "../../../app/store";
import shareEntitySlice from "../slices/shareEntity.slice";
import {
  selectSelectedIssueMode,
  selectSelectedIssueProperty,
} from "../../issue/selectors/issueSelectors";
import {
  selectSelectedAssetMode,
  selectSelectedAssetProperty,
} from "../../asset/selectors/asset.selector";
import assetSlice from "../../asset/slices/asset.slice";
import { selectProfile } from "../../auth/selectors/authSelectors";

const shareEntityMiddleware: ListenerMiddlewareInstance =
  createListenerMiddleware();

//TODO
//pro zkoušení: VK
// http://localhost:3000/issues?id=e9a5dbd5-6805-46a5-a64e-8acc0072c678&a=1c5dd7a436ae449abaace4ac093657b6
// http://localhost:3000/assets?id=8417379f-f8d2-478a-a456-ed37c41e23ed&a=1c5dd7a436ae449abaace4ac093657b6

//při otevření detailu požadavku
shareEntityMiddleware.startListening({
  predicate: (action, currentState, originalState) => {
    return (
      !!selectSelectedIssueProperty(currentState, { property: "id" }) &&
      selectSelectedIssueMode(currentState as RootState) === "read"
    );
  },
  effect: (action: AnyAction, listenerApi: ListenerApiType) => {
    const state: RootState = listenerApi.getState() as RootState;
    const id = selectSelectedIssueProperty(state, { property: "id" });
    window.history.replaceState(
      null,
      "",
      window.location.pathname + "?id=" + id
    );
  },
});

//při zavření detailu požadavku
shareEntityMiddleware.startListening({
  predicate: (action, currentState, originalState) => {
    const profile = selectProfile(currentState as RootState);
    return (
      !!profile &&
      window.location.pathname === "/issues" &&
      !selectSelectedIssueProperty(currentState, { property: "id" })
    );
  },
  effect: (action: AnyAction, listenerApi: ListenerApiType) => {
    window.history.replaceState(null, "", window.location.pathname);
  },
});

//po nasetování listu požadavků se zobrazí detail požadavku (dle uložené entryLocation)
shareEntityMiddleware.startListening({
  type: issueSlice.actions.issueListSet.type,
  effect: (action: AnyAction, listenerApi: ListenerApiType) => {
    const state: RootState = listenerApi.getState() as RootState;
    const entryLocation = state.shareEntity.entryLocation;
    if (
      entryLocation.pathname !== "" &&
      entryLocation.pathname === window.location.pathname &&
      entryLocation.pathname === "/issues" &&
      entryLocation.params.hasOwnProperty("id")
    ) {
      // @ts-ignore
      const id = entryLocation.params["id"];
      listenerApi.dispatch(issueSlice.actions.selectedIssueSetById(id));
    }
    listenerApi.dispatch(
      shareEntitySlice.actions.entryLocationSave({ pathname: "", params: {} })
    );
  },
});

//při otevření detailu majetku
shareEntityMiddleware.startListening({
  predicate: (action, currentState, originalState) => {
    return (
      !!selectSelectedAssetProperty(currentState, { property: "id" }) &&
      selectSelectedAssetMode(currentState as RootState) === "read"
    );
  },
  effect: (action: AnyAction, listenerApi: ListenerApiType) => {
    const state: RootState = listenerApi.getState() as RootState;
    const id = selectSelectedAssetProperty(state, { property: "id" });
    window.history.replaceState(
      null,
      "",
      window.location.pathname + "?id=" + id
    );
  },
});

//při zavření detailu majetku
shareEntityMiddleware.startListening({
  predicate: (action, currentState, originalState) => {
    const profile = selectProfile(currentState as RootState);
    return (
      !!profile &&
      window.location.pathname === "/assets" &&
      !selectSelectedAssetProperty(currentState, { property: "id" })
    );
  },
  effect: (action: AnyAction, listenerApi: ListenerApiType) => {
    window.history.replaceState(null, "", window.location.pathname);
  },
});

//po nasetování listu majetku se zobrazí detail majetku (dle uložené entryLocation)
shareEntityMiddleware.startListening({
  type: assetSlice.actions.listSet.type,
  effect: (action: AnyAction, listenerApi: ListenerApiType) => {
    const state: RootState = listenerApi.getState() as RootState;
    const entryLocation = state.shareEntity.entryLocation;
    if (
      entryLocation.pathname !== "" &&
      entryLocation.pathname === window.location.pathname &&
      entryLocation.pathname === "/assets" &&
      entryLocation.params.hasOwnProperty("id")
    ) {
      // @ts-ignore
      const id = entryLocation.params["id"];
      listenerApi.dispatch(assetSlice.actions.selectedAssetSetById(id));
    }
    listenerApi.dispatch(
      shareEntitySlice.actions.entryLocationSave({ pathname: "", params: {} })
    );
  },
});

export default shareEntityMiddleware;
