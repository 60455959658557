import { Box, Button, Divider } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__updateAsset } from "../../../../libraries/enums/permissionRights";
import assetSlice from "../../slices/asset.slice";
import { Edit } from "@mui/icons-material";
import { selectSelectedAsset } from "../../selectors/asset.selector";

const AssetUpdateButton = (props: any) => {
  const { btnProps = {} } = props;

  const dispatch = useAppDispatch();

  const asset = useAppSelector(selectSelectedAsset);
  const rightUpdateAsset = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__updateAsset,
    })
  );

  const handleClick = () => {
    if (!!asset) {
      dispatch(assetSlice.actions.selectedAssetModeSet("update"));
    }
  };

  if (!rightUpdateAsset) {
    return <></>;
  }

  return (
    <>
      <Divider />
      <Box sx={{ m: 2, textAlign: "right" }}>
        <Button
          onClick={handleClick}
          color={"secondary"}
          variant={"outlined"}
          startIcon={<Edit />}
          size="small"
          {...btnProps}
        >
          Upravit zařízení
        </Button>
      </Box>
    </>
  );
};

export default AssetUpdateButton;
