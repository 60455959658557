import { AnyAction } from "@reduxjs/toolkit";
import {
  effectIssueListFetchArgsType,
  ListenerApiType,
} from "../../../types/api.types";
import { revisionDelete, revisionGetAll, revisionPost } from "../../../api/api";

import revisionSlice from "../slices/revisionSlice";
import { RootState } from "../../../app/store";
import {
  selectSelectedRevision,
  selectSelectedRevisionMode,
} from "../selectors/revisionSelectors";
import { formatDateFromISOToYMD } from "../../common/utils/dateTime.utils";

export const effectRevisionListFetch = async (
  action: AnyAction,
  listenerApi: ListenerApiType,
  args: effectIssueListFetchArgsType = {
    showFetchingIndicator: true,
    resetList: false,
    queryParams: {},
  }
) => {
  if (args.showFetchingIndicator) {
    listenerApi.dispatch(revisionSlice.actions.revisionListFetching(true));
  }

  revisionGetAll({ queryParams: args.queryParams })
    .then((response: any) => {
      if (response.status === 200) {
        if (args.resetList) {
          listenerApi.dispatch(
            revisionSlice.actions.revisionListSet(response.data.revisions)
          );
        } else {
          listenerApi.dispatch(
            revisionSlice.actions.revisionListUpsertMany(
              response.data.revisions
            )
          );
        }
      }
    })
    .finally(() => {
      listenerApi.dispatch(revisionSlice.actions.revisionListFetching(false));
    });
};

export const effectSelectedRevisionSubmit = async (
  action: AnyAction,
  listenerApi: ListenerApiType
) => {
  const originalState: RootState = listenerApi.getState() as RootState;
  const revision = selectSelectedRevision(originalState);
  const mode = selectSelectedRevisionMode(originalState);

  if (!!revision) {
    const copy = { ...revision };
    if (!!copy.revisionAt) {
      copy.revisionAt = formatDateFromISOToYMD(copy.revisionAt);
    }
    if (!!copy.nextRevisionAt) {
      copy.nextRevisionAt = formatDateFromISOToYMD(copy.nextRevisionAt);
    }
    revisionPost({ data: copy }).then((response: any) => {
      if (response.status === 200 || response.status === 201) {
        listenerApi.dispatch(
          revisionSlice.actions.revisionListUpsertOne(response.data.revision)
        );
        if (response.data.replaces) {
          listenerApi.dispatch(
            revisionSlice.actions.revisionListUpsertOne(response.data.replaces)
          );
        }
        if (mode === "update") {
          listenerApi.dispatch(
            revisionSlice.actions.selectedRevisionSet(response.data.revision)
          );
          if (response.data.replaces) {
            listenerApi.dispatch(
              revisionSlice.actions.revisionListUpsertOne(
                response.data.replaces
              )
            );
          }
        }
      }
    });
  }
};

export const effectRevisionDelete = async (
  action: AnyAction,
  listenerApi: ListenerApiType
) => {
  const revision = action.payload;

  if (!!revision) {
    revisionDelete({ revisionId: revision.id }).then((response: any) => {
      if (response.status === 200) {
        listenerApi.dispatch(
          revisionSlice.actions.revisionListRemoveOne(revision.id)
        );
      }
    });
  }
};
