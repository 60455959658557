import React from "react";
import NotImplementedYet from "../../../../dev/components/NotImplementedYet/NotImplementedYet";
import { useAppSelector } from "../../../../../app/store";
import { selectSelectedAsset } from "../../../selectors/asset.selector";

const AssetDetailModeReadTabIssues = () => {
  const asset = useAppSelector(selectSelectedAsset);

  if (!asset) {
    return <></>;
  }

  return (
    <>
      <NotImplementedYet />
    </>
  );
};

export default AssetDetailModeReadTabIssues;
