import React from "react";
import Typography from "@mui/material/Typography";
import { Chip } from "@mui/material";

export default function PageHeading(props) {
  return (
    <Typography component="h1" variant="h4" fontWeight={"500"} color="#013063">
      {props.pageName}
      {props.assignee == null || props.assignee == "" ? (
        <></>
      ) : (
        <>
          {" "}
          pro <Chip label={props.assignee} />
        </>
      )}
    </Typography>
  );
}
