import { AppDispatch } from "../../../app/store";

export const handleDownloadRevision =
  (revisionFile: any) => (dispatch: AppDispatch) => {
    const link = document.createElement("a");
    link.href =
      process.env.REACT_APP_ENDPOINT_FILES +
      "/" +
      revisionFile.path +
      "/originals/" +
      revisionFile.filename;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
