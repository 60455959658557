import { IssueFilterType } from "../../../types";

export const buildInitialFilter = () => {
  const filter: IssueFilterType = {
    number: null,
    brandGroupIds: [],
    serviceCompanyIds: [],
    serviceTechnicianIds: [],
    brandIds: [],
    dateFrom: null,
    dateTo: null,
    statusIds: [],
    typeIds: [],
    unitIds: [],
    hideIssueProgressItemTypeIds: [],
    showClosed: false,
    showManagedByFieldManagers: false,
  };

  return filter;
};
