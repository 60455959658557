import { Button, ButtonGroup } from "@mui/material";
import {
  Input,
  LocationOff,
  LocationOn,
  Output,
  Search,
  Settings,
} from "@mui/icons-material";
import assetSlice from "../../../asset/slices/asset.slice";
import { buildIssue } from "../../../issue/builders/issue.builder";
import issueSlice from "../../../issue/slices/issueSlice";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { buildAssetMovementDto } from "../../../asset/builders/assetMovement.builder";
import { selectAssetFinderActionsForAsset } from "../../selectors/assetFinder.selector";
import { ASSET_FINDER_ASSET_ACTIONS } from "../../../../libraries/enums/assetFinderAssetActions";
import { ASSET_MOVEMENT_DIRECTION_CODE__in } from "../../../../libraries/enums/assetMovementDirections";
import { selectProfile } from "../../../auth/selectors/authSelectors";
import { USER_TYPE_CODE__unit } from "../../../../libraries/enums/userTypes";
import { selectAssetRelationLast } from "../../../asset/selectors/asset.selector";

const ActionShowDetail = (props: any) => {
  const { asset } = props;

  const dispatch = useAppDispatch();

  return (
    <Button
      startIcon={<Search />}
      sx={{ justifyContent: "flex-start" }}
      onClick={() => {
        dispatch(assetSlice.actions.selectedAssetModeSet("read"));
        dispatch(assetSlice.actions.selectedAssetSetById(asset.id));
      }}
    >
      Detail zařízení
    </Button>
  );
};

export const ActionAssetUnitRelationPresence = (props: any) => {
  const { asset, resultCode = "CHECKED_AND_EXISTS" } = props;

  const dispatch = useAppDispatch();

  const lastAssetUnitRelation = useAppSelector((state) =>
    selectAssetRelationLast(state, {
      assetId: asset.id,
      relationName: "unitRelations",
    })
  );

  if (!lastAssetUnitRelation) {
    return <></>;
  }

  if (resultCode === "CHECKED_AND_EXISTS") {
    return (
      <Button
        color={"success"}
        startIcon={<LocationOn />}
        sx={{ justifyContent: "flex-start" }}
        onClick={() => {
          dispatch(
            assetSlice.actions.assetAssetUnitRelationPresenceSubmit({
              assetUnitRelationId: lastAssetUnitRelation.id,
              resultCode,
            })
          );
        }}
      >
        Potvrdit, že zařízení je na středisku{" "}
        {lastAssetUnitRelation.unit.storeNumber}
      </Button>
    );
  }

  return (
    <Button
      startIcon={<LocationOff />}
      color={"error"}
      sx={{ justifyContent: "flex-start" }}
      onClick={() => {
        dispatch(
          assetSlice.actions.assetAssetUnitRelationPresenceSubmit({
            assetUnitRelationId: lastAssetUnitRelation.id,
            resultCode,
          })
        );
      }}
    >
      Označit, že zařízení není na středisku{" "}
      {lastAssetUnitRelation.unit.storeNumber}
    </Button>
  );
};

const ActionCreateIssue = (props: any) => {
  const { asset } = props;

  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const unitStoreNumber = profile?.displayName || "-1";

  return (
    <Button
      startIcon={<Settings />}
      sx={{ justifyContent: "flex-start" }}
      onClick={() => {
        const issue = buildIssue();
        dispatch(issueSlice.actions.selectedIssueSet(issue));
        dispatch(issueSlice.actions.selectedIssueModeSet("create"));
      }}
    >
      Nový servisní požadavek
    </Button>
  );
};

const ActionCreateMovementFromUnit = (props: any) => {
  const { asset } = props;

  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const unitStoreNumber = profile?.displayName || "-1";

  const handleClick = () => {
    if ((profile?.type || "-1") === USER_TYPE_CODE__unit) {
      const assetMovementDto = buildAssetMovementDto();
      assetMovementDto.assetId = asset.id;
      assetMovementDto.directionId = "OUT";
      assetMovementDto.unitStoreNumber = unitStoreNumber;

      dispatch(
        assetSlice.actions.assetAssetMovementSubmit({
          assetId: asset.id,
          assetMovementDto,
        })
      );
    }
  };

  return (
    <Button
      startIcon={<Output />}
      sx={{ justifyContent: "flex-start" }}
      onClick={() => {
        handleClick();
      }}
    >
      Výdej (odvoz, likvidace, &hellip;) ze střediska {unitStoreNumber}
    </Button>
  );
};

const ActionCreateMovementToUnit = (props: any) => {
  const { asset } = props;

  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const unitStoreNumber = profile?.displayName || "-1";

  const handleClick = () => {
    if ((profile?.type || "-1") === USER_TYPE_CODE__unit) {
      const assetMovementDto = buildAssetMovementDto();
      assetMovementDto.assetId = asset.id;
      assetMovementDto.directionId = ASSET_MOVEMENT_DIRECTION_CODE__in;
      assetMovementDto.unitStoreNumber = unitStoreNumber;

      dispatch(
        assetSlice.actions.assetAssetMovementSubmit({
          assetId: asset.id,
          assetMovementDto,
        })
      );
    }
  };

  return (
    <Button
      startIcon={<Input />}
      sx={{ justifyContent: "flex-start" }}
      onClick={() => {
        handleClick();
      }}
    >
      Příjem (dovoz, nákup, &hellip;) na středisko {unitStoreNumber}
    </Button>
  );
};

const AssetFinderResultAssetActions = (props: any) => {
  const { asset } = props;
  const actions = useAppSelector((state) =>
    selectAssetFinderActionsForAsset(state, { asset })
  );

  if (actions.length === 0) {
    return <></>;
  }

  return (
    <ButtonGroup
      fullWidth={true}
      variant={"outlined"}
      color={"secondary"}
      orientation={"vertical"}
      sx={{ mb: 2 }}
    >
      {actions.map((action: string) => {
        return (
          <React.Fragment key={action}>
            {action === ASSET_FINDER_ASSET_ACTIONS.SHOW_DETAIL && (
              <ActionShowDetail asset={asset} />
            )}
            {action === ASSET_FINDER_ASSET_ACTIONS.CREATE_ISSUE && (
              <ActionCreateIssue asset={asset} />
            )}
            {action === ASSET_FINDER_ASSET_ACTIONS.CREATE_MOVEMENT_OUT && (
              <ActionCreateMovementFromUnit asset={asset} />
            )}
            {action === ASSET_FINDER_ASSET_ACTIONS.CREATE_MOVEMENT_IN && (
              <ActionCreateMovementToUnit asset={asset} />
            )}
            {action ===
              ASSET_FINDER_ASSET_ACTIONS.CHECK_PRESENCE_AND_EXISTS && (
              <ActionAssetUnitRelationPresence
                asset={asset}
                resultCode={"CHECKED_AND_EXISTS"}
              />
            )}
            {action ===
              ASSET_FINDER_ASSET_ACTIONS.CHECK_PRESENCE_AND_DOES_NOT_EXIST && (
              <ActionAssetUnitRelationPresence
                asset={asset}
                resultCode={"CHECKED_AND_DOES_NOT_EXIST"}
              />
            )}
          </React.Fragment>
        );
      })}
    </ButtonGroup>
  );
};

export default AssetFinderResultAssetActions;
