//@ts-nocheck

import { selectProfile } from "../../auth/selectors/authSelectors";
import { createSelector } from "@reduxjs/toolkit";
import { permissionRightCodeType } from "../../../libraries/enums/permissionRights";

export const selectProfilePermission = createSelector(
  [selectProfile],
  (profile) => {
    return profile.permission;
  }
);

export const selectProfileHasPermissionRight = createSelector(
  [
    selectProfilePermission,
    (state, args: { permissionRight?: permissionRightCodeType }) =>
      args.permissionRight,
  ],
  (permission, permissionRight) => {
    if (!permission || !permissionRight) {
      return false;
    }

    return permission.permissionRightIds.includes(permissionRight);
  }
);

export const selectProfileOnlyService = createSelector(
  [selectProfile],
  (profile) => {
    return profile?.onlyService;
  }
);
