import React from "react";
import { UnitType } from "../../../../types";
import DocumentList from "../../../document/components/DocumentList/DocumentList";
import { Box } from "@mui/material";
import DocumentCreateButton from "../../../document/components/DocumentCreateButton/DocumentCreateButton";

const TabDocuments = (props: { unit: UnitType }) => {
  return (
    <>
      <Box sx={{ textAlign: "right", pb: 1 }}>
        <DocumentCreateButton btnProps={{ sx: { mb: 1 } }} />
      </Box>
      <Box>
        <DocumentList />
      </Box>
    </>
  );
};

export default TabDocuments;
