import { UnitAddressType } from "../../../types";

export const formatAddress = (address: UnitAddressType | undefined): string => {
  let str = "";
  if (!address) {
    return str;
  }
  if (!!address.city) {
    str = str + address.city;
  }
  if (!!address.street) {
    str = str + ", " + address.street;
  }
  if (!!address.landRegistryNumber) {
    str = str + " " + address.landRegistryNumber;
  }
  if (!!address.houseNumber) {
    str = str + "/" + address.houseNumber;
  }
  return str;
};
