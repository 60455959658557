import { DocumentType, Nullable } from "../../../types";
import { buildDocument } from "../builders/document.builder";
import documentSlice from "../slices/document.slice";
import { AppDispatch } from "../../../app/store";

export const handleDocumentButtonClick =
  (doc: DocumentType, unitId: Nullable<string> = null) =>
  (dispatch: AppDispatch) => {
    if (doc.type === "documentTemplate") {
      const d = buildDocument();
      d.title = doc.title;
      d.templateId = doc.templateId;
      // @ts-ignore
      d.unitId = unitId;
      dispatch(documentSlice.actions.selectedDocumentSet(d));
      dispatch(documentSlice.actions.selectedDocumentModeSet("create"));
    } else {
      const link = document.createElement("a");
      link.href =
        process.env.REACT_APP_ENDPOINT_FILES +
        "/" +
        doc.files[0].path +
        "/originals/" +
        doc.files[0].filename;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
