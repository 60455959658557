import { AnyAction } from "@reduxjs/toolkit";
import {
  effectIssueListFetchArgsType,
  ListenerApiType,
} from "../../../types/api.types";
import {
  assetGetAll,
  assetPost,
  assetUnitRelationPresenceUpsertPost,
  assetUpsertAssetMovementPost,
} from "../../../api/api";
import assetSlice from "../slices/asset.slice";
import { AssetType } from "../../../types/asset.types";
import { RootState } from "../../../app/store";
import {
  selectSelectedAsset,
  selectSelectedAssetMode,
} from "../selectors/asset.selector";

export const effectAssetListFetch = async (
  action: AnyAction,
  listenerApi: ListenerApiType,
  args: effectIssueListFetchArgsType = {
    showFetchingIndicator: true,
    resetList: false,
    queryParams: {},
  }
) => {
  if (args.showFetchingIndicator) {
    listenerApi.dispatch(assetSlice.actions.listFetching(true));
    listenerApi.dispatch(assetSlice.actions.listFetchingStatus("fetching"));
  }

  const queryParams = args.queryParams;
  assetGetAll({ queryParams: queryParams })
    .then((response: any) => {
      if (response.status === 200) {
        const assets: AssetType[] = response.data.assets;
        listenerApi.dispatch(assetSlice.actions.listFetchingStatus("success"));
        if (args.resetList) {
          listenerApi.dispatch(assetSlice.actions.listSet(assets));
        } else {
          listenerApi.dispatch(assetSlice.actions.listUpsertMany(assets));
        }
      }
    })
    .catch((error) => {
      listenerApi.dispatch(assetSlice.actions.listFetchingStatus("failure"));
    })
    .finally(() => {
      listenerApi.dispatch(assetSlice.actions.listFetching(false));
    });
};

export const effectSelectedAssetSubmit = async (
  action: AnyAction,
  listenerApi: ListenerApiType
) => {
  const originalState: RootState = listenerApi.getState() as RootState;
  const asset = selectSelectedAsset(originalState);
  const mode = selectSelectedAssetMode(originalState);

  if (!!asset) {
    assetPost({ data: { asset: asset } }).then((response: any) => {
      if (response.status === 200) {
        if (response.data.length === 1) {
          listenerApi.dispatch(
            assetSlice.actions.listUpsertOne(response.data[0])
          );
        } else {
          listenerApi.dispatch(
            assetSlice.actions.listUpsertMany(response.data)
          );
        }
        if (mode === "update") {
          listenerApi.dispatch(
            assetSlice.actions.selectedAssetSet(response.data[0])
          );
        }
      }
    });
  }
};

export const effectAssetAssetMovementSubmit = async (
  action: AnyAction,
  listenerApi: ListenerApiType
) => {
  const { assetId, assetMovementDto } = action.payload;

  assetUpsertAssetMovementPost({
    assetId: assetId,
    data: assetMovementDto,
  }).then((response: any) => {
    if (response.status === 200) {
      if (response.data.length === 1) {
        listenerApi.dispatch(
          assetSlice.actions.listUpsertOne(response.data[0])
        );
      } else {
        listenerApi.dispatch(assetSlice.actions.listUpsertMany(response.data));
      }
      listenerApi.dispatch(
        assetSlice.actions.selectedAssetSet(response.data[0])
      );
    }
  });
};

export const effectAssetAssetUnitRelationPresenceSubmit = async (
  action: AnyAction,
  listenerApi: ListenerApiType
) => {
  const { assetUnitRelationId, resultCode } = action.payload;

  assetUnitRelationPresenceUpsertPost({
    assetUnitRelationId: assetUnitRelationId,
    data: {
      resultCode,
    },
  }).then((response: any) => {
    if (response.status === 200) {
      if (response.data.length === 1) {
        listenerApi.dispatch(
          assetSlice.actions.listUpsertOne(response.data[0])
        );
      } else {
        listenerApi.dispatch(assetSlice.actions.listUpsertMany(response.data));
      }
      listenerApi.dispatch(
        assetSlice.actions.selectedAssetSet(response.data[0])
      );
    }
  });
};
