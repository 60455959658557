import WarningIcon from "@mui/icons-material/Warning";
import PlumbingIcon from "@mui/icons-material/Plumbing";
import CoffeeMakerIcon from "@mui/icons-material/CoffeeMaker";
import { blue, brown, red } from "@mui/material/colors";

export const ISSUE_TYPE_CODE__crash = "crash";
export const ISSUE_TYPE_CODE__service = "service";
export const ISSUE_TYPE_CODE__newItem = "newItem";

const ISSUE_TYPES = {
  [ISSUE_TYPE_CODE__service]: {
    code: ISSUE_TYPE_CODE__service,
    icon: PlumbingIcon,
    color: "#000000",
    bgcolor: blue[100],
    label: "Servis",
  },
  [ISSUE_TYPE_CODE__crash]: {
    code: ISSUE_TYPE_CODE__crash,
    icon: WarningIcon,
    color: "#000000",
    bgcolor: red[100],
    label: "Havárie",
  },
  [ISSUE_TYPE_CODE__newItem]: {
    code: ISSUE_TYPE_CODE__newItem,
    icon: CoffeeMakerIcon,
    color: "#000000",
    bgcolor: brown[100],
    label: "Nové zařízení",
  },
};

export type issueTypeCodeType = keyof typeof ISSUE_TYPES;

export const getIssueTypeByCode = (code: issueTypeCodeType) => {
  return ISSUE_TYPES[code];
};

export const getIssueTypes = () => {
  return Object.values(ISSUE_TYPES);
};
