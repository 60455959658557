import { AssetMovement, AssetType } from "../../../types/asset.types";
import { Nullable } from "../../../types";

export const getLastAssetMovement = (
  asset: Nullable<AssetType> | undefined
): Nullable<AssetMovement> => {
  if (!asset) {
    return null;
  }
  return (
    [...asset.assetMovements]
      .filter((a) => a.enabled)
      .sort((a: AssetMovement, b: AssetMovement) =>
        a.createdAt.localeCompare(b.createdAt)
      )[asset.assetMovements.length - 1] || null
  );
};
