import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  useMediaQuery,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import store, { useAppDispatch, useAppSelector } from "../../../../app/store";
import React, { useEffect, useState } from "react";
import CloseButton from "../../../common/components/CloseButton/CloseButton";
import { useTheme } from "@mui/material/styles";
import {
  selectHasSelectedRevision,
  selectSelectedRevision,
  selectSelectedRevisionMode,
} from "../../selectors/revisionSelectors";
import revisionSlice from "../../slices/revisionSlice";
import RevisionDetailModeUpdate from "./RevisionDetailModeUpdate";

const RevisionDetail = () => {
  const dispatch = useAppDispatch();

  const theme = useTheme();

  const hasRevision = useAppSelector(selectHasSelectedRevision);
  const mode = useAppSelector(selectSelectedRevisionMode);

  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<any>([]);

  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const validate = () => {
    const errors: any[] = [];
    const state = store.getState();
    const revision = selectSelectedRevision(state);
    if (!!revision) {
      if (!revision.typeId) {
        errors.push("Pole Typ revize být vyplněno.");
      }
      if (!revision.unitStoreNumber) {
        errors.push("Středisko musí být vyplněno.");
      }
      if (!revision.revisionCompanyId) {
        errors.push("Pole Revizní společnost musí být vyplněno.");
      }
      if (!revision.revisionSubjectId) {
        errors.push("Pole Předmět revize musí být vyplněno.");
      }
      if (!revision.revisionAt) {
        errors.push("Pole Datum provedení revize musí být vyplněno.");
      }
      if (!revision.fileContent) {
        errors.push("K revizi musí být nahrán Revizní list.");
      }
    }

    return errors;
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setErrors([]);
      dispatch(revisionSlice.actions.selectedRevisionSet(null));
      dispatch(revisionSlice.actions.selectedRevisionModeSet(null));
    }, 250);
  };

  const handleSubmit = () => {
    const errors = validate();
    if (errors.length === 0) {
      dispatch(revisionSlice.actions.selectedRevisionSubmit());
      if (mode === "update") {
        dispatch(revisionSlice.actions.selectedRevisionModeSet("read"));
      } else {
        handleClose();
      }
    } else {
      setErrors(errors);
    }
  };

  useEffect(() => {
    if (hasRevision) {
      setErrors([]);
      setOpen(true);
    }
  }, [hasRevision]);

  if (mode === "create" || mode === "update") {
    return (
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
        fullScreen={mdUp ? false : true}
      >
        <DialogTitle>
          {mode === "create" && <>Přidat revizi</>}
          {mode === "update" && <>Zpracovat revizi</>}
          <CloseButton onClick={handleClose} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          {mode === "create" && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              Každou revizi prosím zadávejte jednotlivě
            </Alert>
          )}
          <RevisionDetailModeUpdate />
          {errors.length > 0 && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errors.map((error: string) => (
                <Box key={error}>{error}</Box>
              ))}
            </Alert>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant={"contained"}
            color={"primary"}
            startIcon={<DoneIcon />}
            onClick={() => {
              handleSubmit();
            }}
          >
            {mode === "create" && <>Vytvořit revizi</>}
            {mode === "update" && <>Uložit revizi</>}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="lg"
      fullScreen={mdUp ? false : true}
    >
      <DialogTitle>
        Detail revize
        <CloseButton onClick={handleClose} />
      </DialogTitle>
      <Divider />
      <DialogContent>{/*<IssueDetailModeRead />*/}</DialogContent>
    </Dialog>
  );
};

export default RevisionDetail;
