import { BrandType, ProfileType } from "../../../../../types";
import AssetDetailModeReadTabInfo from "./AssetDetailModeReadTabInfo";
import AssetDetailModeReadTabIssues from "./AssetDetailModeReadTabIssues";
import AssetDetailModeReadTabRevisions from "./AssetDetailModeReadTabRevisions";
import AssetDetailModeReadTabDocuments from "./AssetDetailModeReadTabDocuments";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { fieldAsset } from "../../fields/fieldAsset";
import CloseButton from "../../../../common/components/CloseButton/CloseButton";
import React, { createElement, useState } from "react";
import { useAppSelector } from "../../../../../app/store";
import { selectProfile } from "../../../../auth/selectors/authSelectors";
import {
  selectAssetRelatedEntityIdOfAssetRelationLast,
  selectSelectedAsset,
} from "../../../selectors/asset.selector";
import { useTheme } from "@mui/material/styles";
import { AssetType } from "../../../../../types/asset.types";
import {
  selectBrandById,
  selectUnitById,
} from "../../../../enum/selectors/enumSelectors";
import { EntityId } from "@reduxjs/toolkit";
import { getBrandLogoUrlByBrandCode } from "../../../../../libraries/brands";
import { grey } from "@mui/material/colors";
import { getLastAssetMovement } from "../../../utils/assetMovement.utils";
import {
  PERMISSION_RIGHT_CODE__createDocument,
  PERMISSION_RIGHT_CODE__createIssue,
  PERMISSION_RIGHT_CODE__createRevision,
  PERMISSION_RIGHT_CODE__readDocument,
  PERMISSION_RIGHT_CODE__readIssue,
  PERMISSION_RIGHT_CODE__readRevision,
} from "../../../../../libraries/enums/permissionRights";
import { fieldUnit } from "../../fields/fieldUnit";

const getTabItems = (profile: ProfileType) => {
  const INFO = {
    value: "info",
    label: "Základní informace",
    component: AssetDetailModeReadTabInfo,
  };
  const ISSUES = {
    value: "issues",
    label: "Servisní požadavky",
    component: AssetDetailModeReadTabIssues,
  };
  const REVISIONS = {
    value: "revisions",
    label: "Revize a kontroly",
    component: AssetDetailModeReadTabRevisions,
  };
  const DOCUMENTS = {
    value: "documents",
    label: "Dokumenty",
    component: AssetDetailModeReadTabDocuments,
  };

  const items = [INFO];

  if (
    profile.permission.permissionRightIds.some((p) => {
      return [
        PERMISSION_RIGHT_CODE__createIssue,
        PERMISSION_RIGHT_CODE__readIssue,
      ].includes(p);
    })
  ) {
    items.push(ISSUES);
  }

  if (
    profile.permission.permissionRightIds.some((p) => {
      return [
        PERMISSION_RIGHT_CODE__createRevision,
        PERMISSION_RIGHT_CODE__readRevision,
      ].includes(p);
    })
  ) {
    items.push(REVISIONS);
  }

  if (
    profile.permission.permissionRightIds.some((p) => {
      return [
        PERMISSION_RIGHT_CODE__createDocument,
        PERMISSION_RIGHT_CODE__readDocument,
      ].includes(p);
    })
  ) {
    items.push(DOCUMENTS);
  }

  return items;
};

export const AssetDetailHeaderUnit = ({ asset }: { asset: AssetType }) => {
  const unit = useAppSelector((state) =>
    selectUnitById(
      state,
      selectAssetRelatedEntityIdOfAssetRelationLast(state, {
        assetId: asset.id,
        relationName: "unitRelations",
        entityName: "unit",
      })
    )
  );
  const brand = useAppSelector((state) =>
    !unit || !unit.brandId
      ? null
      : selectBrandById(state, unit.brandId as unknown as EntityId)
  );
  if (!unit || !brand) {
    return <></>;
  }

  const lastAssetMovement = getLastAssetMovement(asset);

  return (
    <Box
      sx={{
        opacity: (lastAssetMovement?.direction?.id || "-1") === "OUT" ? 0.5 : 1,
      }}
    >
      <Avatar
        src={getBrandLogoUrlByBrandCode(
          brand.code as unknown as Pick<BrandType, "code">
        )}
        sx={{ mr: 1 }}
      />
      <Box>
        <Typography variant="body2" sx={{ color: grey[600], fontWeight: 600 }}>
          {unit.title}
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
          {unit.subtitle || ""}
        </Typography>
      </Box>
    </Box>
  );
};

const AssetDetailModeRead = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: any;
}) => {
  const [tab, setTab] = useState("info");
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const asset = useAppSelector(selectSelectedAsset);
  const profile = useAppSelector(selectProfile);

  if (!asset || !profile) {
    return <></>;
  }

  const items = getTabItems(profile);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="lg"
      fullScreen={mdUp ? false : true}
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
      }}
    >
      <DialogTitle sx={{ p: 0 }}>
        <Box
          sx={{
            display: "flex",
            alignAssets: "center",
          }}
        >
          <Box display="flex" alignItems="center" sx={{ m: 0 }}>
            <Box sx={{ mr: 2 }}>
              {React.createElement(fieldAsset.component, {
                assetId: asset.id,
              })}
            </Box>
          </Box>
          <CloseButton onClick={handleClose} />
        </Box>
        <Divider />
        {getTabItems(profile).length > 1 && (
          <Tabs
            textColor={"secondary"}
            indicatorColor={"secondary"}
            value={tab}
            onChange={(event, newValue) => {
              setTab(newValue);
            }}
            allowScrollButtonsMobile
            variant="scrollable"
          >
            {items.map((item) => {
              return (
                <Tab key={item.value} label={item.label} value={item.value} />
              );
            })}
          </Tabs>
        )}
      </DialogTitle>
      <DialogContent dividers={true}>
        {items.map((item) => {
          return (
            <Box key={item.value} hidden={item.value !== tab}>
              {createElement(item.component)}
            </Box>
          );
        })}
      </DialogContent>
      {/*<DialogActions sx={{ justifyContent: "flex-start" }}>*/}
      {/*  <Button*/}
      {/*    variant="outlined"*/}
      {/*    size="small"*/}
      {/*    startIcon={<ContentCopy />}*/}
      {/*    onClick={() => {*/}
      {/*      window.navigator.clipboard.writeText(window.location.href);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Zkopírovat do schránky*/}
      {/*  </Button>*/}
      {/*</DialogActions>*/}
    </Dialog>
  );
};

export default AssetDetailModeRead;
