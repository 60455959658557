import { createSelector } from "@reduxjs/toolkit";
import { selectFlattenUnits } from "../../enum/selectors/enumSelectors";
import { RootState } from "../../../app/store";
import { UnitFilterType } from "../../../types";
import { selectProfile } from "../../auth/selectors/authSelectors";

export const selectUnitFilter = (state: RootState): UnitFilterType =>
  state.unit.filter;

export const selectUnitFilterIsEnabled = createSelector(
  [selectUnitFilter],
  (f) => {
    return (
      f.unitIds.length > 0 ||
      f.brandIds.length > 0 ||
      f.brandGroupIds.length > 0
    );
  }
);

export const selectUnitFilterProperty = createSelector(
  [selectUnitFilter, (state, args) => args.property],
  (filter, property) => {
    if (!filter) {
      return null;
    }

    // @ts-ignore
    return filter[property];
  }
);

export const selectListConfig = (state: RootState) => {
  return state.unit.listConfig;
};

export const selectFlattenUnitsFilteredAndSorted = createSelector(
  [selectFlattenUnits, selectUnitFilter, selectProfile],
  (units, filter, profile) => {
    if (!profile) {
      return [];
    }

    const emptyOrInArray = (
      search: string | null,
      array: string[]
    ): boolean => {
      if (array.length === 0) {
        return true;
      }
      if (!search) {
        return false;
      }
      return array.includes(search);
    };

    return units.filter((unit) => {
      return (
        emptyOrInArray(unit.id as unknown as string, filter.unitIds) &&
        emptyOrInArray(
          (unit?.brandId as unknown as string) || null,
          filter.brandIds
        )
      );
    });
  }
);

export const selectFlattenUnitsFilteredAndSortedCount = createSelector(
  [selectFlattenUnitsFilteredAndSorted],
  (units) => {
    return units.length;
  }
);

export const selectFlattenUnitIdsFilteredAndSorted = createSelector(
  [selectFlattenUnitsFilteredAndSorted, selectListConfig],
  (units, listConfig) => {
    return units
      .map((u: { id: any }) => u.id)
      .slice(
        listConfig.page * listConfig.pageSize,
        (listConfig.page + 1) * listConfig.pageSize
      );
  }
);
