//@ts-nocheck

import { Box, Tooltip } from "@mui/material";
import React from "react";
import ChipUserType from "../../Chip/ChipUserType";
import ChipIssueStatus from "../../Chip/ChipIssueStatus";
import {
  USER_TYPE_CODE__headquarters,
  USER_TYPE_CODE__serviceCompany,
  USER_TYPE_CODE__serviceTechnician,
} from "../../../../../libraries/enums/userTypes";
import {
  ISSUE_STATUS_CODE__inProgress,
  ISSUE_STATUS_CODE__new,
} from "../../../../../libraries/enums/issueStatuses";
import { useAppSelector } from "../../../../../app/store";
import { selectIssueById } from "../../../selectors/issueSelectors";
import {
  formatDate,
  formatDateDistanceToNow,
} from "../../../../common/utils/dateTime.utils";
import {
  ISSUE_PROGRESS_ITEM_TYPE_CODE__consideredAsDoneByHeadquarters,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__created,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__forwardedToHeadquarters,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__forwardedToServiceCompany,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByHeadquarters,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByServiceCompany,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByServiceTechnician,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByUnit,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__sentToServiceCompany,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__sentToServiceTechnician,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__serviceListAttached,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__statusInProgress,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__statusNew,
} from "../../../../../libraries/enums/issueProgressItemTypes";
import { grey } from "@mui/material/colors";

const getText = (progressItem) => {
  switch (progressItem.type) {
    case ISSUE_PROGRESS_ITEM_TYPE_CODE__created: {
      return (
        <>
          <ChipUserType
            userTypeCode={progressItem.createdBy.type}
            label={progressItem.createdBy.displayName}
          />
          <Box sx={{ mx: 0.5 }}>vytvořil/a</Box>
        </>
      );
    }
    case ISSUE_PROGRESS_ITEM_TYPE_CODE__forwardedToHeadquarters: {
      return (
        <>
          <ChipUserType
            userTypeCode={progressItem.createdBy.type}
            label={progressItem.createdBy.displayName}
          />
          <Box sx={{ ml: 0.5, mr: 0.5 }}>přesměroval/a na</Box>
          <ChipUserType
            userTypeCode={USER_TYPE_CODE__headquarters}
            label={"Centrála"}
          />
        </>
      );
    }
    case ISSUE_PROGRESS_ITEM_TYPE_CODE__sentToServiceCompany: {
      return (
        <>
          <ChipUserType
            userTypeCode={progressItem.createdBy.type}
            label={progressItem.createdBy.displayName}
          />
          <Box sx={{ ml: 0.5, mr: 0.5 }}>odeslal/a na</Box>
          <ChipUserType
            userTypeCode={USER_TYPE_CODE__serviceCompany}
            label={progressItem.payload.title}
          />
        </>
      );
    }
    case ISSUE_PROGRESS_ITEM_TYPE_CODE__sentToServiceTechnician: {
      return (
        <>
          <ChipUserType
            userTypeCode={progressItem.createdBy.type}
            label={progressItem.createdBy.displayName}
          />
          <Box sx={{ ml: 0.5, mr: 0.5 }}>odeslal/a na</Box>
          <ChipUserType
            userTypeCode={USER_TYPE_CODE__serviceTechnician}
            label={progressItem.payload.title}
          />
        </>
      );
    }
    case ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByUnit: {
      return (
        <>
          <ChipUserType
            userTypeCode={progressItem.createdBy.type}
            label={progressItem.createdBy.displayName}
          />
          <Box sx={{ ml: 0.5, mr: 0.5 }}>označil/a jako Vyřízené</Box>
        </>
      );
    }
    case ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByServiceTechnician: {
      return (
        <>
          <ChipUserType
            userTypeCode={progressItem.createdBy.type}
            label={progressItem.createdBy.displayName}
          />
          <Box sx={{ ml: 0.5, mr: 0.5 }}>označil/a jako Vyřízené</Box>
        </>
      );
    }
    case ISSUE_PROGRESS_ITEM_TYPE_CODE__forwardedToServiceCompany: {
      return (
        <>
          <ChipUserType
            userTypeCode={progressItem.createdBy.type}
            label={progressItem.createdBy.displayName}
          />
          <Box sx={{ ml: 0.5, mr: 0.5 }}>označil/a jako Vyřízené</Box>
        </>
      );
    }
    case ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByServiceCompany: {
      return (
        <>
          <ChipUserType
            userTypeCode={progressItem.createdBy.type}
            label={progressItem.createdBy.displayName}
          />
          <Box sx={{ ml: 0.5, mr: 0.5 }}>označil/a jako Vyřízené</Box>
        </>
      );
    }
    case ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByHeadquarters: {
      return (
        <>
          <ChipUserType
            userTypeCode={progressItem.createdBy.type}
            label={progressItem.createdBy.displayName}
          />
          <Box sx={{ ml: 0.5, mr: 0.5 }}>označil/a jako Vyřízené</Box>
        </>
      );
    }
    case ISSUE_PROGRESS_ITEM_TYPE_CODE__consideredAsDoneByHeadquarters: {
      return (
        <>
          <ChipUserType
            userTypeCode={progressItem.createdBy.type}
            label={progressItem.createdBy.displayName}
          />
          <Box sx={{ ml: 0.5, mr: 0.5 }}>považuje jako Vyřízené</Box>
        </>
      );
    }
    case ISSUE_PROGRESS_ITEM_TYPE_CODE__serviceListAttached: {
      return (
        <>
          <ChipUserType
            userTypeCode={progressItem.createdBy.type}
            label={progressItem.createdBy.displayName}
          />
          <Box sx={{ ml: 0.5, mr: 0.5 }}>nahrál/a servisní list</Box>
        </>
      );
    }
    case ISSUE_PROGRESS_ITEM_TYPE_CODE__statusNew: {
      return (
        <ChipIssueStatus
          issueStatusCode={ISSUE_STATUS_CODE__new}
          label={"Nový"}
        />
      );
    }
    case ISSUE_PROGRESS_ITEM_TYPE_CODE__statusInProgress: {
      return (
        <ChipIssueStatus
          issueStatusCode={ISSUE_STATUS_CODE__inProgress}
          label={"V řešení"}
        />
      );
    }
    default: {
      return <></>;
    }
  }
};

const ProgressItem = (props) => {
  const { progressItem } = props;

  return (
    <Box
      sx={{
        mb: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={formatDateDistanceToNow(progressItem.createdAt)}>
          <Box
            sx={{
              mr: 1,
              fontSize: 12,
              color: grey[500],
              whiteSpace: "nowrap",
            }}
          >
            {formatDate(progressItem.createdAt)}
          </Box>
        </Tooltip>
        <Box
          sx={{
            fontSize: 12,
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {getText(progressItem)}
        </Box>
      </Box>
    </Box>
  );
};

const FieldProgress = (props) => {
  const { issueId, showCount = 2 } = props;

  const issue = useAppSelector((state) => selectIssueById(state, issueId));

  const progressItems = [...issue.progressItems].sort((a, b) => {
    return -a.createdAt.localeCompare(b.createdAt);
  });

  return (
    <Box sx={{ p: 2, pb: 1 }}>
      {progressItems.slice(0, showCount).map((p) => {
        return <ProgressItem key={p.id} progressItem={p} />;
      })}
      {progressItems.length - showCount > 0 && (
        <Box
          sx={{
            mr: 1,
            mb: 1,
            fontSize: 12,
            color: grey[500],
          }}
        >
          {progressItems.length - showCount} předchozí &hellip;
        </Box>
      )}
    </Box>
  );
};

export const fieldProgress = {
  code: "fieldProgress",
  label: "Historie",
  valueGetterFn: (obj) => {
    return "";
  },
  component: FieldProgress,
};
