import {
  Alert,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  useMediaQuery,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import store, { useAppDispatch, useAppSelector } from "../../../../app/store";
import React, { useEffect, useState } from "react";
import {
  selectHasSelectedIssue,
  selectSelectedIssue,
  selectSelectedIssueClosingDialog,
  selectSelectedIssueMode,
  selectSelectedIssuePrevNextIds,
  selectSelectedIssueProperty,
} from "../../selectors/issueSelectors";
import issueSlice from "../../slices/issueSlice";
import IssueDetailModeUpdate from "./IssueDetailModeUpdate";
import IssueDetailModeRead from "./IssueDetailModeRead";
import CloseButton from "../../../common/components/CloseButton/CloseButton";
import ChipIssueStatus from "../Chip/ChipIssueStatus";
import { ISSUE_STATUS_CODE__done } from "../../../../libraries/enums/issueStatuses";
import { useTheme } from "@mui/material/styles";
import { ChevronLeft, ChevronRight, ContentCopy } from "@mui/icons-material";

const IssueDetailPrevNextBtn = () => {
  const dispatch = useAppDispatch();
  const ids = useAppSelector(selectSelectedIssuePrevNextIds);

  return (
    <>
      <ButtonGroup
        size={"small"}
        variant={"outlined"}
        color={"secondary"}
        sx={{ ml: 2 }}
      >
        <Button
          startIcon={<ChevronLeft />}
          disabled={!ids.prev}
          onClick={() => {
            dispatch(issueSlice.actions.selectedIssueSetById(ids.prev));
          }}
        >
          Novější
        </Button>
        <Button
          startIcon={<ChevronRight />}
          disabled={!ids.next}
          onClick={() => {
            dispatch(issueSlice.actions.selectedIssueSetById(ids.next));
          }}
        >
          Starší
        </Button>
      </ButtonGroup>
    </>
  );
};

const IssueDetail = () => {
  const dispatch = useAppDispatch();

  const theme = useTheme();

  const hasIssue = useAppSelector(selectHasSelectedIssue);
  const isClosing = useAppSelector(selectSelectedIssueClosingDialog);
  const number = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "number" })
  );
  const issueOpen = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "open" })
  );
  const mode = useAppSelector(selectSelectedIssueMode);
  const unitIds = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "unitIds" })
  );

  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<any>([]);

  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const validate = () => {
    const errors = [];
    const state = store.getState();
    const issue = selectSelectedIssue(state);
    if (!!issue) {
      if (!issue.unitId && !issue.unitIds) {
        errors.push("Středisko musí být vyplněno.");
      }
      if (!issue.description) {
        errors.push("Pole Požadavek musí být vyplněno.");
      }
    }

    return errors;
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setErrors([]);
      dispatch(issueSlice.actions.selectedIssueSet(null));
      dispatch(issueSlice.actions.selectedIssueModeSet(null));
      dispatch(issueSlice.actions.selectedIssueClosingDialogSet(false));
    }, 250);
  };

  const handleSubmit = () => {
    const errors = validate();
    if (errors.length === 0) {
      dispatch(issueSlice.actions.selectedIssueSubmit());
      if (mode === "update") {
        dispatch(issueSlice.actions.selectedIssueModeSet("read"));
      } else {
        handleClose();
      }
    } else {
      setErrors(errors);
    }
  };

  useEffect(() => {
    if (hasIssue) {
      setErrors([]);
      setOpen(true);
    }
  }, [hasIssue]);

  useEffect(() => {
    if (isClosing) {
      handleClose();
    }
  }, [isClosing]);

  if (mode === "create" || mode === "update") {
    return (
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
        fullScreen={mdUp ? false : true}
      >
        <DialogTitle>
          {mode === "create" && !unitIds && <>Přidat požadavek</>}
          {mode === "create" && !!unitIds && (
            <>Přidat požadavek na více středisek</>
          )}
          {mode === "update" && <>Zpracovat požadavek</>}
          <CloseButton onClick={handleClose} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          {mode === "create" && !unitIds && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              Každý požadavek prosím zadávejte jednotlivě.
            </Alert>
          )}
          {mode === "create" && !!unitIds && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              Můžete zvolit více středisek. Požadavek bude rozkopírován na
              všechna vybraná střediska.
            </Alert>
          )}
          <IssueDetailModeUpdate handleSubmit={handleSubmit} />
          {errors.length > 0 && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errors.join(" ")}
            </Alert>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant={"contained"}
            color={"primary"}
            startIcon={<DoneIcon />}
            onClick={() => {
              handleSubmit();
            }}
          >
            {mode === "create" && <>Vytvořit požadavek</>}
            {mode === "update" && <>Uložit požadavek</>}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="lg"
      fullScreen={mdUp ? false : true}
    >
      <DialogTitle>
        #{number}{" "}
        {!issueOpen && (
          <ChipIssueStatus
            issueStatusCode={ISSUE_STATUS_CODE__done}
            label={"Uzavřený"}
          />
        )}
        <IssueDetailPrevNextBtn />
        <CloseButton onClick={handleClose} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <IssueDetailModeRead />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start" }}>
        <Button
          variant="outlined"
          size="small"
          startIcon={<ContentCopy />}
          onClick={() => {
            window.navigator.clipboard.writeText(window.location.href);
          }}
        >
          Zkopírovat do schránky
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IssueDetail;
