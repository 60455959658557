import { Nullable } from "../../../../types";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { getBrandLogoUrlByBrandCode } from "../../../../libraries/brands";
import { grey } from "@mui/material/colors";
import React from "react";
import issueSlice from "../../../issue/slices/issueSlice";
import { useAppDispatch } from "../../../../app/store";

interface UnitThumbnailContentProps {
  unit: {
    id: string;
    title: string;
    subtitle: Nullable<string>;
  };
  brand: {
    code: string;
  };
  canShowDetail?: boolean;
  padding?: number;
}

const UnitThumbnailContentContent = (props: UnitThumbnailContentProps) => {
  const { unit, brand } = props;

  return (
    <>
      <Avatar
        src={getBrandLogoUrlByBrandCode(brand.code)}
        sx={{
          width: 48,
          height: 48,
          border: "6px solid transparent",
          mr: 1,
          transition: "all 300ms",
        }}
      />
      <Box>
        <Typography variant="body2" sx={{ color: grey[600], fontWeight: 600 }}>
          {unit.title}
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
          {unit.subtitle || ""}
        </Typography>
      </Box>
    </>
  );
};

const UnitThumbnailContent = (props: UnitThumbnailContentProps) => {
  const { unit, brand, canShowDetail = true, padding = 2 } = props;

  const dispatch = useAppDispatch();

  const handleClick = (e: any) => {
    if (canShowDetail) {
      e.stopPropagation();
      dispatch(issueSlice.actions.selectedUnitIdSet(unit.id));
    }
  };

  if (!canShowDetail) {
    return (
      <Box
        sx={{
          display: "flex",
          alignAssets: "center",
          flexWrap: "wrap",
          p: padding,
        }}
      >
        <UnitThumbnailContentContent unit={unit} brand={brand} />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignAssets: "center",
          flexWrap: "wrap",
        }}
      >
        <Tooltip title="Zobrazit více informací o pobočce">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignAssets: "center",
              cursor: "pointer",
              "&:hover": {
                cursor: "pointer",
                ".MuiAvatar-root": {
                  borderColor: "rgb(255, 224, 178)",
                },
              },
            }}
            onClick={handleClick}
          >
            <UnitThumbnailContentContent unit={unit} brand={brand} />
          </Box>
        </Tooltip>
      </Box>
    </>
  );
};

export default UnitThumbnailContent;
