import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  InputAdornment,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { QrCodeScanner as QrCodeScannerIcon } from "@mui/icons-material";
import QrCodeScanner from "../../../common/components/QrCodeScanner/QrCodeScanner";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  selectAnonymous,
  selectApiErrorMessage,
  selectApiFetching,
  selectQrCode,
  selectUnitStoreNumber,
} from "../../selectors/assetFinder.selector";
import assetFinderSlice from "../../slices/assetFinder.slice";
import { selectProfile } from "../../../auth/selectors/authSelectors";
import { selectFlattenUnits } from "../../../enum/selectors/enumSelectors";

const AssetFinderForm = (props: any) => {
  const { handleSearch } = props;

  const dispatch = useAppDispatch();
  const qrCode = useAppSelector(selectQrCode);
  const unitStoreNumber = useAppSelector(selectUnitStoreNumber);
  const errorMessage = useAppSelector(selectApiErrorMessage);
  const fetching = useAppSelector(selectApiFetching);
  const anonymous = useAppSelector(selectAnonymous);
  const profile = useAppSelector(selectProfile);
  const units = useAppSelector(selectFlattenUnits);

  const setQrCode = (text: string) =>
    dispatch(assetFinderSlice.actions.setInputQrCode(text));
  const setUnitStoreNumber = (text: string) =>
    dispatch(assetFinderSlice.actions.setInputUnitStoreNumber(text));

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleScan = (text: string) => {
    setQrCode(text);
    handleClose();
    handleSearch({ unitStoreNumber, qrCode: text });
  };

  const handleSubmit = async () => {
    handleSearch({ qrCode, unitStoreNumber });
  };

  useEffect(() => {
    if (!anonymous) {
      if (units.length === 1) {
        setUnitStoreNumber(units[0].storeNumber);
      } else {
        setUnitStoreNumber("");
      }
    }
  }, [anonymous, units]);

  return (
    <Box>
      <TextField
        margin="normal"
        label={"QR kód"}
        required={true}
        fullWidth={true}
        autoFocus={true}
        value={!!qrCode ? qrCode : ""}
        onChange={(event) => {
          setQrCode(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ mr: -1 }}>
              <IconButton
                onClick={() => {
                  handleOpen();
                }}
                color={"secondary"}
              >
                <QrCodeScannerIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            handleSubmit();
            ev.preventDefault();
          }
        }}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ p: 0 }}>
          <QrCodeScanner onScan={handleScan} />
        </DialogContent>
      </Dialog>

      {anonymous && (
        <TextField
          margin="normal"
          label="Číslo střediska"
          required
          variant="outlined"
          fullWidth
          value={unitStoreNumber}
          onChange={(event) => {
            setUnitStoreNumber(event.target.value);
          }}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              handleSubmit();
              ev.preventDefault();
            }
          }}
        />
      )}

      {errorMessage !== "" && (
        <Alert sx={{ mt: 1 }} severity={"error"}>
          {errorMessage}
        </Alert>
      )}

      <LoadingButton
        disabled={fetching}
        loading={fetching}
        fullWidth
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={() => {
          handleSubmit();
        }}
      >
        Vyhledat
      </LoadingButton>
    </Box>
  );
};

export default AssetFinderForm;
