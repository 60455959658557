import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfile } from "../../../auth/selectors/authSelectors";
import { Nullable, ProfileType } from "../../../../types";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Hidden from "@mui/material/Hidden";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Footer from "../Footer/Footer";
import { logOut } from "../../../auth/utils/utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserTypeByCode } from "../../../../libraries/enums/userTypes";
import HeadwayWidget from "@querycz/headway-react-widget";
import { getNavigationMenuItemsForProfile } from "../../../../app/navigation.utils";
import AssetFinderDialog from "../../../assetFinder/components/AssetFinderDialog/AssetFinderDialog";
import { Menu as MenuIcon } from "@mui/icons-material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

const resolvePathname = (pathname: string) => {
  if (["/", "/dashboard", "/issues"].includes(pathname)) {
    return "/issues";
  }
  if (["/revisions"].includes(pathname)) {
    return "/revisions";
  }
  if (["/documents"].includes(pathname)) {
    return "/documents";
  }
  if (["/assets"].includes(pathname)) {
    return "/assets";
  }
};

const LayoutAppBarMenu = () => {
  const location = useLocation();
  const profile = useAppSelector(selectProfile);

  if (!profile) {
    return <></>;
  }

  return (
    <>
      <Tabs
        sx={{ flexGrow: 1 }}
        value={resolvePathname(location.pathname)}
        textColor={"secondary"}
        indicatorColor={"secondary"}
        scrollButtons={false}
        variant="scrollable"
      >
        {getNavigationMenuItemsForProfile(profile).map((item) => {
          return (
            <Tab
              key={item.to}
              value={item.to}
              label={item.label}
              component={Link}
              to={item.to}
              sx={{
                pt: 3.1,
                pb: 2.8,
              }}
            />
          );
        })}
      </Tabs>
    </>
  );
};

type LayoutAppBarProfileProps = {
  profile: Nullable<ProfileType>;
};
const LayoutAppBarProfile = (props: LayoutAppBarProfileProps) => {
  const { profile } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    logOut(dispatch, navigate);
  };

  const theme = useTheme();

  if (!profile) {
    return <></>;
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Hidden smDown>
        <Avatar
          sx={{ backgroundColor: "rgba(0, 0, 0, 0.08)", color: theme.palette.grey[700] }}
        >
          {React.createElement(getUserTypeByCode(profile.type).icon)}
        </Avatar>

        <Typography variant="subtitle2" sx={{ ml: 1, mr: 2, color: theme.palette.grey[700] }}>
          {profile.displayName}
        </Typography>
      </Hidden>

      {/* <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleLogOut();
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Odhlásit se" />
        </MenuItem>
      </Menu> */}
    </Box>
  );
};

const DrawerMenu = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const profile = useAppSelector(selectProfile);

  const handleLogOut = () => {
    logOut(dispatch, navigate);
  };

  const [assetDialogOpen, setAssetDialogOpen] = useState(false);

  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  if (!profile) {
    return <></>;
  }

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <Toolbar disableGutters={false}>
        <Avatar
          sx={{
            backgroundColor: (theme) => `${theme.palette.secondary.main}`,
          }}
        >
          {React.createElement(getUserTypeByCode(profile.type).icon)}
        </Avatar>

        <Typography variant="subtitle2" sx={{ ml: 1, mr: 2 }}>
          {profile.displayName}
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {getNavigationMenuItemsForProfile(profile).map((item) => {
          return (
            <ListItem key={item.to} disablePadding>
              <ListItemButton component={Link} to={item.to}>
                <ListItemIcon>{React.createElement(item.icon)}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Button
          fullWidth={true}
          color={"primary"}
          variant={"contained"}
          onClick={() => {
            setAssetDialogOpen(true);
          }}
          startIcon={<QrCodeScannerIcon />}
        >
          Skenovat QR kód
        </Button>
      </Box>
      <Divider />
      <List>
        <ListItemButton
          onClick={() => {
            handleLogOut();
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Odhlásit se" />
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <>
      <AssetFinderDialog open={assetDialogOpen} setOpen={setAssetDialogOpen} />
      <IconButton
        onClick={toggleDrawer(true)}
        color={"secondary"}
        sx={{
          // mr: 2,
          border: (theme) => `solid 1px ${theme.palette.secondary.main}`,
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
        {DrawerList}
      </Drawer>
    </>
  );
};

const LayoutAppBar = () => {
  const profile: Nullable<ProfileType> = useAppSelector(selectProfile);

  const [assetDialogOpen, setAssetDialogOpen] = useState(false);

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.6)",
          backdropFilter: "blur(8px)",
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          paddingTop: "env(safe-area-inset-top)",
        }}
      >
        <Toolbar>
          {/* <Hidden smDown> */}
          <Box
            display="flex"
            alignItems="center"
            sx={{
              img: {
                width: 100,
                height: "auto",
                mr: 3,
              },
            }}
          >
            <img
              alt="Lagardere Travel Retail"
              src="/lagardere-travel-retail-logo.svg"
            />
          </Box>
          {/* </Hidden> */}

          <Typography
            variant="h6"
            component="div"
            color="#013063"
            sx={{ mr: { xs: 2, md: 4 } }}
          >
            Fixnote
          </Typography>

          <Hidden lgDown>
            <LayoutAppBarMenu />
          </Hidden>

          <Box sx={{ flex: 1 }} />

          {/* <Hidden mdDown>
            <Button
              fullWidth={false}
              color={"primary"}
              variant={"contained"}
              onClick={() => {
                setAssetDialogOpen(true);
              }}
              startIcon={<QrCodeScannerIcon />}
            >
              Skenovat QR kód
            </Button>
            <AssetFinderDialog
              open={assetDialogOpen}
              setOpen={setAssetDialogOpen}
            />
          </Hidden> */}

          <Hidden smDown>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Box sx={{ mx: 3, mt: -1 }}>
                <HeadwayWidget
                  account="765vbx"
                  translations={{
                    title: "Přehled změn",
                    readMore: "Číst více",
                    footer: "Číst více",
                  }}
                />
              </Box>

              <Box
                sx={{
                  color: (theme) => theme.palette.grey[600],
                  textAlign: "right",
                }}
              >
                <LayoutAppBarProfile profile={profile} />
              </Box>
            </Box>
          </Hidden>

          <DrawerMenu />
        </Toolbar>
      </AppBar>
    </>
  );
};

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = (props: LayoutProps) => {
  return (
    <>
      <CssBaseline />
      <LayoutAppBar />
      <Toolbar />
      <Box sx={{ mt: 7 }}>{props.children}</Box>
      <Footer />
    </>
  );
};

export default Layout;
