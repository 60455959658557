import { Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { PERMISSION_RIGHT_CODE__createAsset } from "../../../../libraries/enums/permissionRights";
import { selectUnitBySelectedUnitId } from "../../../enum/selectors/enumSelectors";
import assetSlice from "../../slices/asset.slice";
import { addUnitIntoAsset, buildAsset } from "../../builders/asset.builder";

const AssetCreateButton = (props: any) => {
  const { btnProps = {} } = props;

  const dispatch = useAppDispatch();

  const unit = useAppSelector(selectUnitBySelectedUnitId);
  const rightCreateAsset = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createAsset,
    })
  );

  const handleCreateItem = () => {
    const asset = buildAsset();
    if (!!unit) {
      addUnitIntoAsset(asset, unit);
    }
    dispatch(assetSlice.actions.selectedAssetSet(asset));
    dispatch(assetSlice.actions.selectedAssetModeSet("create"));
  };

  if (!rightCreateAsset) {
    return <></>;
  }

  return (
    <>
      <Button
        onClick={handleCreateItem}
        color={"primary"}
        variant={"contained"}
        startIcon={<AddCircleIcon />}
        size="large"
        {...btnProps}
      >
        Přidat zařízení
      </Button>
    </>
  );
};

export default AssetCreateButton;
