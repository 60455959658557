import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import CloseButton from "../../../../common/components/CloseButton/CloseButton";
import DoneIcon from "@mui/icons-material/Done";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import assetSlice from "../../../slices/asset.slice";
import store, {
  useAppDispatch,
  useAppSelector,
} from "../../../../../app/store";
import {
  selectSelectedAsset,
  selectSelectedAssetMode,
  selectSelectedAssetProperty,
} from "../../../selectors/asset.selector";
import { selectFlattenUnits } from "../../../../enum/selectors/enumSelectors";
import AssetDetailFieldFile from "./AssetDetailFieldFile";
import { manufacturerGetAll, supplierGetAll } from "../../../../../api/api";
import AssetDetailFieldQrCode from "./AssetDetailFieldQrCode";
import FormFieldAutocomplete from "../../../../common/components/Form/FormFieldAutocomplete";
import GridField from "../../../../common/components/Grid/GridField";
import FormFieldAutocompleteUnit from "../../../../common/components/Form/FormFieldAutocompleteUnit";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  ASSET_STATUS_CODE__new,
  ASSET_STATUS_CODE__used,
  getAssetStatuses,
} from "../../../../../libraries/enums/assetStatuses";

// @ts-ignore
const BaseField = ({ property, label, ...rest }) => {
  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectSelectedAssetProperty(state, { property })
  );
  const changeValue = (value: any) => {
    const payload = [];
    payload.push({
      property,
      value,
    });
    dispatch(assetSlice.actions.selectedAssetPropertiesSet(payload));
  };

  return (
    <TextField
      label={label}
      fullWidth={true}
      value={value || ""}
      onChange={(event) => {
        changeValue(event.target.value);
      }}
      {...rest}
    />
  );
};

// @ts-ignore
const SingleChoiceBaseField = ({ property, label, options, ...rest }) => {
  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectSelectedAssetProperty(state, { property })
  );
  const changeValue = (value: any) => {
    const payload = [];
    payload.push({
      property,
      value,
    });
    dispatch(assetSlice.actions.selectedAssetPropertiesSet(payload));
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value || "-1"}
        label={label}
        onChange={(event) => {
          changeValue(event.target.value);
        }}
        {...rest}
      >
        {options.map((option: any) => {
          return (
            <MenuItem key={option.code} value={option.code}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const FieldDeliveryDate = () => {
  const label = "Datum dodání";
  const property = "deliveryDate";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectSelectedAssetProperty(state, { property })
  );

  const changeValue = (value: any) => {
    const payload = [
      {
        property,
        value,
      },
      {
        property: "deliveryDateFormat",
        value: "Y-m",
      },
    ];
    dispatch(assetSlice.actions.selectedAssetPropertiesSet(payload));
  };

  return (
    <DatePicker
      label="Datum dodání"
      value={value}
      views={["year", "month"]}
      onChange={(newValue) => {
        changeValue(newValue);
      }}
      renderInput={(params: any) => <TextField fullWidth {...params} />}
    />
  );
};

const FieldUnit = () => {
  const label = "Středisko";
  const property = "input.unitId";

  const dispatch = useAppDispatch();
  const selectedUnitId = useAppSelector((state) =>
    selectSelectedAssetProperty(state, { property })
  );
  const options = useAppSelector((state) => selectFlattenUnits(state));

  const handleChange = (payload: any) => {
    dispatch(assetSlice.actions.selectedAssetPropertiesSet(payload));
  };

  return (
    <FormFieldAutocompleteUnit
      label={label}
      property={property}
      defaultOptionId={(selectedUnitId || null) as any}
      creatable={false}
      options={options}
      onChange={handleChange}
    />
  );
};

const FieldStatus = () => (
  <SingleChoiceBaseField
    property={"status.id"}
    label={"Stav"}
    options={[{ code: "-1", label: "Neuvedeno" }].concat(
      getAssetStatuses().map((o) => ({ code: o.code, label: o.label }))
    )}
  />
);
const FieldOwnershipType = () => (
  <SingleChoiceBaseField
    property={"ownershipType.id"}
    label={"Typ vlastnictví"}
    options={[
      { code: "-1", label: "Neuvedeno" },
      { code: "own", label: "Vlastní" },
      { code: "leased", label: "Pronajaté" },
    ]}
  />
);

const FieldInventoryNumber = () => (
  <BaseField property={"inventoryNumber"} label={"Inventární číslo"} />
);
const FieldTitle = () => <BaseField property={"title"} label={"Název"} />;
const FieldUnitPlacement = () => (
  <BaseField property={"input.unitPlacement"} label={"Specifikace umístění"} />
);
const FieldSerialNumber = () => (
  <BaseField property={"serialNumber"} label={"Sériové číslo"} />
);
const FieldProducerNumber = () => (
  <BaseField property={"producerNumber"} label={"Výrobní číslo"} />
);
const FieldManufacturer = () => {
  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectSelectedAssetProperty(state, { property: "input.manufacturer" })
  );
  return (
    <FormFieldAutocomplete
      property={"input.manufacturer"}
      label={"Výrobce"}
      defaultOptionId={value?.id || null}
      dialog={{
        headerText: "Založit nového výrobce",
        contentText: "Zadejte název nového výrobce",
      }}
      options={manufacturerGetAll}
      onChange={(payload) => {
        dispatch(assetSlice.actions.selectedAssetPropertiesSet(payload));
      }}
      creatable={true}
    />
  );
};
const FieldSupplier = () => {
  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectSelectedAssetProperty(state, { property: "input.supplier" })
  );
  return (
    <FormFieldAutocomplete
      property={"input.supplier"}
      label={"Dodavatel"}
      defaultOptionId={value?.id || null}
      dialog={{
        headerText: "Založit nového dodavatele",
        contentText: "Zadejte název nového dodavatele",
      }}
      options={supplierGetAll}
      onChange={(payload) => {
        dispatch(assetSlice.actions.selectedAssetPropertiesSet(payload));
      }}
      creatable={true}
    />
  );
};

const FieldImage = () => <AssetDetailFieldFile type={"asset_photo"} />;
const FieldImageLabel = () => <AssetDetailFieldFile type={"asset_label"} />;
const FieldQrCode = () => <AssetDetailFieldQrCode />;

const AssetDetailModeUpdate = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: any;
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const mode = useAppSelector(selectSelectedAssetMode);
  // const unitIds = useAppSelector((state) =>
  //   selectSelectedIssueProperty(state, { property: "unitIds" })
  // );

  const [errors, setErrors] = useState<any>([]);

  const validate = () => {
    const errors = [];
    const state = store.getState();
    const asset = selectSelectedAsset(state);
    if (!!asset) {
      if (!asset.input || !asset.input.unitId) {
        errors.push("Středisko musí být vyplněno.");
      }
      if (!asset.title) {
        errors.push("Pole Název musí být vyplněno.");
      }
    }

    return errors;
  };

  const handleSubmit = () => {
    const errors = validate();
    if (errors.length === 0) {
      dispatch(assetSlice.actions.selectedAssetSubmit());
      if (mode === "update") {
        dispatch(assetSlice.actions.selectedAssetModeSet("read"));
      } else {
        handleClose();
      }
    } else {
      setErrors(errors);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="sm"
      fullScreen={mdUp ? false : true}
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
      }}
    >
      <DialogTitle>
        {mode === "create" && <>Přidat zařízení</>}
        {mode === "update" && <>Upravit zařízení</>}
        <CloseButton onClick={handleClose} />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <GridField gridProps={{ xs: 6 }} component={FieldStatus} />
          <GridField gridProps={{ xs: 6 }} component={FieldOwnershipType} />
          <GridField gridProps={{ xs: 12 }} component={FieldTitle} />
          <GridField gridProps={{ xs: 12 }} component={FieldUnit} />
          <GridField gridProps={{ xs: 12 }} component={FieldUnitPlacement} />
          <GridField gridProps={{ xs: 6 }} component={FieldInventoryNumber} />
          <GridField gridProps={{ xs: 6 }} component={FieldQrCode} />
          <GridField gridProps={{ xs: 6 }} component={FieldProducerNumber} />
          <GridField gridProps={{ xs: 6 }} component={FieldSerialNumber} />
          <GridField gridProps={{ xs: 12 }} component={FieldManufacturer} />
          <GridField gridProps={{ xs: 12 }} component={FieldSupplier} />
          <GridField gridProps={{ xs: 12 }} component={FieldDeliveryDate} />
          <GridField gridProps={{ xs: 12 }} component={FieldImage} />
          <GridField gridProps={{ xs: 12 }} component={FieldImageLabel} />
        </Grid>
        {errors.length > 0 && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {errors.join(" ")}
          </Alert>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant={"contained"}
          color={"primary"}
          startIcon={<DoneIcon />}
          onClick={() => {
            handleSubmit();
          }}
        >
          {mode === "create" && <>Vytvořit zařízení</>}
          {mode === "update" && <>Upravit zařízení</>}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssetDetailModeUpdate;
