import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssetMovementDto } from "../../../types/asset.types";
import { Nullable } from "../../../types";

interface State {
  selectedAssetMovementDto: Nullable<AssetMovementDto>;
  selectedAssetMovementDtoMode: "create" | "update" | "read";
}

const initialState: State = {
  selectedAssetMovementDto: null,
  selectedAssetMovementDtoMode: "read",
};

const setPropertyByPath = (obj: any, path: string, value: any) => {
  if (!obj || !path) {
    return;
  }

  // Rozdělí cestu na jednotlivé klíče
  const keys = path.split(".");
  let current = obj;

  // Iteruje přes klíče kromě posledního, postupně prochází nebo vytváří objekty
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];

    // Pokud klíč neexistuje nebo není objektem, vytvoří nový objekt
    if (current[key] === undefined || typeof current[key] !== "object") {
      current[key] = {};
    }

    current = current[key];
  }

  // Nastaví hodnotu na poslední klíč v cestě
  current[keys[keys.length - 1]] = value;
};

const assetMovementSlice = createSlice({
  name: "assetMovement",
  initialState: initialState,
  reducers: {
    selectedAssetMovementDtoSet: (
      state,
      action: PayloadAction<Nullable<AssetMovementDto>>
    ) => {
      state.selectedAssetMovementDto = action.payload;
    },
    selectedAssetMovementDtoModeSet: (
      state,
      action: PayloadAction<"create" | "update" | "read">
    ) => {
      state.selectedAssetMovementDtoMode = action.payload;
    },
    selectedAssetMovementDtoPropertySet: (
      state,
      action: PayloadAction<any>
    ) => {
      if (!!state.selectedAssetMovementDto) {
        const property = action.payload.property;
        const value = action.payload.value;
        setPropertyByPath(state.selectedAssetMovementDto, property, value);
      }
    },
    selectedAssetMovementDtoPropertiesSet: (
      state,
      action: PayloadAction<any>
    ) => {
      if (!!state.selectedAssetMovementDto) {
        for (let i = 0; i < action.payload.length; i++) {
          const property = action.payload[i].property;
          const value = action.payload[i].value;
          setPropertyByPath(state.selectedAssetMovementDto, property, value);
        }
      }
    },
  },
});

export default assetMovementSlice;
