import store, { RootState } from "../../../app/store";
import { createSelector } from "@reduxjs/toolkit";
import { selectProfile } from "../../auth/selectors/authSelectors";
import { ASSET_FINDER_ASSET_ACTIONS } from "../../../libraries/enums/assetFinderAssetActions";
import { AssetType } from "../../../types/asset.types";
import {
  USER_TYPE_CODE__headquarters,
  USER_TYPE_CODE__unit,
} from "../../../libraries/enums/userTypes";
import {
  selectAssetRelatedEntityIdOfAssetRelationLast,
  selectAssetRelationLast,
  selectAssetsAll,
} from "../../asset/selectors/asset.selector";
import { selectUnitEntities } from "../../enum/selectors/enumSelectors";
import { getLastAssetMovement } from "../../asset/utils/assetMovement.utils";
import {
  ASSET_MOVEMENT_DIRECTION_CODE__in,
  ASSET_MOVEMENT_DIRECTION_CODE__out,
} from "../../../libraries/enums/assetMovementDirections";
import { isOlderThanXDaysOrEmpty } from "../../asset/utils/assetUnitRelationPresence.utils";

export const selectAnonymous = (state: RootState) =>
  state.assetFinder.anonymous;

export const selectUnitStoreNumber = (state: RootState) =>
  state.assetFinder.input.unitStoreNumber;

// Selector pro 'qrCode'
export const selectQrCode = (state: RootState) =>
  state.assetFinder.input.qrCode;

// Selector pro 'fetching' stav API
export const selectApiFetching = (state: RootState) =>
  state.assetFinder.api.fetching;

// Selector pro 'errorMessage' od API
export const selectApiErrorMessage = (state: RootState) =>
  state.assetFinder.api.errorMessage;

export const selectAssetFinderSearchedAssetIds = (state: RootState) =>
  state.assetFinder.searchedAssetIds;

export const selectAssetFinderActionsForAsset = createSelector(
  [selectProfile, selectUnitEntities, (_, args) => args.asset],
  (profile, units, asset: AssetType) => {
    const actions = [];

    if (!profile) {
      return [];
    }

    const lastMovement = getLastAssetMovement(asset);

    const lastAssetUnitRelation = selectAssetRelationLast(store.getState(), {
      assetId: asset.id,
      relationName: "unitRelations",
    });

    const lastUnitId = selectAssetRelatedEntityIdOfAssetRelationLast(
      store.getState(),
      {
        assetId: asset.id,
        relationName: "unitRelations",
        entityName: "unit",
      }
    );
    const lastUnit = units[lastUnitId];

    if (profile.type === USER_TYPE_CODE__headquarters) {
      actions.push(ASSET_FINDER_ASSET_ACTIONS.SHOW_DETAIL);
    }

    if (profile.type === USER_TYPE_CODE__unit) {
      if (
        (lastMovement?.direction?.id || ASSET_MOVEMENT_DIRECTION_CODE__out) ===
          ASSET_MOVEMENT_DIRECTION_CODE__in &&
        !!lastUnit &&
        lastUnit.title === profile.displayName
      ) {
        actions.push(ASSET_FINDER_ASSET_ACTIONS.SHOW_DETAIL);
        actions.push(ASSET_FINDER_ASSET_ACTIONS.CREATE_MOVEMENT_OUT);
      }
      if (
        (lastMovement?.direction?.id || ASSET_MOVEMENT_DIRECTION_CODE__out) ===
        ASSET_MOVEMENT_DIRECTION_CODE__out
      ) {
        actions.push(ASSET_FINDER_ASSET_ACTIONS.SHOW_DETAIL);
        actions.push(ASSET_FINDER_ASSET_ACTIONS.CREATE_MOVEMENT_IN);
      }
    }

    if (isOlderThanXDaysOrEmpty(lastAssetUnitRelation, 0)) {
      actions.push(ASSET_FINDER_ASSET_ACTIONS.CHECK_PRESENCE_AND_EXISTS);
      actions.push(
        ASSET_FINDER_ASSET_ACTIONS.CHECK_PRESENCE_AND_DOES_NOT_EXIST
      );
    }

    return actions;
  }
);

export const selectAssetsSearchedAssetIds = createSelector(
  [selectAssetsAll, selectAssetFinderSearchedAssetIds],
  (assets, assetIds) => {
    return assets.filter((a) => assetIds.includes(a.id));
  }
);
