//@ts-nocheck

import React from "react";
import { Chip } from "@mui/material";
import { getRevisionTypeByCode } from "../../../../libraries/enums/revisionTypes";

const ChipRevisionType = (props) => {
  const {
    revisionTypeCode,
    label,
    active = true,
    selected = true,
    iconProps = { fontSize: "small" },
  } = props;

  const revisionType = getRevisionTypeByCode(revisionTypeCode);

  return (
    <Chip
      icon={React.createElement(revisionType.icon, iconProps)}
      label={label}
      size="small"
      sx={{
        bgcolor: revisionType.bgcolor,
        opacity: selected ? 1 : 0.4,
      }}
    />
  );
};

export default ChipRevisionType;
