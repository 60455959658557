import { revisionEntityAdapter } from "../slices/revisionSlice";
import { RootState } from "../../../app/store";
import { createSelector } from "@reduxjs/toolkit";
import { RevisionFilterType } from "../../../types";
import {
  selectBrands,
  selectUnitEntities,
  selectUnits,
} from "../../enum/selectors/enumSelectors";
import { selectProfile } from "../../auth/selectors/authSelectors";
import { EARLY_EXPIRATION_DAYS } from "../constants/revision.constants";
import {
  REVISION_STATUS_CODE__invalidDueToExpiration,
  REVISION_STATUS_CODE__valid,
} from "../../../libraries/enums/revisionStatuses";
import {
  getRevisionExpirationStatusByRevision,
  REVISION_EXPIRATION_STATUS_replaced,
} from "../../../libraries/enums/revisionExpirationStatuses";

const revisionListSelector = revisionEntityAdapter.getSelectors(
  (state: RootState) => state.revision.list
);

export const selectRevisionIds = revisionListSelector.selectIds;
export const selectRevisionEntities = revisionListSelector.selectEntities;
export const selectRevisionsAll = revisionListSelector.selectAll;
export const selectRevisionById = revisionListSelector.selectById;

export const selectListFetching = (state: RootState) =>
  state.revision.listFetching;

export const selectSelectedRevision = (state: RootState) =>
  state.revision.selectedRevision;
export const selectHasSelectedRevision = (state: RootState) =>
  !!state.revision.selectedRevision;
export const selectSelectedRevisionMode = (state: RootState) =>
  state.revision.selectedRevisionMode;

export const selectSelectedRevisionProperty = createSelector(
  [selectSelectedRevision, (state, args) => args.property],
  (revision, property) => {
    if (!revision) {
      return null;
    }

    // @ts-ignore
    return revision[property];
  }
);

/**
 * Returns true if filter is enabled
 * @param state
 */
export const selectRevisionFilter = (state: RootState): RevisionFilterType =>
  state.revision.filter;
export const selectRevisionFilterIsEnabled = createSelector(
  [selectRevisionFilter],
  (f) => {
    return (
      f.expirationStatusIds.length > 0 ||
      f.statusIds.length > 0 ||
      f.typeIds.length > 0 ||
      f.unitIds.length > 0 ||
      f.brandIds.length > 0 ||
      f.brandGroupIds.length > 0 ||
      f.revisionCompanyIds.length > 0 ||
      f.revisionSubjectIds.length > 0 ||
      f.withDefectsOnly
    );
  }
);
export const selectRevisionFilterProperty = createSelector(
  [selectRevisionFilter, (state, args) => args.property],
  (filter, property) => {
    if (!filter) {
      return null;
    }

    // @ts-ignore
    return filter[property];
  }
);

export const selectRevisionsFilteredAndSorted = createSelector(
  [selectRevisionsAll, selectRevisionFilter, selectUnitEntities, selectProfile],
  (revisions, filter, unitEntities, profile) => {
    if (!profile) {
      return [];
    }

    const emptyOrInArray = (
      search: string | null,
      array: string[]
    ): boolean => {
      if (array.length === 0) {
        return true;
      }
      if (!search) {
        return false;
      }
      return array.includes(search);
    };

    return revisions
      .filter((revision) => {
        // @ts-ignore
        const unit = unitEntities[revision.unitId];
        return (
          emptyOrInArray(
            getRevisionExpirationStatusByRevision(revision).code,
            filter.expirationStatusIds
          ) &&
          (filter.expirationStatusIds.includes(
            REVISION_EXPIRATION_STATUS_replaced
          ) ||
            getRevisionExpirationStatusByRevision(revision).code !==
              REVISION_EXPIRATION_STATUS_replaced) &&
          emptyOrInArray(revision.typeId, filter.typeIds) &&
          emptyOrInArray(
            revision.unitId as unknown as string,
            filter.unitIds
          ) &&
          emptyOrInArray(
            revision.revisionCompanyId as unknown as string,
            filter.revisionCompanyIds
          ) &&
          emptyOrInArray(
            revision.revisionSubjectId as unknown as string,
            filter.revisionSubjectIds
          ) &&
          emptyOrInArray(
            (unit?.brandId as unknown as string) || null,
            filter.brandIds
          ) &&
          (!filter.withDefectsOnly || revision.withDefects)
        );
      })
      .sort((a, b) => {
        const aX =
          !a.durationInDaysUntilNextRevision &&
          a.durationInDaysUntilNextRevision !== 0
            ? 999999
            : a.durationInDaysUntilNextRevision;

        const bX =
          !b.durationInDaysUntilNextRevision &&
          b.durationInDaysUntilNextRevision !== 0
            ? 999999
            : b.durationInDaysUntilNextRevision;

        if (aX === bX) {
          const aY = unitEntities[a.unitId];
          const bY = unitEntities[b.unitId];
          if (!aY || !bY) {
            return 0;
          }
          return aY.storeNumber.localeCompare(bY.storeNumber);
        }

        return aX - bX;
      });
  }
);

export const selectRevisionsFilteredAndSortedCount = createSelector(
  [selectRevisionsFilteredAndSorted],
  (revisions) => {
    return revisions.length;
  }
);

export const selectRevisionsByUnitId = createSelector(
  [selectRevisionsAll, (state, args) => args.unitId],
  (revisions, unitId) => {
    return revisions.filter((r) => {
      return (
        r.unitId === unitId &&
        [REVISION_STATUS_CODE__valid].includes(r.statusId)
      );
    });
  }
);

export const selectListConfig = (state: RootState) => {
  return state.revision.listConfig;
};

export const selectRevisionIdsFilteredAndSorted = createSelector(
  [selectRevisionsFilteredAndSorted, selectListConfig],
  (revisions, listConfig) => {
    return revisions
      .map((revision) => {
        return revision.id;
      })
      .slice(
        listConfig.page * listConfig.pageSize,
        (listConfig.page + 1) * listConfig.pageSize
      );
  }
);

export const selectBrandsWithStats = createSelector(
  [selectRevisionsAll, selectBrands, selectUnits],
  (revisions, brands, units) => {
    return brands.map((brand) => {
      const unitIdsOfBrand = units
        .filter((u) => (u.brandId as unknown as string) === brand.id)
        .map((u) => u.id);
      return {
        ...brand,
        stats: {
          [REVISION_STATUS_CODE__invalidDueToExpiration]: revisions.filter(
            (r) =>
              r.statusId === REVISION_STATUS_CODE__invalidDueToExpiration &&
              unitIdsOfBrand.includes(r.unitId)
          ).length,
          EARLY_EXPIRATION: revisions.filter(
            (r) =>
              r.durationInDaysUntilNextRevision < EARLY_EXPIRATION_DAYS &&
              r.durationInDaysUntilNextRevision >= 0 &&
              unitIdsOfBrand.includes(r.unitId)
          ).length,
        },
      };
    });
  }
);

export const selectRevisionActionCodesByRevisionIdAndProfile = createSelector(
  [selectProfile, selectRevisionEntities, (state, args) => args.revisionId],
  (profile, revisions, revisionId) => {
    return [];
  }
);
