import { useTheme } from "@mui/material/styles";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  useMediaQuery,
} from "@mui/material";
import store, { useAppDispatch, useAppSelector } from "../../../../app/store";
import React, { useEffect, useState } from "react";
import CloseButton from "../../../common/components/CloseButton/CloseButton";
import DoneIcon from "@mui/icons-material/Done";
import {
  selectHasSelectedDocument,
  selectSelectedDocument,
  selectSelectedDocumentMode,
} from "../../selectors/document.selectors";
import documentSlice from "../../slices/document.slice";
import DocumentDetailModeUpdate from "./DocumentDetailModeUpdate";

const DocumentDetail = () => {
  const dispatch = useAppDispatch();

  const theme = useTheme();

  const hasDocument = useAppSelector(selectHasSelectedDocument);
  const mode = useAppSelector(selectSelectedDocumentMode);
  // const templateId = useAppSelector((state) =>
  //   selectSelectedDocumentProperty(state, { property: "templateId" })
  // );
  // const documentTitle = useAppSelector((state) =>
  //   selectSelectedDocumentProperty(state, { property: "title" })
  // );

  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<any>([]);

  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const validate = () => {
    const errors: any[] = [];
    const state = store.getState();
    const document = selectSelectedDocument(state);
    if (!!document) {
      if (!document.title && !document.isNotNecessary) {
        errors.push("Název dokument je povinné pole.");
      }
      if (!document.fileContent && !document.isNotNecessary) {
        errors.push("K dokumentu musí být nahrán soubor.");
      }
    }

    return errors;
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setErrors([]);
      dispatch(documentSlice.actions.selectedDocumentSet(null));
      dispatch(documentSlice.actions.selectedDocumentModeSet(null));
    }, 250);
  };

  const handleSubmit = () => {
    const errors = validate();
    if (errors.length === 0) {
      dispatch(documentSlice.actions.selectedDocumentSubmit());
      if (mode === "update") {
        dispatch(documentSlice.actions.selectedDocumentModeSet("read"));
      } else {
        handleClose();
      }
    } else {
      setErrors(errors);
    }
  };

  useEffect(() => {
    if (hasDocument) {
      setErrors([]);
      setOpen(true);
    }
  }, [hasDocument]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="sm"
      fullScreen={mdUp ? false : true}
    >
      <DialogTitle>
        {mode === "create" && <>Přidat dokument</>}
        {mode === "update" && <>Zpracovat dokument</>}
        <CloseButton onClick={handleClose} />
      </DialogTitle>
      <Divider />
      <DialogContent>
        {mode === "create" && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            Každý dokument prosím zadávejte jednotlivě
          </Alert>
        )}
        <DocumentDetailModeUpdate />
        {errors.length > 0 && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {errors.map((error: string) => (
              <Box key={error}>{error}</Box>
            ))}
          </Alert>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant={"contained"}
          color={"primary"}
          startIcon={<DoneIcon />}
          onClick={() => {
            handleSubmit();
          }}
        >
          {mode === "create" && <>Uložit dokument</>}
          {mode === "update" && <>Uložit dokument</>}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentDetail;
