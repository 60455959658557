import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { green, orange } from "@mui/material/colors";

export const ISSUE_STATUS_CODE__new = "new";
export const ISSUE_STATUS_CODE__inProgress = "inProgress";
export const ISSUE_STATUS_CODE__done = "done";

const ISSUE_STATUSES = {
  [ISSUE_STATUS_CODE__new]: {
    code: ISSUE_STATUS_CODE__new,
    icon: NewReleasesIcon,
    color: "#000000",
    bgcolor: orange[100],
    label: "Nový",
  },
  [ISSUE_STATUS_CODE__inProgress]: {
    code: ISSUE_STATUS_CODE__inProgress,
    icon: SettingsIcon,
    color: "#000000",
    bgcolor: green[100],
    label: "V řešení",
  },
  [ISSUE_STATUS_CODE__done]: {
    code: ISSUE_STATUS_CODE__done,
    icon: CheckCircleIcon,
    color: "#000000",
    bgcolor: "rgba(0, 0, 0, 0.04)",
    label: "Uzavřený",
  },
};

export type issueStatusCodeType = keyof typeof ISSUE_STATUSES;

export const getIssueStatusByCode = (code: issueStatusCodeType) => {
  return ISSUE_STATUSES[code];
};

export const getIssueStatuses = () => {
  return Object.values(ISSUE_STATUSES);
};
