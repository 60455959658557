import { format as dateFnsFormat, formatDistance } from "date-fns";
import { cs } from "date-fns/locale";

export const formatDate = (dtString: string, format: string = "d. L.") => {
  const dt = new Date(dtString);
  const nonBreakSpace = "\u00A0";
  return dateFnsFormat(dt, format, { locale: cs }).replace(/ /g, nonBreakSpace);
};

export const formatDateDistanceToNow = (dtString: string) => {
  const dt = new Date(dtString);
  return formatDistance(dt, new Date(), { locale: cs, addSuffix: true });
};

export const formatDateFromISOToYMD = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const formatDateTimeToISO = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
};

const getMonthsOfDateInterval = (intervalString: string) => {
  return parseInt(intervalString.replace("P", "").replace("M", ""));
};

export const getDateIntervalDescription = (intervalString: string) => {
  if (intervalString === "") {
    return "";
  }
  const months = getMonthsOfDateInterval(intervalString);

  return months === 1
    ? months + " měsíc"
    : [2, 3, 4].includes(months)
    ? months + " měsíce"
    : months + " měsíců";
};

export const addIntervalToDate = (
  dateString: string,
  intervalString: string
): Date => {
  const dt = new Date(dateString);
  dt.setMonth(dt.getMonth() + getMonthsOfDateInterval(intervalString));

  return dt;
};
