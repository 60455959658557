import React, { createElement } from "react";
import { fieldUnitRelations } from "./fieldUnitRelations";

const Field = ({
  assetId,
  isDetail = false,
}: {
  assetId: string;
  isDetail?: boolean;
}) => {
  return (
    <>
      {createElement(fieldUnitRelations.component, {
        assetId,
        isDetail,
        context: "DETAIL_UNIT",
      })}
    </>
  );
};

export const fieldUnit = {
  code: "fieldUnit",
  label: "Středisko",
  valueGetterFn: (obj: any) => {
    return "";
  },
  component: Field,
};
