import FactoryIcon from "@mui/icons-material/Factory";
import { grey } from "@mui/material/colors";
import { Chip } from "@mui/material";
import React from "react";
import { ChipProps } from "./interfaces";

const ChipManufacturer = (props: ChipProps) => {
  const { label, size = "medium" } = props;

  return (
    <Chip
      label={label}
      icon={<FactoryIcon />}
      size={size}
      sx={{
        mr: 1,
        color: grey[600],
        bgcolor: "rgba(0, 0, 0, 0.04)",
      }}
    />
  );
};

export default ChipManufacturer;
