import { RevisionFilterType } from "../../../types";

export const buildInitialFilter = () => {
  const filter: RevisionFilterType = {
    brandIds: [],
    brandGroupIds: [],
    statusIds: [],
    expirationStatusIds: [],
    typeIds: [],
    unitIds: [],
    revisionCompanyIds: [],
    revisionSubjectIds: [],
    withDefectsOnly: false,
  };

  return filter;
};
