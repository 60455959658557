import { useLayoutEffect } from "react";
import { useAppDispatch } from "../../../../app/store";
import { entryLocationSave } from "../../actions/shareEntity.actions";

const ShareEntityProvider = (props: any) => {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(entryLocationSave());
  }, []);

  return <>{props.children}</>;
};

export default ShareEntityProvider;
