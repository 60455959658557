import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#ffffff",
      main: "#007bff",
    },
    secondary: {
      main: "#e50178",
    },
    text: {
      primary: "#12161B",
    },
    background: {
      default: "#f8fafc",
    },
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover td": {
            backgroundColor: "#e1f5fe !important",
            opacity: "1",
          },
        },
      },
    },
  },
});

export default theme;
