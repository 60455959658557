import { green, grey, orange, red } from "@mui/material/colors";
import {
  KeyboardTab,
  SentimentNeutral,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
} from "@mui/icons-material";
import { RevisionType } from "../../types";
import {
  REVISION_TYPE_CODE__initial,
  REVISION_TYPE_CODE__regular,
} from "./revisionTypes";
import {
  EARLY_EXPIRATION_DAYS,
  INVALID_DUE_REPLACEMENT,
} from "../../features/revision/constants/revision.constants";

export const REVISION_EXPIRATION_STATUS_before = "before"; //revize je před expirací
export const REVISION_EXPIRATION_STATUS_expiring = "expiring"; //revize brzy expiruje
export const REVISION_EXPIRATION_STATUS_after = "after"; //revize je po expiraci
export const REVISION_EXPIRATION_STATUS_replaced = "replaced"; //revize je nahrazena

const REVISION_EXPIRATION_STATUSES = {
  [REVISION_EXPIRATION_STATUS_before]: {
    code: REVISION_EXPIRATION_STATUS_before,
    icon: SentimentVerySatisfied,
    color: "#000000",
    bgcolor: green[100],
    label: "Před expirací",
    field: {
      props: {
        sx: {
          fontSize: "90%",
        },
      },
      getText: (revision: RevisionType) => {
        return "Za " + revision.durationInDaysUntilNextRevision + " dní";
      },
    },
  },
  [REVISION_EXPIRATION_STATUS_expiring]: {
    code: REVISION_EXPIRATION_STATUS_expiring,
    icon: SentimentNeutral,
    color: "#000000",
    bgcolor: orange[100],
    label: "Expirující",
    field: {
      props: {
        sx: {
          fontSize: "90%",
          fontWeight: 500,
          color: orange[400],
        },
      },
      getText: (revision: RevisionType) => {
        return "Za " + revision.durationInDaysUntilNextRevision + " dní";
      },
    },
  },
  [REVISION_EXPIRATION_STATUS_after]: {
    code: REVISION_EXPIRATION_STATUS_after,
    icon: SentimentVeryDissatisfied,
    color: "#000000",
    bgcolor: red[100],
    label: "Po expiraci",
    field: {
      props: {
        sx: {
          fontSize: "90%",
          fontWeight: 500,
          color: red[400],
        },
      },
      getText: (revision: RevisionType) => {
        return "Před " + revision.durationInDaysUntilNextRevision + " dny";
      },
    },
  },
  [REVISION_EXPIRATION_STATUS_replaced]: {
    code: REVISION_EXPIRATION_STATUS_replaced,
    icon: KeyboardTab,
    color: "#000000",
    bgcolor: grey[100],
    label: "Nahrazená",
    field: {
      props: {
        sx: {
          fontSize: "90%",
          fontWeight: 500,
          color: grey[400],
        },
      },
      getText: (revision: RevisionType) => {
        return "";
      },
    },
  },
};

export type revisionStatusCodeType = keyof typeof REVISION_EXPIRATION_STATUSES;

export const getRevisionExpirationStatusByCode = (
  code: revisionStatusCodeType
) => {
  return REVISION_EXPIRATION_STATUSES[code];
};

export const getRevisionExpirationStatuses = () => {
  return Object.values(REVISION_EXPIRATION_STATUSES);
};

export const getRevisionExpirationStatusByRevision = (
  revision: RevisionType
): any | null => {
  if (
    [REVISION_TYPE_CODE__regular, REVISION_TYPE_CODE__initial].includes(
      revision.typeId
    )
  ) {
    if (revision.statusId === INVALID_DUE_REPLACEMENT) {
      return REVISION_EXPIRATION_STATUSES[REVISION_EXPIRATION_STATUS_replaced];
    }
    if (revision.durationInDaysUntilNextRevision >= EARLY_EXPIRATION_DAYS) {
      return REVISION_EXPIRATION_STATUSES[REVISION_EXPIRATION_STATUS_before];
    }
    if (revision.durationInDaysUntilNextRevision >= 0) {
      return REVISION_EXPIRATION_STATUSES[REVISION_EXPIRATION_STATUS_expiring];
    }
    if (revision.durationInDaysUntilNextRevision < 0) {
      return REVISION_EXPIRATION_STATUSES[REVISION_EXPIRATION_STATUS_after];
    }
  }

  return REVISION_EXPIRATION_STATUSES[REVISION_EXPIRATION_STATUS_before];
};
