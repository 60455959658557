import React from "react";
import { Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { selectSelectedIssueProperty } from "../../../selectors/issueSelectors";
import issueSlice from "../../../slices/issueSlice";
import SendIcon from "@mui/icons-material/Send";

const IssueDetailFieldServiceCompanySentToBtn = () => {
  const dispatch = useAppDispatch();

  const changeValue = () => {
    dispatch(
      issueSlice.actions.selectedIssuePropertiesSet([
        { property: "sentToServiceCompanyAt", value: new Date().toISOString() },
        { property: "attachmentInputs", value: [] },
      ])
    );
  };

  const serviceCompanyId = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "serviceCompanyId" })
  );

  if (!serviceCompanyId) {
    return <></>;
  }

  return (
    <Button
      onClick={() => {
        changeValue();
      }}
      sx={{ mt: 2, mb: 2 }}
      size={"small"}
      variant={"outlined"}
      color={"secondary"}
      startIcon={<SendIcon />}
    >
      Odeslat servisní společnosti
    </Button>
  );
};
export default IssueDetailFieldServiceCompanySentToBtn;
