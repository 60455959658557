import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  anonymous: boolean;
  input: {
    qrCode: string;
    unitStoreNumber: string;
  };
  api: {
    fetching: boolean;
    errorMessage: string;
  };
  searchedAssetIds: string[];
}

const initialState: State = {
  anonymous: true,
  api: { errorMessage: "", fetching: false },
  input: {
    qrCode: "",
    unitStoreNumber: "",
  },
  searchedAssetIds: [],
};

const assetFinderSlice = createSlice({
  name: "assetFinder",
  initialState: initialState,
  reducers: {
    setAnonymous: (state: State, action: PayloadAction<boolean>) => {
      state.anonymous = action.payload;
    },
    setInputUnitStoreNumber: (state: State, action: PayloadAction<string>) => {
      state.input.unitStoreNumber = action.payload;
    },
    setInputQrCode: (state: State, action: PayloadAction<string>) => {
      state.input.qrCode = action.payload;
    },
    setApiFetching: (state: State, action: PayloadAction<boolean>) => {
      state.api.fetching = action.payload;
    },
    setApiErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.api.errorMessage = action.payload;
    },
    setSearchedAssetIds: (state: State, action: PayloadAction<string[]>) => {
      state.searchedAssetIds = action.payload;
    },
  },
});

export default assetFinderSlice;
