import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toaster = () => {
  return (
    <ToastContainer
      position="bottom-left"
      transition={Bounce}
      theme="colored"
    />
  );
};

export default Toaster;
