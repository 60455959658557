import { formatAddress } from "../../../../common/utils/address.utils";
import { BrandType } from "../../../../../types";
import React, { useEffect } from "react";
import { Autocomplete, Avatar, Box, TextField } from "@mui/material";
import { getBrandLogoUrlByBrandCode } from "../../../../../libraries/brands";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { selectFlattenUnits } from "../../../../enum/selectors/enumSelectors";
import {
  selectSelectedIssueMode,
  selectSelectedIssueProperty,
} from "../../../selectors/issueSelectors";
import issueSlice from "../../../slices/issueSlice";

const IssueDetailFieldUnitEditable = () => {
  const dispatch = useAppDispatch();

  const changeUnitId = (value: any) => {
    dispatch(
      issueSlice.actions.selectedIssuePropertySet({
        property: "unitId",
        value: value,
      })
    );
  };

  const unitId = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "unitId" })
  );
  const unitOptions = useAppSelector((state) => selectFlattenUnits(state));

  useEffect(() => {
    if (unitOptions.length === 1) {
      changeUnitId(unitOptions[0].id);
    }
  }, [unitOptions]);

  return (
    <Autocomplete
      onChange={(event, newValue) => {
        if (!!newValue) {
          changeUnitId(newValue.id);
        } else {
          changeUnitId(null);
        }
      }}
      value={unitOptions.find((u) => u.id === unitId) || null}
      options={unitOptions}
      renderOption={(props, option) => {
        return (
          <li key={option.id} {...props}>
            <strong>{option.title}</strong>&nbsp;&nbsp;
            {option.subtitle || ""}
          </li>
        );
      }}
      groupBy={(option) => {
        // @ts-ignore
        return option.brand.code;
      }}
      renderGroup={(item) => {
        const { group, children } = item;
        const code = group as unknown as Pick<BrandType, "code">;
        const codeString = code as unknown as string;
        return (
          <React.Fragment key={item.key}>
            <Box sx={{ m: 1, display: "flex", alignItems: "center" }}>
              <Avatar sx={{ mx: 1 }} src={getBrandLogoUrlByBrandCode(code)} />
              <Box sx={{ fontWeight: 600 }}>{codeString}</Box>
            </Box>
            <Box sx={{ mb: 2 }}>{children}</Box>
          </React.Fragment>
        );
      }}
      getOptionLabel={(option) =>
        option.title + " " + formatAddress(option.address)
      }
      renderInput={(params) => <TextField {...params} label={"Středisko"} />}
    />
  );
};

const IssueDetailFieldUnitEditableMultiple = () => {
  const dispatch = useAppDispatch();

  const changeValue = (value: any) => {
    dispatch(
      issueSlice.actions.selectedIssuePropertySet({
        property: "unitIds",
        value: value,
      })
    );
  };

  const value = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "unitIds" })
  );
  const unitOptions = useAppSelector((state) => selectFlattenUnits(state));

  const changeValueByBrandCode = (code: Pick<BrandType, "code">) => {
    const newValue = [...value];
    const unitIds = unitOptions
      .filter((u) => u.brand.code === code && !newValue.includes(u.id))
      .map((u) => u.id);
    changeValue(newValue.concat(unitIds));
  };

  useEffect(() => {
    if (unitOptions.length === 1) {
      changeValue([unitOptions[0].id]);
    }
  }, [unitOptions]);

  return (
    <Autocomplete
      onChange={(event, newValue) => {
        if (!!newValue) {
          changeValue(newValue.map((nv) => nv.id));
        } else {
          changeValue([]);
        }
      }}
      multiple={true}
      disableCloseOnSelect={true}
      value={unitOptions.filter((u) => value.includes(u.id))}
      options={unitOptions}
      renderOption={(props, option) => {
        return (
          <li key={option.id} {...props}>
            <strong>{option.title}</strong>&nbsp;&nbsp;
            {option.subtitle || ""}
          </li>
        );
      }}
      groupBy={(option) => {
        // @ts-ignore
        return option.brand.code;
      }}
      renderGroup={(item) => {
        const { group, children } = item;
        const code = group as unknown as Pick<BrandType, "code">;
        const codeString = code as unknown as string;
        return (
          <React.Fragment key={item.key}>
            <Box
              sx={{
                p: 1,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                "&:hover": { bgcolor: (theme) => theme.palette.action.hover },
              }}
              onClick={() => {
                changeValueByBrandCode(code);
              }}
            >
              <Avatar sx={{ mx: 1 }} src={getBrandLogoUrlByBrandCode(code)} />
              <Box sx={{ fontWeight: 600 }}>{codeString}</Box>
            </Box>
            <Box sx={{ mb: 2 }}>{children}</Box>
          </React.Fragment>
        );
      }}
      getOptionLabel={(option) =>
        option.title + " " + formatAddress(option.address)
      }
      renderInput={(params) => <TextField {...params} label={"Střediska"} />}
    />
  );
};

const IssueDetailFieldUnit = () => {
  const mode = useAppSelector(selectSelectedIssueMode);
  const unitIds = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "unitIds" })
  );

  switch (mode) {
    case "create": {
      if (!!unitIds) {
        return <IssueDetailFieldUnitEditableMultiple />;
      }
      return <IssueDetailFieldUnitEditable />;
    }
    case "update": {
      return <IssueDetailFieldUnitEditable />;
    }
    default: {
      return <></>;
    }
  }
};

export default IssueDetailFieldUnit;
