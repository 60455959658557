import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { selectSelectedIssue } from "../../../selectors/issueSelectors";
import { selectProfile } from "../../../../auth/selectors/authSelectors";
import React, { useState } from "react";
import issueSlice from "../../../slices/issueSlice";
import { Box, IconButton, TextField } from "@mui/material";
import { Send } from "@mui/icons-material";

const IssueDetailFieldCommentNew = () => {
  const dispatch = useAppDispatch();

  const issue = useAppSelector(selectSelectedIssue);
  const profile = useAppSelector(selectProfile);

  const [message, setMessage] = useState<string>("");

  const handleSubmit = () => {
    if (message !== "") {
      dispatch(issueSlice.actions.selectedIssueCommentSubmit({ message }));
      setMessage("");
    }
  };

  if (!profile || !issue) {
    return <></>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <TextField
          size={"small"}
          label={"Přidat komentář"}
          fullWidth={true}
          value={message}
          onChange={(event) => {
            setMessage(event.target.value);
          }}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              handleSubmit();
              ev.preventDefault();
            }
          }}
        />
      </Box>
      <Box sx={{ ml: 1 }}>
        <IconButton
          onClick={() => {
            handleSubmit();
          }}
          color={"secondary"}
        >
          <Send />
        </IconButton>
      </Box>
    </Box>
  );
};

export default IssueDetailFieldCommentNew;
