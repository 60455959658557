import React from "react";
import { Avatar, Box, Chip, IconButton, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import issueSlice from "../../slices/issueSlice";
import {
  selectBrandsWithStats,
  selectIssueFilterProperty,
} from "../../selectors/issueSelectors";
import {
  getBrandLogoUrlByBrandCode,
  getBrandPositionByBrandCode,
} from "../../../../libraries/brands";
import { BrandType } from "../../../../types";
import {
  getIssueStatusByCode,
  ISSUE_STATUS_CODE__inProgress,
  ISSUE_STATUS_CODE__new,
} from "../../../../libraries/enums/issueStatuses";
import {
  getIssueTypeByCode,
  ISSUE_TYPE_CODE__crash,
} from "../../../../libraries/enums/issueTypes";

const IssueFilterBrand = () => {
  const property = "brandIds";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectIssueFilterProperty(state, { property: property })
  );
  const options = useAppSelector(selectBrandsWithStats);

  const setValue = (value: any) => {
    dispatch(
      issueSlice.actions.issueFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {options
        .sort((a: any, b: any) => {
          const aX = getBrandPositionByBrandCode(a.code);
          const bX = getBrandPositionByBrandCode(b.code);
          return aX - bX;
        })
        .map((option) => {
          return (
            <Tooltip key={option.id} title={option.code}>
              <IconButton
                size="small"
                onClick={() => {
                  if (value.includes(option.id)) {
                    setValue(value.filter((v: any) => v !== option.id));
                  } else {
                    setValue(value.concat(option.id));
                  }
                }}
                sx={{
                  padding: 0,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    padding: 1,
                    borderRadius: "50%",
                    position: "relative",
                    background: value.includes(option.id)
                      ? "rgb(255, 224, 178)"
                      : "",
                  }}
                >
                  <Avatar
                    src={getBrandLogoUrlByBrandCode(
                      option.code as unknown as Pick<BrandType, "code">
                    )}
                  />
                </Box>
                <Chip
                  label={option.stats[ISSUE_TYPE_CODE__crash]}
                  size="small"
                  sx={{
                    background: getIssueTypeByCode(ISSUE_TYPE_CODE__crash)
                      .bgcolor,
                    border: "1px solid #fff",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    zIndex: 3,
                    pointerEvents: "none",
                    fontSize: 12,
                  }}
                />
                <Chip
                  label={option.stats[ISSUE_STATUS_CODE__new]}
                  size="small"
                  sx={{
                    background: getIssueStatusByCode(ISSUE_STATUS_CODE__new)
                      .bgcolor,
                    border: "1px solid #fff",
                    position: "absolute",
                    right: -5,
                    top: 15,
                    zIndex: 2,
                    pointerEvents: "none",
                    fontSize: 12,
                  }}
                />
                <Chip
                  label={option.stats[ISSUE_STATUS_CODE__inProgress]}
                  size="small"
                  sx={{
                    background: getIssueStatusByCode(
                      ISSUE_STATUS_CODE__inProgress
                    ).bgcolor,
                    border: "1px solid #fff",
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    pointerEvents: "none",
                    fontSize: 12,
                  }}
                />
              </IconButton>
            </Tooltip>
          );
        })}
    </Box>
  );
};

export default IssueFilterBrand;
