import React from "react";
import { Avatar, Box, Chip, IconButton, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  getBrandLogoUrlByBrandCode,
  getBrandPositionByBrandCode,
} from "../../../../libraries/brands";
import { BrandType } from "../../../../types";
import {
  selectBrandsWithStats,
  selectRevisionFilterProperty,
} from "../../selectors/revisionSelectors";
import revisionSlice from "../../slices/revisionSlice";
import {
  getRevisionStatusByCode,
  REVISION_STATUS_CODE__invalidDueToExpiration,
} from "../../../../libraries/enums/revisionStatuses";
import { orange } from "@mui/material/colors";

const RevisionFilterBrand = () => {
  const property = "brandIds";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectRevisionFilterProperty(state, { property: property })
  );
  const options = useAppSelector(selectBrandsWithStats);

  const setValue = (value: any) => {
    dispatch(
      revisionSlice.actions.revisionFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {options
        .sort((a: any, b: any) => {
          const aX = getBrandPositionByBrandCode(a.code);
          const bX = getBrandPositionByBrandCode(b.code);
          return aX - bX;
        })
        .map((option) => {
          return (
            <Tooltip key={option.id} title={option.code}>
              <IconButton
                size="small"
                onClick={() => {
                  if (value.includes(option.id)) {
                    setValue(value.filter((v: any) => v !== option.id));
                  } else {
                    setValue(value.concat(option.id));
                  }
                }}
                sx={{
                  padding: 0,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    padding: 1,
                    borderRadius: "50%",
                    position: "relative",
                    background: value.includes(option.id)
                      ? "rgb(255, 224, 178)"
                      : "",
                  }}
                >
                  <Avatar
                    src={getBrandLogoUrlByBrandCode(
                      option.code as unknown as Pick<BrandType, "code">
                    )}
                  />
                </Box>
                <Chip
                  label={
                    option.stats[REVISION_STATUS_CODE__invalidDueToExpiration]
                  }
                  size="small"
                  sx={{
                    background: getRevisionStatusByCode(
                      REVISION_STATUS_CODE__invalidDueToExpiration
                    ).bgcolor,
                    border: "1px solid #fff",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    zIndex: 3,
                    pointerEvents: "none",
                    fontSize: 12,
                  }}
                />
                <Chip
                  label={option.stats["EARLY_EXPIRATION"]}
                  size="small"
                  sx={{
                    background: orange[100],
                    border: "1px solid #fff",
                    position: "absolute",
                    right: -5,
                    top: 15,
                    zIndex: 2,
                    pointerEvents: "none",
                    fontSize: 12,
                  }}
                />
              </IconButton>
            </Tooltip>
          );
        })}
    </Box>
  );
};

export default RevisionFilterBrand;
