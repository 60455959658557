import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__createIssue } from "../../../../libraries/enums/permissionRights";
import { RevisionType } from "../../../../types";
import { AddCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { buildIssue } from "../../../issue/builders/issue.builder";
import { getFullTitleOfRevisionSubject } from "../../../../libraries/enums/revisionSubjects";
import issueSlice from "../../../issue/slices/issueSlice";
import { selectRevisionSubjectById } from "../../../enum/selectors/enumSelectors";
import { formatDate } from "../../../common/utils/dateTime.utils";

type ActionProps = {
  revision: RevisionType;
  children?: any;
};

const RevisionActionCreateIssue = (props: ActionProps) => {
  const { revision, children = null } = props;

  const dispatch = useAppDispatch();
  const hasPermissionRightCreateIssue = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createIssue,
    })
  );
  const revisionSubject = useAppSelector((state) =>
    selectRevisionSubjectById(state, revision.revisionSubjectId)
  );

  const handleCreate = () => {
    const issue = buildIssue();
    if (!!issue && !!revision && !!revisionSubject) {
      issue.unitId = revision.unitId as any;
      issue.description =
        revision.defects +
        " (zjištěno při revizi " +
        getFullTitleOfRevisionSubject(revisionSubject) +
        " dne " +
        formatDate(revision.revisionAt, "d. L. yyyy") +
        ")";
      issue.typeId = "service";
      issue.createdByRevisionId = revision.id;
    }
    dispatch(issueSlice.actions.selectedIssueSet(issue));
    dispatch(issueSlice.actions.selectedIssueModeSet("create"));
  };

  if (!revision || !hasPermissionRightCreateIssue || !revision.withDefects) {
    return <></>;
  }

  if (!!children) {
    return (
      <>
        {React.cloneElement(children, {
          onClick: () => {
            handleCreate();
          },
        })}
      </>
    );
  }

  return (
    <Button
      onClick={() => {
        handleCreate();
      }}
      color={"primary"}
      size={"small"}
    >
      <AddCircle fontSize={"small"} />
    </Button>
  );
};

export default RevisionActionCreateIssue;
