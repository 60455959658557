import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { FileDownload, FileUpload } from "@mui/icons-material";
import IssueDetailFieldAttachments from "./IssueDetailFieldAttachments";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  selectIssueById,
  selectSelectedIssueProperty,
} from "../../../selectors/issueSelectors";
import issueSlice from "../../../slices/issueSlice";
import CloseButton from "../../../../common/components/CloseButton/CloseButton";
import SaveIcon from "@mui/icons-material/Save";
import { FILE_TYPE } from "../../../../../libraries/enums/fileType";

const IssueDetailFieldServiceCompanyServiceListContent = (props: {
  issueId: string;
}) => {
  const issue = useAppSelector((state) =>
    selectIssueById(state, props.issueId)
  );

  const attachments = !!issue
    ? [...issue.files].filter((a) => a.type === FILE_TYPE.ISSUE_SERVICE_LIST)
    : //.sort((a, b) => a.position - b.position)
      [];

  if (attachments.length === 0) {
    return <></>;
  }

  return (
    <>
      <Divider />
      <Box sx={{ m: 2, mb: 1 }}>
        {attachments.map((attachment) => {
          return (
            <Button
              key={attachment.id}
              size={"small"}
              variant={"outlined"}
              startIcon={<FileDownload />}
              component={"a"}
              href={
                process.env.REACT_APP_ENDPOINT_FILES +
                "/" +
                attachment.path +
                "/originals/" +
                attachment.filename
              }
              target={"_blank"}
              sx={{
                mr: 1,
                mb: 1,
              }}
            >
              Servisní list
            </Button>
          );
        })}
      </Box>
    </>
  );
};

const IssueDetailFieldServiceCompanyServiceList = () => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const issueId = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "id" })
  );
  const serviceCompanyId = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "serviceCompanyId" })
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    dispatch(issueSlice.actions.selectedIssueSubmit());
    handleClose();
  };

  return (
    <>
      <IssueDetailFieldServiceCompanyServiceListContent issueId={issueId} />

      <Divider />

      <Box textAlign={"right"} mb={2}>
        <Button
          onClick={() => {
            handleOpen();
          }}
          sx={{ mt: 2, mr: 2 }}
          size={"small"}
          variant={"outlined"}
          color={"secondary"}
          startIcon={<FileUpload />}
        >
          Nahrát servisní list
        </Button>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Nahrát servisní list
          <CloseButton onClick={handleClose} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <IssueDetailFieldAttachments type={"issue_service_list"} />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            variant={"contained"}
            color={"primary"}
            size={"small"}
            startIcon={<SaveIcon />}
          >
            Uložit a zavřít
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default IssueDetailFieldServiceCompanyServiceList;
