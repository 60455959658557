import { RevisionSubjectType } from "../../types";

export const getActionOfRevisionSubject = (
  revisionSubject: RevisionSubjectType
) => {
  return revisionSubject.actionTitle;
};

export const getFullTitleOfRevisionSubject = (
  revisionSubject: RevisionSubjectType
) => {
  return revisionSubject.title;
};
