import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect } from "react";
import issueSlice from "../../slices/issueSlice";
import { useTheme } from "@mui/material/styles";

const IssueListRefetchButtonCounter = () => {
  const interval = useAppSelector(
    (state) => state.issue.listFetchingPolling.interval
  );
  const nextRefetchIn = useAppSelector(
    (state) => state.issue.listFetchingPolling.nextFetchIn
  );

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        value={(nextRefetchIn / interval) * 100}
        size={20}
      />
      <Box
        sx={{
          top: "1px",
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="primary"
          sx={{
            textTransform: "lowercase",
            fontSize: 10,
          }}
        >
          {/* {`${nextRefetchIn}s`} */}
          {`${nextRefetchIn}`}
        </Typography>
      </Box>
    </Box>
  );
};

const IssueRefetchButton = () => {
  const interval = 60;

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useAppDispatch();

  const handleRefetch = () => {
    dispatch(
      issueSlice.actions.listFetchingPolling({
        enabled: true,
        interval: interval,
        nextFetchIn: 0, //musí být 0
      })
    );
  };

  useEffect(() => {
    dispatch(
      issueSlice.actions.listFetchingPolling({
        enabled: process.env.REACT_APP_ENV !== "development",
        interval: interval,
        nextFetchIn: interval, //musí být stejné číslo jako interval
      })
    );
  }, []);

  return (
    <>
      <Button
        fullWidth={mdUp ? false : true}
        variant="outlined"
        sx={{
          mt: { xs: 1, md: 0 },
          mr: { md: 1 },
        }}
        onClick={() => {
          handleRefetch();
        }}
      >
        <IssueListRefetchButtonCounter />
        <Box sx={{ ml: 1 }}>Aktualizovat</Box>
      </Button>
    </>
  );
};

export default IssueRefetchButton;
