import React, { useEffect } from "react";
import { Grid, Paper } from "@mui/material";
import { Container } from "@mui/system";
import PageHeading from "../../../common/components/PageHeading/PageHeading";
import { useAppDispatch } from "../../../../app/store";
import RevisionList from "../RevisionList/RevisionList";
import enumSlice from "../../../enum/slices/enumSlice";
import revisionSlice from "../../slices/revisionSlice";
import RevisionCreateButton from "../RevisionCreateButton/RevisionCreateButton";

const Revision = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(enumSlice.actions.enumsFetch());
    dispatch(revisionSlice.actions.revisionListFetch());
  }, []);

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <PageHeading pageName="Revize a kontroly" />
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={6}
            sx={{
              justifyContent: {
                md: "flex-end",
              },
              alignItems: {
                md: "center",
              },
            }}
          >
            <RevisionCreateButton />
          </Grid>

          <Grid item xs={12}>
            <Paper
              sx={{ border: (theme) => `1px solid ${theme.palette.divider}` }}
              elevation={0}
            >
              <RevisionList />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Revision;
