import {
  AnyAction,
  createListenerMiddleware,
  ListenerMiddlewareInstance,
} from "@reduxjs/toolkit";
import { ListenerApiType } from "../../../types/api.types";
import documentSlice from "../slices/document.slice";
import {
  effectDocumentDelete,
  effectDocumentListFetch,
  effectSelectedDocumentSubmit,
} from "./document.effect";

const documentMiddleware: ListenerMiddlewareInstance =
  createListenerMiddleware();

//inicializační načtení dokumentů
documentMiddleware.startListening({
  type: documentSlice.actions.documentListFetch.type,
  effect: async (action: AnyAction, listenerApi: ListenerApiType) => {
    const queryParams = {};
    await effectDocumentListFetch(action, listenerApi, {
      queryParams,
      showFetchingIndicator: true,
      resetList: true,
    });
  },
});

//při odeslání dokumentu
documentMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    return [documentSlice.actions.selectedDocumentSubmit.type].includes(
      action.type
    );
  },
  effect: async (action, listenerApi) => {
    await effectSelectedDocumentSubmit(action, listenerApi);
  },
});

//při odstraňování dokumentu
documentMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    return [documentSlice.actions.documentDelete.type].includes(action.type);
  },
  effect: async (action, listenerApi) => {
    await effectDocumentDelete(action, listenerApi);
  },
});

export default documentMiddleware;
