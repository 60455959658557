import { Chip } from "@mui/material";
import React from "react";
import { ChipProps } from "./interfaces";

const ChipAssetMovementDirection = (props: ChipProps) => {
  const { label, background, size = "small" } = props;

  return (
    <Chip
      label={label}
      size={size}
      sx={{
        mr: 1,
        bgcolor: background,
      }}
    />
  );
};

export default ChipAssetMovementDirection;
