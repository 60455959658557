import { Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { PERMISSION_RIGHT_CODE__createDocument } from "../../../../libraries/enums/permissionRights";
import { buildDocument } from "../../builders/document.builder";
import documentSlice from "../../slices/document.slice";

const DocumentCreateButton = (props: any) => {
  const { btnProps = {} } = props;

  const dispatch = useAppDispatch();

  const rightCreateDocument = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createDocument,
    })
  );

  const handleCreateDocument = () => {
    const document = buildDocument();
    dispatch(documentSlice.actions.selectedDocumentSet(document));
    dispatch(documentSlice.actions.selectedDocumentModeSet("create"));
  };

  if (!rightCreateDocument) {
    return <></>;
  }

  return (
    <>
      <Button
        onClick={handleCreateDocument}
        color={"primary"}
        variant={"contained"}
        startIcon={<AddCircleIcon />}
        size="large"
        {...btnProps}
      >
        Přidat dokument
      </Button>
    </>
  );
};

export default DocumentCreateButton;
