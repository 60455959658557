import { green, red } from "@mui/material/colors";

export const ASSET_MOVEMENT_DIRECTION_CODE__in = "IN";
export const ASSET_MOVEMENT_DIRECTION_CODE__out = "OUT";

const ASSET_MOVEMENT_DIRECTIONS = {
  [ASSET_MOVEMENT_DIRECTION_CODE__in]: {
    code: ASSET_MOVEMENT_DIRECTION_CODE__in,
    label: "Příjem (dovoz, nákup, ...)",
    preposition: "na středisko",
    background: green[200],
  },
  [ASSET_MOVEMENT_DIRECTION_CODE__out]: {
    code: ASSET_MOVEMENT_DIRECTION_CODE__out,
    label: "Výdej (odvoz, likvidace, ...)",
    preposition: "ze střediska",
    background: red[200],
  },
};

export type assetMovementDirectionCodeType =
  keyof typeof ASSET_MOVEMENT_DIRECTIONS;

export const getAssetMovementDirectionByCode = (
  code: assetMovementDirectionCodeType | null
) => {
  if (!code) {
    return null;
  }
  return ASSET_MOVEMENT_DIRECTIONS[code];
};

export const getAssetMovementDirections = () => {
  return Object.values(ASSET_MOVEMENT_DIRECTIONS);
};
