import React from "react";
import {
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  TablePagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { fieldUnit } from "./fields/fieldUnit";
import { fieldDescription } from "./fields/fieldDescription";
import { fieldService } from "./fields/fieldService";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  selectIssueIdsFilteredAndSorted,
  selectIssuesFilteredAndSortedCount,
  selectListConfig,
  selectListFetching,
} from "../../selectors/issueSelectors";
import issueSlice from "../../slices/issueSlice";
import { fieldProgress } from "./fields/fieldProgress";
import { fieldComments } from "./fields/fieldComments";
import { Box } from "@mui/system";
import IssueFilter from "../IssueFilter/IssueFilter";
import IssueFilterBrand from "../IssueFilter/IssueFilterBrand";
import { useTheme } from "@mui/material/styles";
import IssueExportButton from "../IssueExportButton/IssueExportButton";
import IssueRefetchButton from "../IssueRefetchButton/IssueRefetchButton";

const IssueList = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const fetching = useAppSelector(selectListFetching);
  const rows = useAppSelector(selectIssueIdsFilteredAndSorted);
  const listConfig = useAppSelector(selectListConfig);
  const count = useAppSelector(selectIssuesFilteredAndSortedCount);

  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const columnsSizes = lgUp ? [3, 2, 2, 2, 3] : [4, 4, 4];

  const columns = lgUp
    ? [fieldDescription, fieldUnit, fieldService, fieldComments, fieldProgress]
    : [fieldDescription, fieldUnit, fieldService];

  const handlePageChange = (event: any, page: any) => {
    dispatch(issueSlice.actions.listConfigSet({ page: page }));
  };

  const handleRowsPerPageChange = (event: any) => {
    dispatch(
      issueSlice.actions.listConfigSet({ pageSize: event.target.value })
    );
  };

  return (
    <>
      <Box sx={{ p: 1 }}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <IssueFilter />
          </Grid>
          <Grid item xs={12} md={5} textAlign="right">
            <IssueRefetchButton />
            <Hidden mdDown>
              <IssueExportButton />
            </Hidden>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ p: 1, mb: 4 }}>
        <IssueFilterBrand />
      </Box>

      <Hidden mdDown>
        <Box
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Grid container>
            {columns.map((column, index) => {
              return (
                <Grid key={column.code} item xs={columnsSizes[index]} p={2}>
                  <Typography variant="subtitle2">{column.label}</Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <Divider />
      </Hidden>

      <Box hidden={!fetching} sx={{ py: 5, textAlign: "center" }}>
        <CircularProgress />
      </Box>
      <Box hidden={fetching} sx={{}}>
        {rows.map((id, index) => {
          return (
            <Grid
              container
              onClick={() => {
                dispatch(issueSlice.actions.selectedIssueSetById(id));
              }}
              sx={{
                outline: 0,
                cursor: "pointer",
                "&:hover": {
                  bgcolor: "rgb(225, 245, 254)",
                },
                background:
                  index % 2 === 1 ? "rgba(0, 0, 0, 0.02)" : "rgba(0, 0, 0, 0)",
                borderBottom: "1px solid #ececec",
                "&:last-of-type": {
                  borderBottom: 0,
                },
              }}
              tabIndex={-1}
              key={id}
            >
              {columns.map((column, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    md={columnsSizes[index]}
                    key={column.code}
                  >
                    {React.createElement(column.component, {
                      issueId: id,
                    })}
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Box>

      <Divider />

      <TablePagination
        rowsPerPageOptions={[5, 25, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={listConfig.pageSize}
        page={listConfig.page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage="Počet řádků"
        labelDisplayedRows={({ from, to, count }) =>
          `Zobrazeno ${from}–${to} z ${count} záznamů`
        }
        backIconButtonProps={{
          "aria-label": "předchozí",
        }}
        nextIconButtonProps={{
          "aria-label": "další",
        }}
      />
    </>
  );
};

export default IssueList;
