import Link from "@mui/material/Link";
import { Typography } from "@mui/material";

const Copyright = () => {
  return (
    <>
      <Link
        href="https://www.query.cz"
        sx={{ opacity: ".3", display: "block", textAlign: "center" }}
        target="_blank"
      >
        <img alt="Query" width="24" height="29" src="/images/query.svg" />
      </Link>

      <Typography
        sx={{
          color: (theme) => theme.palette.grey[600],
          textAlign: "center",
          marginTop: 1,
          opacity: ".3",
          fontSize: 12,
        }}
        variant="body1"
      >
        <code>{process.env.REACT_APP_VERSION}</code>
      </Typography>
    </>
  );
};

export default Copyright;
