import { Alert, Link } from "@mui/material";
import { Helmet } from "react-helmet";
import LogIn from "../features/auth/components/LogIn/LogIn";
import LayoutHome from "../features/common/components/Layout/LayoutHome";

const LogInView = () => {
  return (
    <>
      <Helmet>
        <title>Přihlášení — Fixnote</title>
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>

      <LayoutHome>
        <LogIn />
        <Alert severity="info" sx={{ mt: 3 }}>
          Nedaří se vám přihlášení do nové verze aplikace nebo jste zapomněl/a
          heslo?
          <p style={{ marginBottom: 0 }}>
            Napište nám na{" "}
            <Link href="mailto:hello@query.cz">hello@query.cz</Link> a pomůžeme
            vám.
          </p>
        </Alert>
      </LayoutHome>
    </>
  );
};

export default LogInView;
