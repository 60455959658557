import { IssueType } from "../../../types";
import { ISSUE_TYPE_CODE__service } from "../../../libraries/enums/issueTypes";
import { ISSUE_STATUS_CODE__new } from "../../../libraries/enums/issueStatuses";
import { v4 as uuidv4 } from "uuid";

export const buildIssue = () => {
  const now = new Date();
  const issue: IssueType = {
    id: uuidv4(),
    createdAt: now.toISOString(),
    updatedAt: null,
    number: 0,
    description: "",
    files: [],
    open: true,
    managedByFieldManager: false,
    typeId: ISSUE_TYPE_CODE__service,
    unitId: null,
    serviceCompanyId: null,
    progressItems: [],
    comments: [],
    statusId: ISSUE_STATUS_CODE__new,
    consideredAsDoneByHeadquartersAt: null,
    forwardedToHeadquartersAt: null,
    sentToServiceCompanyAt: null,
    serviceTechnicianId: null,
    markedAsDoneByHeadquartersAt: null,
    markedAsDoneByServiceTechnicianAt: null,
    forwardedToServiceCompanyAt: null,
    markedAsDoneByServiceCompanyAt: null,
    markedAsDoneByUnitAt: null,
    sentToServiceTechnicianAt: null,
    attachmentInputs: undefined,
    serviceTechnicianInput: null,
  };

  return issue;
};
