import React from "react";
import {
  ButtonGroup,
  CircularProgress,
  Divider,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useAppSelector } from "../../../../app/store";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import {
  selectDocumentsFilteredAndSorted,
  selectListFetching,
} from "../../selectors/document.selectors";
import { DocumentType } from "../../../../types";
import DocumentChip from "../Chip/DocumentChip";
import DocumentActionDelete from "../Action/DocumentActionDelete";

const FieldDocument = (props: { document: DocumentType }) => {
  const { document } = props;

  const status =
    document.type === "document" && !!document.files[0].id
      ? "documented"
      : "documentTemplate";

  const note = status === "documented" ? document.note : "";

  return (
    <Grid item md={10}>
      <DocumentChip document={document} size={"medium"} /> <small>{note}</small>
    </Grid>
  );
};

const FieldDocumentActions = (props: { document: DocumentType }) => {
  return (
    <Grid
      item
      container
      alignItems={"center"}
      justifyContent={"flex-end"}
      md={2}
    >
      <ButtonGroup variant={"outlined"}>
        <DocumentActionDelete document={props.document} />
      </ButtonGroup>
    </Grid>
  );
};

const DocumentList = () => {
  const theme = useTheme();
  // const dispatch = useAppDispatch();

  const fetching = useAppSelector(selectListFetching);
  const rows = useAppSelector(selectDocumentsFilteredAndSorted);
  // const listConfig = useAppSelector(selectListConfig);
  // const count = useAppSelector(selectDocumentsFilteredAndSortedCount);

  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const columnsSizes = lgUp ? [11, 1] : [11, 1];

  const columns = lgUp
    ? [FieldDocument, FieldDocumentActions]
    : [FieldDocument, FieldDocumentActions];

  return (
    <>
      <Box hidden={!fetching} sx={{ py: 5, textAlign: "center" }}>
        <CircularProgress />
      </Box>

      <Box
        hidden={fetching || rows.length !== 0}
        sx={{ color: "rgba(0, 0, 0, 0.24)", p: 2 }}
      >
        Není zde žádný dokument.
      </Box>

      <Box hidden={fetching}>
        <Divider />
        {rows.map((document: any, index) => {
          return (
            <>
              <Grid
                container
                alignItems="center"
                sx={{
                  transition: "background-color 200ms",
                  "&:hover": {
                    background: "rgb(225, 245, 254)",
                  },
                }}
                tabIndex={-1}
                key={document.id}
              >
                {columns.map((column, index) => {
                  return (
                    <>
                      {React.createElement(column, {
                        document: document,
                      })}
                    </>
                  );
                })}
              </Grid>
              <Divider />
            </>
          );
        })}
      </Box>
    </>
  );
};

export default DocumentList;
