import { BrandType } from "../types";

const BRANDS = {
  "1MINUTE": { position: 1100, label: "", logoPath: "1minute-logo.png" },
  AFS: { position: 1300, label: "", logoPath: "afs-logo.png" },
  "AFS HLN": { position: 700, label: "", logoPath: "afs-hln-logo.png" },
  "COSTA COFFEE": {
    position: 100,
    label: "",
    logoPath: "costa-coffee-logo.png",
  },
  "HELLO!": { position: 600, label: "", logoPath: "hello-logo.png" },
  HUBIZ: { position: 1000, label: "", logoPath: "hubiz-logo.png" },
  INMEDIO: { position: 900, label: "", logoPath: "inmedio-logo.png" },
  "LAGARDERE DUTY FREE": {
    position: 1400,
    label: "",
    logoPath: "lagardere-duty-free-logo.png",
  },
  "MR. BAKER": { position: 400, label: "", logoPath: "mr-baker-logo.png" },
  PAPERDOT: { position: 1200, label: "", logoPath: "paper-dot-logo.png" },
  PAUL: { position: 200, label: "", logoPath: "paul-logo.png" },
  "PIZZA LAMIA STAZIONE": {
    position: 500,
    label: "",
    logoPath: "pizza-logo.jpg",
  },
  RELAY: { position: 800, label: "", logoPath: "relay-logo.png" },
  UGO: { position: 300, label: "", logoPath: "ugo-logo.png" },
  "BANH-MI-BA": { position: 750, label: "", logoPath: "banh-mi-ba-logo.png" },
  NATOO: { position: 770, label: "", logoPath: "natoo-logo.png" },
  "BAGETERIE BOULEVARD": {
    position: 1500,
    label: "",
    logoPath: "bageterie-boulevard-logo.png",
  },
  LAVAZZA: { position: 1600, label: "", logoPath: "lavazza-logo.png" },
  "SO! COFFEE": { position: 1700, label: "", logoPath: "so-coffee-logo.png" },
  BOTTEGA: { position: 1800, label: "", logoPath: "bottega-logo.png" },
  "POP UP BAR": { position: 1900, label: "", logoPath: "pop-up-bar-logo.png" },
  "BEER&PRETZEL": {
    position: 2000,
    label: "",
    logoPath: "beer-pretzel-logo.png",
  },
  "LAGARDERE TRAVEL RETAIL": {
    position: 1,
    label: "",
    logoPath: "ltr-logo.png",
  },
  MARCHE: { position: 2100, label: "", logoPath: "marche-logo.png" },
};

export const getBrandLogoUrlByBrandCode = (
  brandCode: Pick<BrandType, "code"> | string
) => {
  let key = brandCode as unknown as string;
  if (BRANDS.hasOwnProperty(key)) {
    // @ts-ignore
    return "/images/" + BRANDS[key].logoPath;
  }

  return "BASIC_URL";
};

export const getBrandPositionByBrandCode = (
  brandCode: Pick<BrandType, "code">
) => {
  let key = brandCode as unknown as string;
  if (BRANDS.hasOwnProperty(key)) {
    // @ts-ignore
    return BRANDS[key].position;
  }

  return 9000;
};
