import { Grid } from "@mui/material";
import React, { createElement } from "react";

interface GridFieldProps {
  gridProps: any;
  component: any;
}

const GridField = (props: GridFieldProps) => {
  const { gridProps, component } = props;
  return (
    <Grid item {...gridProps}>
      {createElement(component)}
    </Grid>
  );
};

export default GridField;
