import LensIcon from "@mui/icons-material/Lens";
import LensBlurIcon from "@mui/icons-material/LensBlur";
import { orange, yellow } from "@mui/material/colors";

export const ASSET_STATUS_CODE__new = "new";
export const ASSET_STATUS_CODE__used = "used";

const ASSET_STATUSES = {
  [ASSET_STATUS_CODE__new]: {
    code: ASSET_STATUS_CODE__new,
    icon: LensIcon,
    color: "#000000",
    bgcolor: yellow[100],
    label: "Nové",
  },
  [ASSET_STATUS_CODE__used]: {
    code: ASSET_STATUS_CODE__used,
    icon: LensBlurIcon,
    color: "#000000",
    bgcolor: orange[100],
    label: "Použité",
  },
};

export type assetStatusCodeType = keyof typeof ASSET_STATUSES;

export const getAssetStatusByCode = (code: assetStatusCodeType | null) => {
  if (!code) {
    return null;
  }
  return ASSET_STATUSES[code];
};

export const getAssetStatuses = () => {
  return Object.values(ASSET_STATUSES);
};
