import { Chip } from "@mui/material";
import React from "react";
import { ChipProps } from "./interfaces";
import { Info } from "@mui/icons-material";

const ChipLastAssetMovement = (props: ChipProps) => {
  const { label, size = "small", background } = props;

  return (
    <Chip
      icon={<Info color={"inherit"} />}
      label={label}
      size={size}
      sx={{
        mr: 1,
        color: "#fff",
        bgcolor: background,
      }}
    />
  );
};

export default ChipLastAssetMovement;
