//@ts-nocheck

import { Box, Button } from "@mui/material";
import React from "react";
import "@fancyapps/ui/dist/fancybox.css";
import { useAppSelector } from "../../../../app/store";
import { selectRevisionById } from "../../selectors/revisionSelectors";
import { FileDownload } from "@mui/icons-material";

const FieldFile = (props: any) => {
  const { revisionId, isDetail = false } = props;

  const revision = useAppSelector((state) =>
    selectRevisionById(state, revisionId)
  );

  if (!revision || !revision.files[0].id) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ p: 2, width: "100%" }}>
        <Button
          size={"small"}
          variant={"contained"}
          color={"secondary"}
          startIcon={<FileDownload />}
          component={"a"}
          href={
            process.env.REACT_APP_ENDPOINT_FILES +
            "/" +
            revision.files[0].path +
            "/originals/" +
            revision.files[0].filename
          }
          target={"_blank"}
          sx={{
            mr: 1,
            mb: 1,
          }}
        >
          Revizní list
        </Button>
      </Box>
    </>
  );
};

export const fieldFile = {
  code: "fieldFile",
  label: "Revizní list",
  component: FieldFile,
};
