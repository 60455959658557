import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__createAssetMovement } from "../../../../libraries/enums/permissionRights";
import { buildAssetMovementDto } from "../../builders/assetMovement.builder";
import assetMovementSlice from "../../slices/assetMovement.slice";
import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React from "react";
import { selectSelectedAsset } from "../../selectors/asset.selector";
import { getLastAssetMovement } from "../../utils/assetMovement.utils";
import {
  ASSET_MOVEMENT_DIRECTION_CODE__in,
  ASSET_MOVEMENT_DIRECTION_CODE__out,
} from "../../../../libraries/enums/assetMovementDirections";

const AssetMovementCreateButton = (props: any) => {
  const { btnProps = {} } = props;

  const dispatch = useAppDispatch();

  const asset = useAppSelector(selectSelectedAsset);
  const rightCreateAssetMovement = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createAssetMovement,
    })
  );

  const handleCreateItem = () => {
    if (!!asset) {
      const lastMovement = getLastAssetMovement(asset);
      const assetMovementDto = buildAssetMovementDto();
      assetMovementDto.assetId = asset.id;
      assetMovementDto.directionId =
        (lastMovement?.direction?.id || "-1") ===
        ASSET_MOVEMENT_DIRECTION_CODE__in
          ? ASSET_MOVEMENT_DIRECTION_CODE__out
          : ASSET_MOVEMENT_DIRECTION_CODE__in;
      if (!!lastMovement) {
        assetMovementDto.unitStoreNumber = lastMovement.unit.storeNumber;
      }

      dispatch(
        assetMovementSlice.actions.selectedAssetMovementDtoSet(assetMovementDto)
      );
      dispatch(
        assetMovementSlice.actions.selectedAssetMovementDtoModeSet("create")
      );
    }
  };

  if (!rightCreateAssetMovement || !asset) {
    return <></>;
  }

  return (
    <>
      <Button
        onClick={handleCreateItem}
        startIcon={<AddCircleIcon />}
        {...btnProps}
      >
        Nový pohyb
      </Button>
    </>
  );
};

export default AssetMovementCreateButton;
