import React from "react";
import { fieldAsset } from "../../../asset/components/fields/fieldAsset";
import { Box, Paper } from "@mui/material";
import AssetFinderResultAssetActions from "./AssetFinderResultAssetActions";
import { useAppSelector } from "../../../../app/store";
import { selectAssetById } from "../../../asset/selectors/asset.selector";
import { fieldUnit } from "../../../asset/components/fields/fieldUnit";
import { selectAnonymous } from "../../selectors/assetFinder.selector";
import { selectProfile } from "../../../auth/selectors/authSelectors";
import { fieldIdentifiers } from "../../../asset/components/fields/fieldIdentifiers";
import { USER_TYPE_CODE__unit } from "../../../../libraries/enums/userTypes";

const AssetFinderResultAsset = (props: any) => {
  const { assetId } = props;
  const asset = useAppSelector((state) => selectAssetById(state, assetId));

  const anonymous = useAppSelector(selectAnonymous);
  const profile = useAppSelector(selectProfile);

  if (!asset || !profile) {
    return <></>;
  }

  const canShowFieldUnit = !anonymous && profile.type !== USER_TYPE_CODE__unit;

  return (
    <Paper sx={{ mt: 1 }} variant={"outlined"}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Box>
          {React.createElement(fieldAsset.component, {
            assetId: asset.id,
          })}
        </Box>
        {canShowFieldUnit && (
          <Box>
            {React.createElement(fieldUnit.component, {
              assetId: asset.id,
            })}
          </Box>
        )}
        <Box>
          {React.createElement(fieldIdentifiers.component, {
            assetId: asset.id,
          })}
        </Box>
      </Box>

      <Box sx={{ px: 2 }}>
        <AssetFinderResultAssetActions asset={asset} />
      </Box>
    </Paper>
  );
};

export default AssetFinderResultAsset;
