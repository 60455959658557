import shareEntitySlice from "../slices/shareEntity.slice";
import { AppDispatch } from "../../../app/store";

const parseQueryString = (queryString: string) => {
  const params = new URLSearchParams(queryString);
  const result = {};

  // @ts-ignore
  for (const [key, value] of params) {
    if (key !== "a") {
      // @ts-ignore
      result[key] = value;
    }
  }

  return result;
};

export const entryLocationSave =
  () => (dispatch: AppDispatch, getState: any) => {
    return dispatch(
      shareEntitySlice.actions.entryLocationSave({
        pathname: window.location.pathname,
        params: parseQueryString(window.location.search),
      })
    );
  };
