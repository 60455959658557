import { Alert } from "@mui/material";

const NotImplementedYet = () => {
  return (
    <Alert severity={"info"}>
      Bude implementováno v budoucí verzi aplikace Fixnote.
    </Alert>
  );
};

export default NotImplementedYet;
