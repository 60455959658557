import { AssetType } from "../../../../types/asset.types";
import React from "react";
import { useAppSelector } from "../../../../app/store";
import AssetFinderResultAsset from "./AssetFinderResultAsset";
import { selectAssetsSearchedAssetIds } from "../../selectors/assetFinder.selector";

const AssetFinderResult = () => {
  const assets = useAppSelector(selectAssetsSearchedAssetIds);

  return (
    <>
      {assets.map((asset: AssetType) => {
        return <AssetFinderResultAsset key={asset.id} assetId={asset.id} />;
      })}
    </>
  );
};

export default AssetFinderResult;
