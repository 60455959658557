import React from "react";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../app/store";
import { selectAssetMovements } from "../../selectors/asset.selector";
import { formatDate } from "../../../common/utils/dateTime.utils";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import ChipDate from "../../../common/components/Chip/ChipDate";
import UnitThumbnailContent from "../../../unit/components/UnitThumbnail/UnitThumbnailContent";
import {
  ASSET_MOVEMENT_DIRECTION_CODE__out,
  getAssetMovementDirectionByCode,
} from "../../../../libraries/enums/assetMovementDirections";
import ChipAssetMovementDirection from "../../../common/components/Chip/ChipAssetMovementDirection";
import { getAssetMovementReasonLabel } from "../../../../libraries/enums/assetMovementReasons";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__updateAssetMovement } from "../../../../libraries/enums/permissionRights";
import { Edit } from "@mui/icons-material";
import assetMovementSlice from "../../slices/assetMovement.slice";
import { AssetMovement, AssetMovementDto } from "../../../../types/asset.types";

const fDate = (dtString: string | null) => {
  if (!dtString) {
    return "neuvedeno";
  }
  return formatDate(dtString, "d. L. Y HH:mm");
};

const Field = ({
  assetId,
  isDetail = false,
  context = "DETAIL_UNIT_RELATIONS",
}: {
  assetId: string;
  isDetail?: boolean;
  context?: "LIST" | "DETAIL_UNIT" | "DETAIL_UNIT_RELATIONS";
}) => {
  const dispatch = useAppDispatch();
  const assetMovements = useAppSelector((state: RootState) =>
    selectAssetMovements(state, { assetId: assetId })
  );
  const rightUpdateAssetMovement = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__updateAssetMovement,
    })
  );

  const handleUpdate = (assetMovement: AssetMovement) => {
    const assetMovementDto: AssetMovementDto = {
      assetId: assetId,
      createdAt: assetMovement.createdAt,
      description: assetMovement.description,
      directionId: assetMovement.direction.id,
      enabled: assetMovement.enabled,
      id: assetMovement.id,
      reasonId: assetMovement.reason.id,
      statusId: assetMovement.status.id,
      targetEntityDescription: assetMovement.targetEntityDescription,
      targetEntityId: assetMovement.targetEntityId,
      targetEntityTypeId: assetMovement.targetEntityType?.id || null,
      unitStoreNumber: assetMovement.unit.storeNumber,
    };

    dispatch(
      assetMovementSlice.actions.selectedAssetMovementDtoSet(assetMovementDto)
    );
    dispatch(
      assetMovementSlice.actions.selectedAssetMovementDtoModeSet("update")
    );
  };

  return (
    <>
      <Table>
        <TableBody>
          {assetMovements
            .sort((a, b) => -1 * a.createdAt.localeCompare(b.createdAt)) //neodstraňovat tento sort
            .map((assetMovement) => {
              return (
                <TableRow key={assetMovement.id}>
                  <TableCell>
                    <ChipDate label={fDate(assetMovement.createdAt)} />
                  </TableCell>
                  <TableCell>
                    <ChipAssetMovementDirection
                      label={
                        getAssetMovementDirectionByCode(
                          assetMovement.direction.id
                        )?.label || ""
                      }
                      background={
                        getAssetMovementDirectionByCode(
                          assetMovement.direction.id
                        )?.background || ""
                      }
                    />{" "}
                    {getAssetMovementDirectionByCode(assetMovement.direction.id)
                      ?.preposition || ""}
                  </TableCell>
                  <TableCell>
                    <UnitThumbnailContent
                      unit={assetMovement.unit}
                      brand={assetMovement.unit.brand}
                      canShowDetail={false}
                      padding={0}
                    />
                  </TableCell>
                  <TableCell>
                    {assetMovement.direction.id ===
                      ASSET_MOVEMENT_DIRECTION_CODE__out && (
                      <>
                        {getAssetMovementReasonLabel(assetMovement.reason.id)}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    {rightUpdateAssetMovement &&
                      assetMovement.direction.id ===
                        ASSET_MOVEMENT_DIRECTION_CODE__out && (
                        <>
                          <IconButton
                            color={"secondary"}
                            onClick={() => {
                              handleUpdate(assetMovement);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </>
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </>
  );
};

export const fieldAssetMovements = {
  code: "fieldAssetMovements",
  label: "Pohyby",
  valueGetterFn: (obj: any) => {
    return "";
  },
  component: Field,
};
