import React from "react";
import {
  Divider,
  Grid,
  Hidden,
  IconButton,
  TablePagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import FieldUnitContent from "../../../enum/components/FieldUnitContent/FieldUnitContent";
import { selectDocumentsForUnitId } from "../../../document/selectors/document.selectors";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__createDocument } from "../../../../libraries/enums/permissionRights";
import { buildDocument } from "../../../document/builders/document.builder";
import documentSlice from "../../../document/slices/document.slice";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  selectFlattenUnitIdsFilteredAndSorted,
  selectFlattenUnitsFilteredAndSortedCount,
  selectListConfig,
} from "../../selectors/unit.selector";
import unitSlice from "../../slices/unit.slice";
import UnitFilter from "../UnitFilter/UnitFilter";
import UnitFilterBrand from "../UnitFilter/UnitFilterBrand";
import { grey } from "@mui/material/colors";
import DocumentChip from "../../../document/components/Chip/DocumentChip";
import issueSlice from "../../../issue/slices/issueSlice";

const FieldDocuments = (props: { unitId: string; showCount?: number }) => {
  const { unitId, showCount = 2 } = props;

  const dispatch = useAppDispatch();
  const documents = useAppSelector((state) =>
    selectDocumentsForUnitId(state, {
      unitId: unitId,
    })
  );
  const fDocuments = documents.filter(
    (d) => d.type === "document" && !!d.files[0].id
  );

  const handleClick = (e: any) => {
    e.stopPropagation();
    dispatch(issueSlice.actions.selectedUnitIdSet(unitId));
  };

  return (
    <Box sx={{ px: 2, py: 1 }}>
      {fDocuments.slice(0, showCount).map((d) => {
        return (
          <Box key={d.id}>
            <DocumentChip document={d} />
          </Box>
        );
      })}
      <Box>
        {fDocuments.length - showCount > 0 && (
          <Box
            onClick={handleClick}
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
              mr: 1,
              mb: 1,
              fontSize: 12,
              color: grey[500],
            }}
          >
            {fDocuments.length - showCount} další &hellip;
          </Box>
        )}
      </Box>
    </Box>
  );
};

const FieldDocumentsNotDocumented = (props: {
  unitId: string;
  showCount?: number;
}) => {
  const { unitId, showCount = 2 } = props;

  const documents = useAppSelector((state) =>
    selectDocumentsForUnitId(state, {
      unitId: unitId,
    })
  );
  const rightCreateDocument = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createDocument,
    })
  );

  const dispatch = useAppDispatch();
  const fDocuments = documents.filter((d) => d.type === "documentTemplate");

  const handleClick = (e: any) => {
    e.stopPropagation();
    dispatch(issueSlice.actions.selectedUnitIdSet(unitId));
  };

  if (!rightCreateDocument) {
    return <></>;
  }

  return (
    <Box sx={{ px: 2, py: 1 }}>
      {fDocuments.slice(0, showCount).map((d) => {
        return (
          <Box key={d.id}>
            <DocumentChip
              document={d}
              selectedDocumentDefault={{ unitId: unitId }}
            />
          </Box>
        );
      })}
      <Box>
        {fDocuments.length - showCount > 0 && (
          <Box
            onClick={handleClick}
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
              mr: 1,
              mb: 1,
              fontSize: 12,
              color: grey[500],
            }}
          >
            {fDocuments.length - showCount} další &hellip;
          </Box>
        )}
      </Box>
    </Box>
  );
};

const FieldDocumentCreate = (props: any) => {
  const dispatch = useAppDispatch();

  const rightCreateDocument = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createDocument,
    })
  );

  const handleCreateDocument = () => {
    const document = buildDocument();
    document.unitId = props.unitId;
    dispatch(documentSlice.actions.selectedDocumentSet(document));
    dispatch(documentSlice.actions.selectedDocumentModeSet("create"));
  };

  if (!rightCreateDocument) {
    return <></>;
  }

  return (
    <Box sx={{ textAlign: { md: "right" }, width: "100%", px: 2 }}>
      <IconButton onClick={handleCreateDocument} color={"primary"}>
        <AddCircleIcon />
      </IconButton>
    </Box>
  );
};

const fieldUnit = {
  code: "fieldUnit",
  label: "Středisko",
  component: FieldUnitContent,
};

const fieldDocuments = {
  code: "fieldDocumentDocumented",
  label: "Dokumenty",
  component: FieldDocuments,
};

const fieldDocumentsNotDocumented = {
  code: "fieldDocumentNotDocumented",
  label: "Nenahrané dokumenty",
  component: FieldDocumentsNotDocumented,
};

const fieldDocumentCreate = {
  code: "fieldDocumentCreate",
  label: "",
  component: FieldDocumentCreate,
};

const UnitList = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const rows = useAppSelector(selectFlattenUnitIdsFilteredAndSorted);
  const listConfig = useAppSelector(selectListConfig);
  const count = useAppSelector(selectFlattenUnitsFilteredAndSortedCount);

  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const columnsSizes = lgUp ? [3, 5, 3, 1] : [3, 5, 3, 1];

  const columns = lgUp
    ? [
      fieldUnit,
      fieldDocuments,
      fieldDocumentsNotDocumented,
      fieldDocumentCreate,
    ]
    : [
      fieldUnit,
      fieldDocuments,
      fieldDocumentsNotDocumented,
      fieldDocumentCreate,
    ];

  const handlePageChange = (event: any, page: any) => {
    dispatch(unitSlice.actions.listConfigSet({ page: page }));
  };

  const handleRowsPerPageChange = (event: any) => {
    dispatch(unitSlice.actions.listConfigSet({ pageSize: event.target.value }));
  };

  return (
    <>
      <Box sx={{ p: 1 }}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <UnitFilter />
          </Grid>
          <Grid item xs={12} md={5} textAlign="right" />
        </Grid>
      </Box>

      <Box sx={{ p: 1, mb: 4 }}>
        <UnitFilterBrand />
      </Box>

      <Hidden mdDown>
        <Box
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Grid container>
            {columns.map((column, index) => {
              return (
                <Grid key={column.code} item xs={columnsSizes[index]} p={2}>
                  <Typography variant="subtitle2">{column.label}</Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <Divider />
      </Hidden>

      <Box>
        {rows.map((id, index) => {
          return (
            <Grid
              container
              // onClick={() => {
              //   dispatch(revisionSlice.actions.selectedRevisionSetById(id));
              // }}
              sx={{
                outline: 0,
                // cursor: "pointer",
                "&:hover": {
                  bgcolor: "rgb(225, 245, 254)",
                },
                background:
                  index % 2 === 1 ? "rgba(0, 0, 0, 0.02)" : "rgba(0, 0, 0, 0)",
                borderBottom: "1px solid #ececec",
                "&:last-of-type": {
                  borderBottom: 0,
                },
              }}
              tabIndex={-1}
              key={id}
            >
              {columns.map((column, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    md={columnsSizes[index]}
                    key={column.code}
                  >
                    {React.createElement(column.component, {
                      unitId: id,
                    })}
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Box>

      <Divider />

      <TablePagination
        rowsPerPageOptions={[5, 25, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={listConfig.pageSize}
        page={listConfig.page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage="Počet řádků"
        labelDisplayedRows={({ from, to, count }) =>
          `Zobrazeno ${from}–${to} z ${count} záznamů`
        }
        backIconButtonProps={{
          "aria-label": "předchozí",
        }}
        nextIconButtonProps={{
          "aria-label": "další",
        }}
      />
    </>
  );
};

export default UnitList;
