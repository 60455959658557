import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { AlertTitle, Tooltip } from "@mui/material";

export default function NewsAlert(props) {
  const { severity, title, content, localStorageItem } = props;

  const [visible, setVisible] = React.useState(false);
  useEffect(() => {
    let pop_status = localStorage.getItem(localStorageItem);
    if (!pop_status) {
      setVisible(true);
      localStorage.setItem(localStorageItem, 1);
    }
  }, []);
  if (!visible) return null;

  return (
    <Alert
      sx={{ mb: 2 }}
      severity={severity}
      action={
        <Tooltip title="Zavřít a už nezobrazovat">
          <IconButton
            color="inherit"
            size="small"
            onClick={() => setVisible(false)}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      }
    >
      <AlertTitle>{title}</AlertTitle>
      {content}
    </Alert>
  );
}
