import { Box, Chip } from "@mui/material";
import React from "react";
import { Nullable } from "../../../../types";

export const typeId2Label = {
  qrCode: "QR kód",
  serial: "sériové číslo",
  producer: "výrobní číslo",
  inventory: "inventární číslo",
};

const Identifier = ({
  typeId,
  value,
}: {
  typeId: keyof typeof typeId2Label;
  value: Nullable<string>;
}) => {
  if (!value) {
    return <></>;
  }

  return (
    <Box>
      <Box
        sx={{
          fontSize: "60%",
          textTransform: "uppercase",
          letterSpacing: 1,
          pr: 1,
        }}
      >
        {typeId2Label[typeId]}
      </Box>
      <Chip size={"small"} label={value} />
    </Box>
  );
};

export default Identifier;
