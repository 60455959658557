//@ts-nocheck

import React from "react";
import { Chip } from "@mui/material";
import { getIssueTypeByCode } from "../../../../libraries/enums/issueTypes";

const ChipIssueType = (props) => {
  const {
    issueTypeCode,
    label,
    active = true,
    selected = true,
    iconProps = { fontSize: "small" },
  } = props;

  const issueType = getIssueTypeByCode(issueTypeCode);

  return (
    <Chip
      icon={React.createElement(issueType.icon, iconProps)}
      label={label}
      size="small"
      sx={{
        bgcolor: issueType.bgcolor,
        opacity: selected ? 1 : 0.4,
      }}
    />
  );
};

export default ChipIssueType;
