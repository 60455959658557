import React, { useEffect } from "react";
import { Box, Collapse, Typography } from "@mui/material";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../common/utils/localStorage.utils";
import { assetFinderSearchPost } from "../../../../api/api";
import assetSlice from "../../../asset/slices/asset.slice";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import AssetDetail from "../../../asset/components/AssetDetail/AssetDetail";
import { logOutOnly, saveAuthToken } from "../../../auth/utils/utils";
import { loadProfile } from "../../../../app/profile.utils";
import { useNavigate } from "react-router-dom";
import enumSlice from "../../../enum/slices/enumSlice";
import Toaster from "../../../toast/components/Toaster/Toaster";
import AssetFinderForm from "./AssetFinderForm";
import AssetFinderResult from "../AssetFinderResult/AssetFinderResult";
import assetFinderSlice from "../../slices/assetFinder.slice";
import { selectAssetsSearchedAssetIds } from "../../selectors/assetFinder.selector";

interface AssetFinderProps {
  anonymous: boolean;
}

const AssetFinder = (props: AssetFinderProps) => {
  const { anonymous = true } = props;

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const assets = useAppSelector(selectAssetsSearchedAssetIds);

  const initialize = () => {
    if (anonymous) {
      dispatch(assetSlice.actions.listSet([]));
    }
    dispatch(assetFinderSlice.actions.setSearchedAssetIds([]));
    dispatch(assetFinderSlice.actions.setApiErrorMessage(""));
    dispatch(assetFinderSlice.actions.setApiFetching(false));
  };

  const onError = (error: string) => {
    dispatch(assetFinderSlice.actions.setApiErrorMessage(error));
    dispatch(assetFinderSlice.actions.setApiFetching(false));
    if (anonymous) {
      logOutOnly(dispatch);
    }
  };

  //pokud bylo nalezeno zařízení
  const onSuccess = (inputData: any, data: any) => {
    if (anonymous) {
      //přihlásím se pod prodejnou, která byla zadána při vyhledávání
      const authToken = data.token;
      saveAuthToken(authToken);
      //po úspěšném získání profilu
      loadProfile(authToken, { dispatch, navigate }).then(() => {
        //načtu číselníky
        dispatch(enumSlice.actions.enumsFetch());

        //uložím si získaná zařízení
        dispatch(assetSlice.actions.listSet(data.assets));

        //uložím si ID vyhledaných zařízení
        dispatch(
          assetFinderSlice.actions.setSearchedAssetIds(
            data.assets.map((a: any) => a.id)
          )
        );

        //uložím informaci, že se jedná o oneTimeToken (pouze pro tuto komponentu)
        setLocalStorage("oneTimeAuthToken", true);

        //uložím si vyhledávací kritéria do localstorage
        setLocalStorage("assetFinder", {
          unitStoreNumber: inputData.unitStoreNumber,
        });

        dispatch(assetFinderSlice.actions.setApiFetching(false));
      });
    } else {
      //načtu číselníky
      dispatch(enumSlice.actions.enumsFetch());

      //uložím si získaná zařízení
      dispatch(assetSlice.actions.listUpsertMany(data.assets));

      //uložím si ID vyhledaných zařízení
      dispatch(
        assetFinderSlice.actions.setSearchedAssetIds(
          data.assets.map((a: any) => a.id)
        )
      );

      dispatch(assetFinderSlice.actions.setApiFetching(false));
    }
  };

  const handleSearch = (value: { unitStoreNumber: string; qrCode: string }) => {
    initialize();
    dispatch(assetFinderSlice.actions.setApiFetching(true));
    const data = {
      anonymous: anonymous, //anonymous === true -> způsobí, že je vrácen i authToken
      unitStoreNumber: value.unitStoreNumber,
      qrCode: value.qrCode,
    };
    assetFinderSearchPost({ data })
      .then((response) => {
        if (response.status === 200) {
          // @ts-ignore
          if (response.data.assets.length === 0) {
            onError(
              "Pro tento QR kód a středisko není evidováno žádné zařízení."
            );
          } else {
            // @ts-ignore
            onSuccess(data, response.data);
          }
        } else {
          onError(
            "Pro tento QR kód a středisko není evidováno žádné zařízení."
          );
        }
      })
      .catch(() => {
        onError("Pro tento QR kód a středisko není evidováno žádné zařízení.");
      });
  };

  useEffect(() => {
    initialize();
    dispatch(assetFinderSlice.actions.setAnonymous(anonymous));
    if (anonymous) {
      dispatch(
        assetFinderSlice.actions.setInputUnitStoreNumber(
          getLocalStorage("assetFinder")?.unitStoreNumber || ""
        )
      );
    }
  }, [anonymous]);

  return (
    <>
      <AssetFinderForm handleSearch={handleSearch} />
      <Collapse in={assets.length > 0}>
        <Box sx={{ mt: 2 }}>
          <Typography variant={"caption"}>
            Nalezeno {assets.length} zařízení
          </Typography>
          <AssetFinderResult />
        </Box>
      </Collapse>
      {anonymous && (
        <>
          <AssetDetail />
          <Toaster />
        </>
      )}
    </>
  );
};

export default AssetFinder;
