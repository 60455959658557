import { green, red } from "@mui/material/colors";
import { ThumbDown, ThumbsUpDown, ThumbUp } from "@mui/icons-material";

export const REVISION_STATUS_CODE__valid = "valid";
export const REVISION_STATUS_CODE__invalidDueToReplacement =
  "invalidDueToReplacement";
export const REVISION_STATUS_CODE__invalidDueToExpiration =
  "invalidDueToExpiration";

const REVISION_STATUSES = {
  [REVISION_STATUS_CODE__valid]: {
    code: REVISION_STATUS_CODE__valid,
    icon: ThumbUp,
    color: "#000000",
    bgcolor: green[100],
    label: "Platná",
  },
  [REVISION_STATUS_CODE__invalidDueToReplacement]: {
    code: REVISION_STATUS_CODE__invalidDueToReplacement,
    icon: ThumbsUpDown,
    color: "#000000",
    bgcolor: "rgba(0, 0, 0, 0.04)",
    label: "Neplatná (nahrazená novější)",
  },
  [REVISION_STATUS_CODE__invalidDueToExpiration]: {
    code: REVISION_STATUS_CODE__invalidDueToExpiration,
    icon: ThumbDown,
    color: "#000000",
    bgcolor: red[100],
    label: "Neplatná (po expiraci)",
  },
};

export type revisionStatusCodeType = keyof typeof REVISION_STATUSES;

export const getRevisionStatusByCode = (code: revisionStatusCodeType) => {
  return REVISION_STATUSES[code];
};

export const getRevisionStatuses = () => {
  return Object.values(REVISION_STATUSES);
};
