//@ts-nocheck

import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { grey } from "@mui/material/colors";
import { useSelector } from "react-redux";
import ChipIssueType from "../../Chip/ChipIssueType";
import { selectIssueById } from "../../../selectors/issueSelectors";
import {
  formatDate,
  formatDateDistanceToNow,
} from "../../../../common/utils/dateTime.utils";
import { getIssueTypeByCode } from "../../../../../libraries/enums/issueTypes";
import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";
import ChipIssueStatus from "../../Chip/ChipIssueStatus";
import { getIssueStatusByCode } from "../../../../../libraries/enums/issueStatuses";
import TodayIcon from "@mui/icons-material/Today";
import { FILE_TYPE } from "../../../../../libraries/enums/fileType";

const FieldDescription = (props: any) => {
  const { issueId, isDetail = false } = props;

  const issue = useSelector((state) => selectIssueById(state, issueId));

  const attachments = !!issue
    ? [...issue.files].filter((a) => a.type === FILE_TYPE.ISSUE_PHOTO)
    : //.sort((a, b) => a.position - b.position)
      [];

  const toggleFancybox = (e) => {
    e.stopPropagation();
    const fancybox = Fancybox.show(
      attachments.map((attachment) => {
        return {
          type: "image",
          src:
            process.env.REACT_APP_ENDPOINT_FILES +
            "/" +
            attachment.path +
            "/originals/" +
            attachment.filename,
        };
      }),
      {
        infinite: false,
      }
    );
  };

  return (
    <>
      <Box sx={{ p: 2, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Chip
            label={"#" + issue.number}
            size="small"
            sx={{
              mr: 1,
              color: grey[600],
              bgcolor: "rgba(0, 0, 0, 0.04)",
            }}
          />
          <Tooltip title={formatDateDistanceToNow(issue.createdAt)}>
            <Chip
              label={formatDate(issue.createdAt)}
              icon={<TodayIcon />}
              size="small"
              sx={{
                mr: 1,
                color: grey[600],
                bgcolor: "rgba(0, 0, 0, 0.04)",
              }}
            />
          </Tooltip>
          <Box sx={{ mr: 1, my: 0.5 }}>
            <ChipIssueType
              issueTypeCode={issue.typeId}
              label={getIssueTypeByCode(issue.typeId).label}
              active={true}
            />
          </Box>
          <Box sx={{ my: 0.5 }}>
            <ChipIssueStatus
              issueStatusCode={issue.statusId}
              label={getIssueStatusByCode(issue.statusId).label}
              active={true}
              size={"small"}
            />
          </Box>
        </Box>
        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <AvatarGroup
            spacing="small"
            max={2}
            variant="circular"
            total={attachments.length}
            sx={{ mr: attachments.length === 0 ? 0 : 1, cursor: "pointer" }}
            onClick={toggleFancybox}
          >
            {attachments.map((attachment) => {
              return (
                <Avatar
                  key={attachment.id}
                  src={
                    process.env.REACT_APP_ENDPOINT_FILES +
                    "/" +
                    attachment.path +
                    "/thumbnails/" +
                    attachment.filename
                  }
                />
              );
            })}
          </AvatarGroup>

          <Typography
            sx={{
              overflow: "hidden",
            }}
            component="div"
            variant="body2"
          >
            {isDetail ? (
              <>{issue.description}</>
            ) : (
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {issue.description}
              </Box>
            )}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export const fieldDescription = {
  code: "fieldDescription",
  label: "Požadavek",
  valueGetterFn: (obj) => {
    return obj.description;
  },
  component: FieldDescription,
};
