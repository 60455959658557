export const anonymizeEmail = (email: string): string => {
  if (email.includes("@lagardere-tr.cz")) {
    return email;
  }
  if (email.includes("@")) {
    const parts = email.split("@");
    return (
      parts[0].substring(0, 1) +
      "***" +
      parts[0].substring(parts[0].length - 1) +
      "@" +
      parts[1]
    );
  }
  return email;
};
