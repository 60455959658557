import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Container maxWidth={false} sx={{ mt: 3, pb: 2 }}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box mb={2}>
            <Typography
              sx={{
                color: (theme) => theme.palette.grey[600],
                fontSize: 14,
                "& code": {
                  fontSize: 12,
                },
              }}
              variant="body1"
            >
              Fixnote <code>{process.env.REACT_APP_VERSION}</code>
            </Typography>
            <Typography
              sx={{ color: (theme) => theme.palette.grey[600] }}
              variant="caption"
            >
              &copy; {new Date().getFullYear()} Query Digital, s.r.o., všechna
              práva vyhrazena
            </Typography>
          </Box>

          {process.env.REACT_APP_ENV === "development" && (
            <Box
              sx={{
                color: (theme) => theme.palette.warning.main,
                display: "block",
                wordWrap: "break-word",
                fontSize: 12,
                mb: 2,
              }}
            >
              <code>
                <span role="img" aria-label="environment">
                  ☁️
                </span>{" "}
                {process.env.REACT_APP_ENV}
              </code>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
