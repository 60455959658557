import { Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
import AssetFinder from "../AssetFinder/AssetFinder";

interface AssetFinderDialogProps {
  open: boolean;
  setOpen: any;
}

const AssetFinderDialog = (props: AssetFinderDialogProps) => {
  const { open, setOpen } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Vyhledat zařízení podle QR kódu</DialogTitle>
        <Divider />
        <DialogContent>
          <AssetFinder anonymous={false} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssetFinderDialog;
