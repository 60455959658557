import { fetchProfile, logOut } from "../features/auth/utils/utils";
import authSlice from "../features/auth/slices/authSlice";
import { getEntryUrlForProfile } from "./navigation.utils";
import { AppDispatch } from "./store";
import { NavigateFunction } from "react-router-dom";

export const loadProfile = async (
  authToken: string | null,
  api: {
    dispatch: AppDispatch;
    navigate: NavigateFunction;
  }
) => {
  const { dispatch, navigate } = api;
  dispatch(authSlice.actions.statusSet("loading"));
  if (!!authToken) {
    fetchProfile(authToken)
      .then(async (response) => {
        if (response.status === 200) {
          // @ts-ignore
          const profile = await response.data.user;
          dispatch(authSlice.actions.statusSet("success"));
          dispatch(authSlice.actions.profileSet(profile));
          navigate(getEntryUrlForProfile(profile), { replace: true });
        } else {
          dispatch(authSlice.actions.statusSet("failure"));
          logOut(dispatch, navigate);
        }
      })
      .catch((e: any) => {
        dispatch(authSlice.actions.statusSet("failure"));
        logOut(dispatch, navigate);
      });
  } else {
    dispatch(authSlice.actions.statusSet("failure"));
    logOut(dispatch, navigate);
  }
};
