import React, { useEffect } from "react";
import {
  getAuthTokenViaURLAccessToken,
  getURLAccessToken,
  loadAuthToken,
  logOut,
  saveAuthToken,
} from "../../utils/utils";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../app/store";
import { useNavigate } from "react-router-dom";
import Loader from "../../../common/components/Loader/Loader";
import { loadProfile } from "../../../../app/profile.utils";
import authSlice from "../../slices/authSlice";
import HomeCrossroads from "../../../common/components/HomeCrossroads/HomeCrossroads";
import { getLocalStorage } from "../../../common/utils/localStorage.utils";

type AuthGuardProps = {
  children: React.ReactNode;
};

const AuthGuard = (props: AuthGuardProps) => {
  const dispatch = useAppDispatch();
  const status = useAppSelector((state: RootState) => state.auth.authStatus);
  const profile = useAppSelector((state: RootState) => state.auth.profile);

  const navigate = useNavigate();

  const init = async () => {
    if (getLocalStorage("oneTimeAuthToken")) {
      logOut(dispatch, navigate);
    } else {
      dispatch(authSlice.actions.statusSet("loading"));
      getURLAccessToken().then((urlAccessToken) => {
        if (!!urlAccessToken) {
          getAuthTokenViaURLAccessToken(urlAccessToken)
            .then((response) => {
              if (response.status === 200) {
                // @ts-ignore
                const authToken = response.data.token;
                saveAuthToken(authToken);
                loadProfile(authToken, { dispatch, navigate });
              } else {
                dispatch(authSlice.actions.statusSet("failure"));
                logOut(dispatch, navigate);
              }
            })
            .catch((e) => {
              dispatch(authSlice.actions.statusSet("failure"));
              logOut(dispatch, navigate);
            });
        } else {
          const authToken = loadAuthToken();
          loadProfile(authToken, { dispatch, navigate });
        }
      });
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (status === "loading") {
    return <Loader />;
  }

  if (status === "success" && !!profile) {
    return <>{props.children}</>;
  }

  return (
    <>
      <HomeCrossroads />
    </>
  );
};

export default AuthGuard;
