import { RevisionType } from "../../../types";
import { v4 as uuidv4 } from "uuid";
import { REVISION_TYPE_CODE__regular } from "../../../libraries/enums/revisionTypes";
import { formatDateTimeToISO } from "../../common/utils/dateTime.utils";

export const buildRevision = () => {
  const revision: RevisionType = {
    createdAt: "",
    defects: "",
    durationInDaysUntilNextRevision: 0,
    externalId: "",
    fileContent: "",
    id: uuidv4(),
    nextRevisionAt: null,
    note: "",
    replacedBy: null,
    replaces: null,
    revisionAt: formatDateTimeToISO(new Date()),
    revisionCompanyId: "",
    revisionSubjectId: "",
    statusId: "",
    summary: "",
    typeId: REVISION_TYPE_CODE__regular,
    unitId: "",
    unitStoreNumber: "",
    withDefects: false,
  };

  return revision;
};
