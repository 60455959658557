import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import {
  ROUTER_PATH_ASSET_FINDER,
  ROUTER_PATH_LOGIN,
} from "../../../../app/router";
import LayoutHome from "../Layout/LayoutHome";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import KeyIcon from "@mui/icons-material/Key";

const HomeCrossroads = () => {
  return (
    <>
      <LayoutHome>
        <Button
          sx={{ mt: 1, mb: 3 }}
          fullWidth={true}
          size={"large"}
          variant={"contained"}
          color={"primary"}
          startIcon={<KeyIcon />}
          component={Link}
          to={ROUTER_PATH_LOGIN}
        >
          Přihlásit se do aplikace
        </Button>
        <Button
          fullWidth={true}
          size={"large"}
          variant={"contained"}
          color={"primary"}
          startIcon={<QrCodeScannerIcon />}
          component={Link}
          to={ROUTER_PATH_ASSET_FINDER}
        >
          Skenovat QR kód
        </Button>
      </LayoutHome>
    </>
  );
};

export default HomeCrossroads;
