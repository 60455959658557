import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  DocumentFilterType,
  DocumentType,
  ListConfigType,
  Nullable,
} from "../../../types";
import { buildInitialFilter } from "../builders/filter.builder";

type StateType = {
  filter: DocumentFilterType;
  list: EntityState<DocumentType>;
  listFetching: boolean;
  listFetchingPolling: {
    enabled: boolean;
    interval: number;
    nextFetchIn: number;
    lastChangeAt: string;
  };
  listConfig: ListConfigType;
  selectedDocument: Nullable<DocumentType>;
  selectedDocumentMode: Nullable<"create" | "update" | "read">;
};

export const documentEntityAdapter = createEntityAdapter<DocumentType>();

const initialState: StateType = {
  filter: buildInitialFilter(),
  list: documentEntityAdapter.getInitialState(),
  listFetching: false,
  listFetchingPolling: {
    enabled: true,
    interval: 5 * 60,
    nextFetchIn: 5 * 60,
    lastChangeAt: "1000-01-01",
  },
  listConfig: {
    page: 0,
    pageSize: 25,
    sortBy: null,
    sortByDirection: "ASC",
  },
  selectedDocument: null,
  selectedDocumentMode: null,
};

const documentSlice = createSlice({
  name: "document",
  initialState: initialState,
  reducers: {
    documentListFetch: () => {},
    documentListFetching: (
      state: StateType,
      action: PayloadAction<boolean>
    ) => {
      state.listFetching = action.payload;
    },
    documentListRemoveAll: (state: StateType) => {
      documentEntityAdapter.removeAll(state.list);
    },
    documentListSet: (
      state: StateType,
      action: PayloadAction<DocumentType[]>
    ) => {
      documentEntityAdapter.setAll(state.list, action.payload);
    },
    documentListUpsertMany: (
      state: StateType,
      action: PayloadAction<DocumentType[]>
    ) => {
      documentEntityAdapter.upsertMany(state.list, action.payload);
    },
    documentFilterSet: (
      state: StateType,
      action: PayloadAction<DocumentFilterType>
    ) => {
      state.filter = action.payload;
      state.listConfig = { ...state.listConfig, page: 0 };
    },
    documentFilterPropertySet: (
      state: StateType,
      action: PayloadAction<{ property: string; value: any }>
    ) => {
      if (!state.filter) {
        state.filter = buildInitialFilter();
      }
      // @ts-ignore
      state.filter[action.payload.property] = action.payload.value;
      state.listConfig = { ...state.listConfig, page: 0 };
    },
    documentListUpsertOne: (
      state: StateType,
      action: PayloadAction<DocumentType>
    ) => {
      documentEntityAdapter.upsertOne(state.list, action.payload);
    },
    documentDelete: (
      state: StateType,
      action: PayloadAction<DocumentType>
    ) => {},
    documentListRemoveOne: (
      state: StateType,
      action: PayloadAction<EntityId>
    ) => {
      documentEntityAdapter.removeOne(state.list, action.payload);
    },
    selectedDocumentSet: (
      state: StateType,
      action: PayloadAction<Nullable<DocumentType>>
    ) => {
      state.selectedDocument = action.payload;
    },
    selectedDocumentModeSet: (
      state: StateType,
      action: PayloadAction<Nullable<"create" | "update" | "read">>
    ) => {
      state.selectedDocumentMode = action.payload;
    },
    selectedDocumentPropertySet: (
      state: StateType,
      action: PayloadAction<any>
    ) => {
      if (!!state.selectedDocument) {
        // @ts-ignore
        state.selectedDocument[action.payload.property] = action.payload.value;
      }
    },
    selectedDocumentPropertiesSet: (
      state: StateType,
      action: PayloadAction<any>
    ) => {
      if (!!state.selectedDocument) {
        for (let i = 0; i < action.payload.length; i++) {
          // @ts-ignore
          state.selectedDocument[action.payload[i].property] =
            action.payload[i].value;
        }
      }
    },
    selectedDocumentSetById: (state: StateType, action) => {
      const document = state.list.entities[action.payload];
      if (!!document) {
        state.selectedDocument = document;
        state.selectedDocumentMode = "read";
      }
    },
    selectedDocumentSubmit: (state: StateType) => {},
    listConfigSet: (state: StateType, action: PayloadAction<any>) => {
      state.listConfig = { ...state.listConfig, ...action.payload };
    },
  },
});

export default documentSlice;
