//@ts-nocheck

import { Box, Chip, Tooltip } from "@mui/material";
import React from "react";
import { grey } from "@mui/material/colors";
import "@fancyapps/ui/dist/fancybox.css";
import TodayIcon from "@mui/icons-material/Today";
import { useAppSelector } from "../../../../app/store";
import { selectRevisionById } from "../../selectors/revisionSelectors";
import {
  formatDate,
  formatDateDistanceToNow,
} from "../../../common/utils/dateTime.utils";
import ChipRevisionType from "../Chip/ChipRevisionType";
import {
  getRevisionTypeByCode,
  REVISION_TYPE_CODE__initial,
  REVISION_TYPE_CODE__regular,
} from "../../../../libraries/enums/revisionTypes";
import {
  getRevisionExpirationStatusByRevision,
  REVISION_EXPIRATION_STATUS_before,
} from "../../../../libraries/enums/revisionExpirationStatuses";
import ChipRevisionExpirationStatus from "../Chip/ChipRevisionExpirationStatus";
import { getFullTitleOfRevisionSubject } from "../../../../libraries/enums/revisionSubjects";
import { selectRevisionSubjectById } from "../../../enum/selectors/enumSelectors";
import { ReportProblem } from "@mui/icons-material";
import { getIssueStatusByCode } from "../../../../libraries/enums/issueStatuses";

const FieldDescriptionIssuesContent = (props: any) => {
  const { issuesContent } = props;
  const issues = issuesContent.split(",");

  return (
    <>
      {issues.map((issue) => {
        const parts = issue.split("|");
        const issueStatus = getIssueStatusByCode(parts[2]);
        return (
          <Chip
            key={parts[0]}
            label={"#" + parts[1]}
            size="small"
            sx={{
              ml: 1,
              color: issueStatus.color,
              bgcolor: issueStatus.bgcolor,
            }}
          />
        );
      })}
    </>
  );
};

const FieldDescription = (props: any) => {
  const { revisionId, isDetail = false } = props;

  const revision = useAppSelector((state) =>
    selectRevisionById(state, revisionId)
  );

  const revisionSubject = useAppSelector((state) =>
    selectRevisionSubjectById(state, revision.revisionSubjectId)
  );

  const revisionTypeId = revision.typeId;

  if (!revision) {
    return <></>;
  }

  const expirationStatus = getRevisionExpirationStatusByRevision(revision);

  return (
    <>
      <Box sx={{ p: 2, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Tooltip title={formatDateDistanceToNow(revision.revisionAt)}>
            <Chip
              label={formatDate(revision.revisionAt, "d. L. yyyy")}
              icon={<TodayIcon />}
              size="small"
              sx={{
                mr: 1,
                color: grey[600],
                bgcolor: "rgba(0, 0, 0, 0.04)",
              }}
            />
          </Tooltip>
          <Box sx={{ mr: 1, my: 0.5 }}>
            <ChipRevisionType
              revisionTypeCode={revisionTypeId}
              label={getRevisionTypeByCode(revisionTypeId).label}
              active={true}
            />
          </Box>
          {[REVISION_TYPE_CODE__regular, REVISION_TYPE_CODE__initial].includes(
            revisionTypeId
          ) &&
            expirationStatus.code !== REVISION_EXPIRATION_STATUS_before && (
              <Box sx={{ mr: 1, my: 0.5 }}>
                <ChipRevisionExpirationStatus
                  revisionExpirationStatusCode={expirationStatus.code}
                  label={expirationStatus.label}
                  size="small"
                  active={true}
                />
              </Box>
            )}
        </Box>
        <Box>
          <Box>
            <strong>{getFullTitleOfRevisionSubject(revisionSubject)}</strong>
          </Box>
          {(revision.withDefects || revision.issuesContent) && (
            <Box sx={{ display: "flex" }}>
              {revision.withDefects && (
                <>
                  <ReportProblem
                    color={"error"}
                    sx={{ mr: 1 }}
                    fontSize={"small"}
                  />
                  <Box>{revision.defects}</Box>
                </>
              )}
              {revision.issuesContent && (
                <FieldDescriptionIssuesContent
                  issuesContent={revision.issuesContent}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export const fieldDescription = {
  code: "fieldDescription",
  label: "Revize",
  component: FieldDescription,
};
