import React from "react";
import { Avatar, Box, IconButton, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  getBrandLogoUrlByBrandCode,
  getBrandPositionByBrandCode,
} from "../../../../libraries/brands";
import { BrandType } from "../../../../types";
import { selectUnitFilterProperty } from "../../selectors/unit.selector";
import unitSlice from "../../slices/unit.slice";
import { selectBrands } from "../../../enum/selectors/enumSelectors";

const UnitFilterBrand = () => {
  const property = "brandIds";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectUnitFilterProperty(state, { property: property })
  );
  const options = useAppSelector(selectBrands);

  const setValue = (value: any) => {
    dispatch(
      unitSlice.actions.unitFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {options
        .sort((a: any, b: any) => {
          const aX = getBrandPositionByBrandCode(a.code);
          const bX = getBrandPositionByBrandCode(b.code);
          return aX - bX;
        })
        .map((option) => {
          return (
            <Tooltip key={option.id} title={option.code}>
              <IconButton
                size="small"
                onClick={() => {
                  if (value.includes(option.id)) {
                    setValue(value.filter((v: any) => v !== option.id));
                  } else {
                    setValue(value.concat(option.id));
                  }
                }}
                sx={{
                  padding: 0,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    padding: 1,
                    borderRadius: "50%",
                    position: "relative",
                    background: value.includes(option.id)
                      ? "rgb(255, 224, 178)"
                      : "",
                  }}
                >
                  <Avatar
                    src={getBrandLogoUrlByBrandCode(
                      option.code as unknown as Pick<BrandType, "code">
                    )}
                  />
                </Box>
              </IconButton>
            </Tooltip>
          );
        })}
    </Box>
  );
};

export default UnitFilterBrand;
