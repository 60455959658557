export const PERMISSION_RIGHT_CODE__createIssue = "createIssue";
export const PERMISSION_RIGHT_CODE__createIssueMultiple = "createIssueMultiple";
export const PERMISSION_RIGHT_CODE__updateIssue = "updateIssue";
export const PERMISSION_RIGHT_CODE__readIssue = "readIssue";
export const PERMISSION_RIGHT_CODE__commentIssue = "commentIssue";
export const PERMISSION_RIGHT_CODE__forwardIssueToHeadquarters =
  "forwardToHeadquarters";
export const PERMISSION_RIGHT_CODE__assignServiceCompanyToIssue =
  "assignServiceCompanyToIssue";
export const PERMISSION_RIGHT_CODE__assignServiceTechnicianToIssue =
  "assignServiceTechnicianToIssue";
export const PERMISSION_RIGHT_CODE__markIssueAsDoneByServiceTechnician =
  "markIssueAsDoneByServiceTechnician";
export const PERMISSION_RIGHT_CODE__forwardToServiceCompany =
  "forwardToServiceCompany";
export const PERMISSION_RIGHT_CODE__markIssueAsDoneByServiceCompany =
  "markIssueAsDoneByServiceCompany";
export const PERMISSION_RIGHT_CODE__markIssueAsDoneByUnit =
  "markIssueAsDoneByUnit";
export const PERMISSION_RIGHT_CODE__markIssueAsDoneByHeadquarters =
  "markIssueAsDoneByHeadquarters";
export const PERMISSION_RIGHT_CODE__considerIssueAsDoneByHeadquarters =
  "considerIssueAsDoneByHeadquarters";

export const PERMISSION_RIGHT_CODE__createRevision = "createRevision";
export const PERMISSION_RIGHT_CODE__readRevision = "readRevision";
export const PERMISSION_RIGHT_CODE__deleteRevision = "deleteRevision";

export const PERMISSION_RIGHT_CODE__createDocument = "createDocument";
export const PERMISSION_RIGHT_CODE__readDocument = "readDocument";
export const PERMISSION_RIGHT_CODE__deleteDocument = "deleteDocument";

export const PERMISSION_RIGHT_CODE__createAsset = "createAsset";
export const PERMISSION_RIGHT_CODE__readAsset = "readAsset";
export const PERMISSION_RIGHT_CODE__updateAsset = "updateAsset";
export const PERMISSION_RIGHT_CODE__createAssetMovement = "createAssetMovement";
export const PERMISSION_RIGHT_CODE__createAssetMovementOfDirectionIn =
  "createAssetMovementOfDirectionIn";
export const PERMISSION_RIGHT_CODE__createAssetMovementOfDirectionOut =
  "createAssetMovementOfDirectionOut";
export const PERMISSION_RIGHT_CODE__updateAssetMovement = "updateAssetMovement";

const PERMISSION_RIGHTS = {
  [PERMISSION_RIGHT_CODE__createIssue]: {
    code: PERMISSION_RIGHT_CODE__createIssue,
  },
  [PERMISSION_RIGHT_CODE__createIssueMultiple]: {
    code: PERMISSION_RIGHT_CODE__createIssueMultiple,
  },
  [PERMISSION_RIGHT_CODE__updateIssue]: {
    code: PERMISSION_RIGHT_CODE__updateIssue,
  },
  [PERMISSION_RIGHT_CODE__readIssue]: {
    code: PERMISSION_RIGHT_CODE__readIssue,
  },
  [PERMISSION_RIGHT_CODE__commentIssue]: {
    code: PERMISSION_RIGHT_CODE__commentIssue,
  },
  [PERMISSION_RIGHT_CODE__forwardIssueToHeadquarters]: {
    code: PERMISSION_RIGHT_CODE__forwardIssueToHeadquarters,
  },
  [PERMISSION_RIGHT_CODE__assignServiceCompanyToIssue]: {
    code: PERMISSION_RIGHT_CODE__assignServiceCompanyToIssue,
  },
  [PERMISSION_RIGHT_CODE__assignServiceTechnicianToIssue]: {
    code: PERMISSION_RIGHT_CODE__assignServiceTechnicianToIssue,
  },
  [PERMISSION_RIGHT_CODE__markIssueAsDoneByServiceTechnician]: {
    code: PERMISSION_RIGHT_CODE__markIssueAsDoneByServiceTechnician,
  },
  [PERMISSION_RIGHT_CODE__forwardToServiceCompany]: {
    code: PERMISSION_RIGHT_CODE__forwardToServiceCompany,
  },
  [PERMISSION_RIGHT_CODE__markIssueAsDoneByServiceCompany]: {
    code: PERMISSION_RIGHT_CODE__markIssueAsDoneByServiceCompany,
  },
  [PERMISSION_RIGHT_CODE__markIssueAsDoneByUnit]: {
    code: PERMISSION_RIGHT_CODE__markIssueAsDoneByUnit,
  },
  [PERMISSION_RIGHT_CODE__markIssueAsDoneByHeadquarters]: {
    code: PERMISSION_RIGHT_CODE__markIssueAsDoneByHeadquarters,
  },
  [PERMISSION_RIGHT_CODE__considerIssueAsDoneByHeadquarters]: {
    code: PERMISSION_RIGHT_CODE__considerIssueAsDoneByHeadquarters,
  },
  [PERMISSION_RIGHT_CODE__createRevision]: {
    code: PERMISSION_RIGHT_CODE__createRevision,
  },
  [PERMISSION_RIGHT_CODE__readRevision]: {
    code: PERMISSION_RIGHT_CODE__readRevision,
  },
  [PERMISSION_RIGHT_CODE__deleteRevision]: {
    code: PERMISSION_RIGHT_CODE__deleteRevision,
  },
  [PERMISSION_RIGHT_CODE__createDocument]: {
    code: PERMISSION_RIGHT_CODE__createDocument,
  },
  [PERMISSION_RIGHT_CODE__readDocument]: {
    code: PERMISSION_RIGHT_CODE__readDocument,
  },
  [PERMISSION_RIGHT_CODE__deleteDocument]: {
    code: PERMISSION_RIGHT_CODE__deleteDocument,
  },
  [PERMISSION_RIGHT_CODE__createAsset]: {
    code: PERMISSION_RIGHT_CODE__createAsset,
  },
  [PERMISSION_RIGHT_CODE__readAsset]: {
    code: PERMISSION_RIGHT_CODE__readAsset,
  },
  [PERMISSION_RIGHT_CODE__updateAsset]: {
    code: PERMISSION_RIGHT_CODE__updateAsset,
  },
  [PERMISSION_RIGHT_CODE__createAssetMovement]: {
    code: PERMISSION_RIGHT_CODE__createAssetMovement,
  },
  [PERMISSION_RIGHT_CODE__createAssetMovementOfDirectionIn]: {
    code: PERMISSION_RIGHT_CODE__createAssetMovementOfDirectionIn,
  },
  [PERMISSION_RIGHT_CODE__createAssetMovementOfDirectionOut]: {
    code: PERMISSION_RIGHT_CODE__createAssetMovementOfDirectionOut,
  },
  [PERMISSION_RIGHT_CODE__updateAssetMovement]: {
    code: PERMISSION_RIGHT_CODE__updateAssetMovement,
  },
};

export type permissionRightCodeType = keyof typeof PERMISSION_RIGHTS;

export const getPermissionRightByCode = (code: permissionRightCodeType) => {
  return PERMISSION_RIGHTS[code];
};
