import { v4 as uuidv4 } from "uuid";
import { AssetMovementDto } from "../../../types/asset.types";
import { ASSET_MOVEMENT_DIRECTION_CODE__in } from "../../../libraries/enums/assetMovementDirections";

export const buildAssetMovementDto = () => {
  const entity: AssetMovementDto = {
    assetId: "",
    description: "",
    directionId: ASSET_MOVEMENT_DIRECTION_CODE__in,
    enabled: true,
    id: uuidv4(),
    reasonId: "UNSPECIFIED",
    statusId: "IN_PROGRESS",
    targetEntityDescription: null,
    targetEntityId: null,
    targetEntityTypeId: null,
    unitStoreNumber: "",
    createdAt: new Date().toISOString(),
  };

  return entity;
};
