export const ISSUE_PROGRESS_ITEM_TYPE_CODE__created = "created";
export const ISSUE_PROGRESS_ITEM_TYPE_CODE__forwardedToHeadquarters =
  "forwardedToHeadquarters";
export const ISSUE_PROGRESS_ITEM_TYPE_CODE__sentToServiceCompany =
  "sentToServiceCompany";
export const ISSUE_PROGRESS_ITEM_TYPE_CODE__sentToServiceTechnician =
  "sentToServiceTechnician";
export const ISSUE_PROGRESS_ITEM_TYPE_CODE__serviceListAttached =
  "serviceListAttached";
export const ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByUnit =
  "markedAsDoneByUnit";
export const ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByServiceTechnician =
  "markedAsDoneByServiceTechnician";
export const ISSUE_PROGRESS_ITEM_TYPE_CODE__forwardedToServiceCompany =
  "forwardedToServiceCompany";
export const ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByServiceCompany =
  "markedAsDoneByServiceCompany";
export const ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByHeadquarters =
  "markedAsDoneByHeadquarters";
export const ISSUE_PROGRESS_ITEM_TYPE_CODE__consideredAsDoneByHeadquarters =
  "consideredAsDoneByHeadquarters";

export const ISSUE_PROGRESS_ITEM_TYPE_CODE__statusNew = "statusNew";
export const ISSUE_PROGRESS_ITEM_TYPE_CODE__statusInProgress =
  "statusInProgress";
export const ISSUE_PROGRESS_ITEM_TYPE_CODE__statusDone = "statusDone";

const ISSUE_PROGRESS_ITEM_TYPES = {
  [ISSUE_PROGRESS_ITEM_TYPE_CODE__created]: {
    code: ISSUE_PROGRESS_ITEM_TYPE_CODE__created,
  },
  [ISSUE_PROGRESS_ITEM_TYPE_CODE__forwardedToHeadquarters]: {
    code: ISSUE_PROGRESS_ITEM_TYPE_CODE__forwardedToHeadquarters,
  },
  [ISSUE_PROGRESS_ITEM_TYPE_CODE__sentToServiceCompany]: {
    code: ISSUE_PROGRESS_ITEM_TYPE_CODE__sentToServiceCompany,
  },
  [ISSUE_PROGRESS_ITEM_TYPE_CODE__sentToServiceTechnician]: {
    code: ISSUE_PROGRESS_ITEM_TYPE_CODE__sentToServiceTechnician,
  },
  [ISSUE_PROGRESS_ITEM_TYPE_CODE__serviceListAttached]: {
    code: ISSUE_PROGRESS_ITEM_TYPE_CODE__serviceListAttached,
  },
  [ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByUnit]: {
    code: ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByUnit,
  },
  [ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByServiceTechnician]: {
    code: ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByServiceTechnician,
  },
  [ISSUE_PROGRESS_ITEM_TYPE_CODE__forwardedToServiceCompany]: {
    code: ISSUE_PROGRESS_ITEM_TYPE_CODE__forwardedToServiceCompany,
  },
  [ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByServiceCompany]: {
    code: ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByServiceCompany,
  },
  [ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByHeadquarters]: {
    code: ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByHeadquarters,
  },
  [ISSUE_PROGRESS_ITEM_TYPE_CODE__consideredAsDoneByHeadquarters]: {
    code: ISSUE_PROGRESS_ITEM_TYPE_CODE__consideredAsDoneByHeadquarters,
  },
  [ISSUE_PROGRESS_ITEM_TYPE_CODE__statusNew]: {
    code: ISSUE_PROGRESS_ITEM_TYPE_CODE__statusNew,
  },
  [ISSUE_PROGRESS_ITEM_TYPE_CODE__statusInProgress]: {
    code: ISSUE_PROGRESS_ITEM_TYPE_CODE__statusInProgress,
  },
  [ISSUE_PROGRESS_ITEM_TYPE_CODE__statusDone]: {
    code: ISSUE_PROGRESS_ITEM_TYPE_CODE__statusDone,
  },
};

export type issueProgressItemTypeCodeType =
  keyof typeof ISSUE_PROGRESS_ITEM_TYPES;

export const getIssueProgressItemTypeByCode = (
  code: issueProgressItemTypeCodeType
) => {
  return ISSUE_PROGRESS_ITEM_TYPES[code];
};
