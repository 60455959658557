import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  selectSelectedAssetMode,
  selectSelectedAssetProperty,
} from "../../../selectors/asset.selector";
import assetSlice from "../../../slices/asset.slice";
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  InputAdornment,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { QrCodeScanner as QrCodeScannerIcon } from "@mui/icons-material";
import QrCodeScanner from "../../../../common/components/QrCodeScanner/QrCodeScanner";
import { qrCodeCheckPost } from "../../../../../api/api";

const AssetDetailFieldQrCodeInput = (props: any) => {
  const { label, initialValue = "", onChange, onSubmit, error } = props;

  const [value, setValue] = useState(initialValue);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleScan = (text: string) => {
    setValue(text);
    handleClose();
  };

  useEffect(() => {
    onChange(value);
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      if (value !== initialValue) {
        onSubmit(value);
      }
    }, 3000);
    setTimer(newTimer);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [value]);

  return (
    <>
      <TextField
        label={label}
        fullWidth={true}
        value={!!value ? value : ""}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        error={error !== ""}
        helperText={error}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ mr: -1 }}>
              <IconButton
                onClick={() => {
                  handleOpen();
                }}
                color={"secondary"}
              >
                <QrCodeScannerIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ p: 0 }}>
          <QrCodeScanner onScan={handleScan} />
        </DialogContent>
      </Dialog>
    </>
  );
};

const AssetDetailFieldQrCode = () => {
  const label = "QR kód";
  const property = "input.qrCode";

  const dispatch = useAppDispatch();
  const mode = useAppSelector(selectSelectedAssetMode);
  const value = useAppSelector((state) =>
    selectSelectedAssetProperty(state, { property })
  );
  const changeValue = (value: any) => {
    const payload = [];
    payload.push({
      property,
      value,
    });
    dispatch(assetSlice.actions.selectedAssetPropertiesSet(payload));
  };

  const [status, setStatus] = useState("");

  const handleSubmit = (value: any) => {
    setStatus("");
    if (value === "") {
      changeValue("");
    }
    if (!!value) {
      qrCodeCheckPost({
        data: {
          input: value,
        },
      }).then((response) => {
        let error = false;
        // @ts-ignore
        if (response.data.resultCode === "cannot_be_used") {
          error = true;
          setStatus("Tento QR kód nelze použít.");
        }
        // @ts-ignore
        if (response.data.resultCode === "already_used") {
          error = true;
          setStatus("Tento QR kód již byl použit.");
        }
        if (!error) {
          changeValue(value);
        }
      });
    }
  };

  return (
    <>
      <AssetDetailFieldQrCodeInput
        label={label}
        initialValue={value}
        onSubmit={handleSubmit}
        onChange={() => {
          setStatus("");
        }}
        error={status}
      />
    </>
  );
};

export default AssetDetailFieldQrCode;
