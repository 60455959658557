export const ASSET_MOVEMENT_REASON_CODE = {
  UNSPECIFIED: "UNSPECIFIED",
  ELIMINATION: "ELIMINATION",
  MOVE_TO_ANOTHER_UNIT: "MOVE_TO_ANOTHER_UNIT",
  SERVICE: "SERVICE",
};

export type assetMovementReasonCodeType =
  keyof typeof ASSET_MOVEMENT_REASON_CODE;

export const getAssetMovementReasonLabel = (
  code: assetMovementReasonCodeType
): string | undefined => {
  switch (code) {
    case ASSET_MOVEMENT_REASON_CODE.UNSPECIFIED:
      return "Neuvedeno";
    case ASSET_MOVEMENT_REASON_CODE.SERVICE:
      return "Servis";
    case ASSET_MOVEMENT_REASON_CODE.MOVE_TO_ANOTHER_UNIT:
      return "Stěhování na jiné středisko";
    case ASSET_MOVEMENT_REASON_CODE.ELIMINATION:
      return "Vyřazení";
  }
};

export const getAssetMovementReasons = () => {
  return Object.values(ASSET_MOVEMENT_REASON_CODE);
};
