import { AssetFilterType } from "../../../types/asset.types";

export const buildInitialFilter = () => {
  const filter: AssetFilterType = {
    unitIds: [],
    qrCode: "",
    fulltext: "",
    assetMovementReasonIds: [],
    extraWithoutUnit: false,
    extraWithoutQrCode: false,
  };

  return filter;
};
