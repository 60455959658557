//@ts-nocheck

import React from "react";
import { Chip } from "@mui/material";
import { getUserTypeByCode } from "../../../../libraries/enums/userTypes";
import { anonymizeEmail } from "../../../common/utils/anonymizer.utils";

const ChipUserType = (props) => {
  const { userTypeCode, label, iconProps = { fontSize: "small" } } = props;

  return (
    <Chip
      icon={React.createElement(
        getUserTypeByCode(userTypeCode).icon,
        iconProps
      )}
      label={anonymizeEmail(label)}
      size="small"
    />
  );
};

export default ChipUserType;
