import { Box, Grid, Paper } from "@mui/material";
import PageHeading from "../../../common/components/PageHeading/PageHeading";
import { Container } from "@mui/system";
import AssetList from "../AssetList/AssetList";
import { useAppDispatch } from "../../../../app/store";
import { useEffect } from "react";
import enumSlice from "../../../enum/slices/enumSlice";
import AssetCreateButton from "../AssetCreateButton/AssetCreateButton";
import assetSlice from "../../slices/asset.slice";
import AssetFilter from "../AssetFilter/AssetFilter";

const Asset = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(enumSlice.actions.enumsFetch());
    dispatch(assetSlice.actions.listFetch());
  }, []);

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ mr: 2 }}>
              <PageHeading pageName="Zařízení" />
            </Box>
            {/*<Box>*/}
            {/*  <AssetFinderDialog />*/}
            {/*</Box>*/}
          </Box>
        </Grid>

        <Grid
          container
          item
          xs={12}
          md={6}
          sx={{
            justifyContent: {
              md: "flex-end",
            },
            alignItems: {
              md: "center",
            },
          }}
        >
          <AssetCreateButton />
        </Grid>

        {/*<MergingDialog />*/}

        <Grid item xs={12}>
          <Paper
            sx={{ border: (theme) => `1px solid ${theme.palette.divider}` }}
            elevation={0}
          >
            <Box sx={{ p: 1 }}>

              <AssetFilter />

              {/*<Box>*/}
              {/*  <K2Indicator />*/}
              {/*</Box>*/}
            </Box>
            <AssetList />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Asset;
