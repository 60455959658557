import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  selectBrandByUnitId,
  selectUnitAddressByUnitId,
  selectUnitById,
} from "../../selectors/enumSelectors";
import issueSlice from "../../../issue/slices/issueSlice";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { getBrandLogoUrlByBrandCode } from "../../../../libraries/brands";
import { grey } from "@mui/material/colors";
import React from "react";

const FieldUnitContent = (props: any) => {
  const { unitId } = props;

  const dispatch = useAppDispatch();
  const unit = useAppSelector((state) => selectUnitById(state, unitId));
  const brand = useAppSelector((state) => selectBrandByUnitId(state, unitId));
  const unitAddress = useAppSelector((state) =>
    selectUnitAddressByUnitId(state, unitId)
  );

  const handleClick = (e: any) => {
    e.stopPropagation();
    dispatch(issueSlice.actions.selectedUnitIdSet(unitId));
  };

  if (!unit || !brand) {
    return <></>;
  }

  return (
    <>
      <Box
        p={2}
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Tooltip title="Zobrazit více informací o pobočce">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              "&:hover": {
                cursor: "pointer",
                ".MuiAvatar-root": {
                  borderColor: "rgb(255, 224, 178)",
                },
              },
            }}
            onClick={handleClick}
          >
            <Avatar
              src={getBrandLogoUrlByBrandCode(brand.code as unknown as any)}
              sx={{
                width: 48,
                height: 48,
                border: "6px solid transparent",
                mr: 1,
                transition: "all 300ms",
              }}
            />
            <Box>
              <Typography
                variant="body2"
                sx={{ color: grey[600], fontWeight: 600 }}
              >
                {unit.title}
              </Typography>
              <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                {unit.subtitle || ""}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      </Box>
    </>
  );
};

export default FieldUnitContent;
