import { UnitFilterType } from "../../../types";

export const buildInitialFilter = () => {
  const filter: UnitFilterType = {
    brandIds: [],
    brandGroupIds: [],
    unitIds: [],
  };

  return filter;
};
