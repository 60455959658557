import { useAppSelector } from "../../../../app/store";
import {
  selectAssetById,
  selectAssetRelations,
} from "../../selectors/asset.selector";
import { Box } from "@mui/system";
import React from "react";
import Identifier from "../Identifier/Identifier";
// @ts-ignore
import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm";
import { Avatar, AvatarGroup, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { AssetQrCodeRelation } from "../../../../types/asset.types";
import { File } from "../../../../types/file.types";
import { QrCodeType } from "../../../../types/qrCode.types";
import { shallowEqual } from "react-redux";

const FieldIdentifiers = ({
  assetId,
  isDetail = false,
}: {
  assetId: string;
  isDetail?: boolean;
}) => {
  const asset = useAppSelector((state) => selectAssetById(state, assetId));

  const qrCodeRelations = useAppSelector(
    (state) =>
      selectAssetRelations(state, {
        assetId: assetId,
        relationName: "qrCodeRelations",
      }),
    {
      equalityFn: shallowEqual,
    }
  );
  const fileRelations = useAppSelector(
    (state) =>
      selectAssetRelations(state, {
        assetId: assetId,
        relationName: "fileRelations",
      }),
    {
      equalityFn: shallowEqual,
    }
  );

  const qrCodes = qrCodeRelations.map((relation: AssetQrCodeRelation) => {
    return relation.qrCode;
  });

  const files = (asset?.files || []).filter(
    (file: File) => file.type === "asset_label"
  );

  const toggleFancybox = (e: any) => {
    e.stopPropagation();
    const fancybox = Fancybox.show(
      files.map((file: File) => {
        return {
          type: "image",
          src:
            process.env.REACT_APP_ENDPOINT_FILES +
            "/" +
            file.path +
            "/originals/" +
            file.filename,
        };
      }),
      {
        infinite: false,
      }
    );
  };

  if (!asset) {
    return <></>;
  }

  if (
    isDetail &&
    files.length === 0 &&
    !asset.inventoryNumber &&
    !asset.serialNumber &&
    !asset.producerNumber &&
    qrCodes.length === 0
  ) {
    return (
      <Box sx={{ p: 2, width: "100%", display: "flex" }}>
        <Typography
          variant={"body2"}
          sx={{
            fontStyle: "italic",
            color: grey[500],
            fontWeight: 100,
            fontSize: "90%",
          }}
        >
          není uvedeno
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, width: "100%", display: "flex" }}>
      <AvatarGroup
        spacing="small"
        max={2}
        variant="circular"
        total={files.length}
        sx={{ mr: files.length === 0 ? 0 : 1, cursor: "pointer" }}
        onClick={toggleFancybox}
      >
        {files.map((file: File) => {
          return (
            <Avatar
              key={file.id}
              src={
                process.env.REACT_APP_ENDPOINT_FILES +
                "/" +
                file.path +
                "/thumbnails/" +
                file.filename
              }
            />
          );
        })}
      </AvatarGroup>
      <Box sx={{ display: "flex", "& > div": { mr: 1 } }}>
        <Identifier typeId={"inventory"} value={asset.inventoryNumber} />
        <Identifier typeId={"serial"} value={asset.serialNumber} />
        <Identifier typeId={"producer"} value={asset.producerNumber} />
        {qrCodes.map((qrCode: QrCodeType) => {
          return (
            <Identifier
              key={qrCode.id}
              typeId={"qrCode"}
              value={qrCode.label}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export const fieldIdentifiers = {
  code: "identifiers",
  label: "Identifikace",
  component: FieldIdentifiers,
};
