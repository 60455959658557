import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__createRevision } from "../../../../libraries/enums/permissionRights";
import { buildRevision } from "../../builders/revision.builder";
import revisionSlice from "../../../revision/slices/revisionSlice";
import { RevisionType } from "../../../../types";
import { Upgrade } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  REVISION_TYPE_CODE__initial,
  REVISION_TYPE_CODE__regular,
} from "../../../../libraries/enums/revisionTypes";
import {
  REVISION_STATUS_CODE__invalidDueToExpiration,
  REVISION_STATUS_CODE__valid,
} from "../../../../libraries/enums/revisionStatuses";

type ActionProps = {
  revision: RevisionType;
  children?: any;
};

const RevisionActionUpgrade = (props: ActionProps) => {
  const { revision, children = null } = props;

  const dispatch = useAppDispatch();
  const hasPermissionRightCreateRevision = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createRevision,
    })
  );

  const handleUpgrade = () => {
    const rev = buildRevision();
    if (!!revision) {
      rev.revisionSubjectId = revision.revisionSubjectId;
      rev.unitId = revision.unitId;
      rev.unitStoreNumber = revision.unitStoreNumber;
      rev.revisionCompanyId = revision.revisionCompanyId;
    }
    dispatch(revisionSlice.actions.selectedRevisionSet(rev));
    dispatch(revisionSlice.actions.selectedRevisionModeSet("create"));
  };

  if (
    !revision ||
    !hasPermissionRightCreateRevision ||
    ![REVISION_TYPE_CODE__initial, REVISION_TYPE_CODE__regular].includes(
      revision.typeId
    ) ||
    ![
      REVISION_STATUS_CODE__valid,
      REVISION_STATUS_CODE__invalidDueToExpiration,
    ].includes(revision.statusId)
  ) {
    return <></>;
  }

  if (!!children) {
    return (
      <>
        {React.cloneElement(children, {
          onClick: () => {
            handleUpgrade();
          },
        })}
      </>
    );
  }

  return (
    <Button
      onClick={() => {
        handleUpgrade();
      }}
      color={"primary"}
      size={"small"}
    >
      <Upgrade fontSize={"small"} />
    </Button>
  );
};

export default RevisionActionUpgrade;
