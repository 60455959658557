import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  selectFlattenUnits,
  selectFlattenUnitsLength,
} from "../../../enum/selectors/enumSelectors";
import { BrandType } from "../../../../types";
import { getBrandLogoUrlByBrandCode } from "../../../../libraries/brands";
import { formatAddress } from "../../../common/utils/address.utils";
import { selectAssetFilterProperty } from "../../selectors/asset.selector";
import assetSlice from "../../slices/asset.slice";
import IconButton from "@mui/material/IconButton";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import QrCodeScanner from "../../../common/components/QrCodeScanner/QrCodeScanner";
import {
  getAssetMovementReasonLabel,
  getAssetMovementReasons,
} from "../../../../libraries/enums/assetMovementReasons";

const AssetFilterUnitIds = () => {
  const property = "unitIds";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectAssetFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      assetSlice.actions.filterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  const flattenUnits = useAppSelector((state) => selectFlattenUnits(state));

  const unitOptions = flattenUnits;

  return (
    <Autocomplete
      multiple={true}
      onChange={(event, newValue) => {
        if (!!newValue) {
          setValue(newValue.map((v) => v.id));
        } else {
          setValue(null);
        }
      }}
      value={unitOptions.filter((u) => value.includes(u.id))}
      options={unitOptions}
      renderOption={(props, option) => {
        return (
          <li key={option.id} {...props}>
            <strong>{option.title}</strong>&nbsp;&nbsp;
            {/* {formatAddress(option.address)} */}
            {option.subtitle || ""}
          </li>
        );
      }}
      groupBy={(option) => {
        // @ts-ignore
        return option.brand.code;
      }}
      renderGroup={(asset) => {
        const { group, children } = asset;
        const code = group as unknown as Pick<BrandType, "code">;
        const codeString = code as unknown as string;
        return (
          <React.Fragment key={asset.key}>
            <Box sx={{ m: 1, display: "flex", alignAssets: "center" }}>
              <Avatar sx={{ mx: 1 }} src={getBrandLogoUrlByBrandCode(code)} />
              <Box sx={{ fontWeight: 600 }}>{codeString}</Box>
            </Box>
            <Box sx={{ mb: 2 }}>{children}</Box>
          </React.Fragment>
        );
      }}
      getOptionLabel={(option) =>
        option.title + " " + formatAddress(option.address)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          margin={"normal"}
          label={"Středisko"}
          sx={{ mt: 0 }}
        />
      )}
    />
  );
};

const AssetFilterFulltext = () => {
  const property = "fulltext";

  const dispatch = useAppDispatch();

  const [value, setValue] = useState("");
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleScan = (text: string) => {
    setValue(text);
    handleClose();
  };

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      dispatch(
        assetSlice.actions.filterPropertySet({
          property: property,
          value: value,
        })
      );
    }, 1);
    setTimer(newTimer);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [value]);

  return (
    <>
      <TextField
        label={"Název, QR kód, identifikátor, výrobce, ..."}
        fullWidth={true}
        value={!!value ? value : ""}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ mr: -1 }}>
              <IconButton
                onClick={() => {
                  handleOpen();
                }}
                color={"secondary"}
              >
                <QrCodeScannerIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ p: 0 }}>
          <QrCodeScanner onScan={handleScan} />
        </DialogContent>
      </Dialog>
    </>
  );
};

const AssetFilterAssetMovementReasonIds = () => {
  const property = "assetMovementReasonIds";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectAssetFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      assetSlice.actions.filterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  const options = getAssetMovementReasons();

  return (
    <Autocomplete
      multiple={true}
      onChange={(event, newValue) => {
        if (!!newValue) {
          setValue(newValue.map((v) => v));
        } else {
          setValue(null);
        }
      }}
      value={options.filter((u) => value.includes(u))}
      options={options}
      renderOption={(props, option) => {
        return (
          <li key={option} {...props}>
            {getAssetMovementReasonLabel(option as any)}
          </li>
        );
      }}
      getOptionLabel={(option) =>
        getAssetMovementReasonLabel(option as any) || ""
      }
      renderInput={(params) => (
        <TextField
          {...params}
          margin={"normal"}
          label={"Důvod pohybu zařízení"}
          sx={{ mt: 0 }}
        />
      )}
    />
  );
};

interface AssetFilterCheckboxProps {
  label: string;
  property: string;
}

const AssetFilterCheckbox = (props: AssetFilterCheckboxProps) => {
  const { label, property } = props;

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectAssetFilterProperty(state, { property })
  );

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              onChange={(event) => {
                dispatch(
                  assetSlice.actions.filterPropertySet({
                    property,
                    value: event.target.checked,
                  })
                );
              }}
            />
          }
          label={label}
        />
      </FormGroup>
    </>
  );
};

const AssetFilter = () => {
  const unitLength = useAppSelector(selectFlattenUnitsLength);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          {unitLength > 1 && (
            <AssetFilterUnitIds />
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          {unitLength > 1 && (
            <AssetFilterAssetMovementReasonIds />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {unitLength > 1 && (
            <AssetFilterFulltext />
          )}
        </Grid>
      </Grid>


      <Box sx={{ display: "flex" }}>
        <Box sx={{ mr: 2 }}>
          <AssetFilterCheckbox
            property={"extraWithoutUnit"}
            label={"Není na žádném středisku"}
          />
        </Box>
        <Box sx={{ mr: 2 }}>
          <AssetFilterCheckbox
            property={"extraWithoutQrCode"}
            label={"Bez QR kódu"}
          />
        </Box>
      </Box>
    </>
  );
};

export default AssetFilter;
