import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { useDropzone } from "react-dropzone";
import { Box, Button } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { grey } from "@mui/material/colors";
import assetSlice from "../../../slices/asset.slice";
import { selectSelectedAsset } from "../../../selectors/asset.selector";
import { File as AssetFile } from "../../../../../types/file.types";

const ASSET_FILE_RELATION_TYPE_CODE__asset_photo = "asset_photo";
const ASSET_FILE_RELATION_TYPE_CODE__asset_label = "asset_label";

const assetFileRelationTypeCode2AcceptMimeType = {
  [ASSET_FILE_RELATION_TYPE_CODE__asset_photo]: {
    "image/png": [".png"],
    "image/jpeg": [".jpg", ".jpeg"],
  },
  [ASSET_FILE_RELATION_TYPE_CODE__asset_label]: {
    "image/png": [".png"],
    "image/jpeg": [".jpg", ".jpeg"],
  },
};

const assetFileRelationTypeCode2Label = {
  [ASSET_FILE_RELATION_TYPE_CODE__asset_photo]: "fotografie",
  [ASSET_FILE_RELATION_TYPE_CODE__asset_label]: "identifikační štítky",
};

type FileEnvelopeType = {
  action?: "no-action" | "create" | "delete";
  name: string;
  file: File;
  preview: string | undefined;
};

const toBase64 = (file: File): any =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

interface AssetDetailFieldProps {
  type: "asset_photo" | "asset_label";
}

const AssetDetailFieldFile = (props: AssetDetailFieldProps) => {
  const { type } = props;

  const dispatch = useAppDispatch();
  const asset = useAppSelector(selectSelectedAsset);

  const [files, setFiles] = useState<FileEnvelopeType[]>([]);
  const [filesToDelete, setFilesToDelete] = useState<string[]>([]);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: assetFileRelationTypeCode2AcceptMimeType[type],
    onDrop: (acceptedFiles) => {
      setFiles((prev) => {
        return prev.concat(
          acceptedFiles
            .filter((acceptedFile) => {
              return !prev.map((p) => p.name).includes(acceptedFile.name);
            })
            .map((acceptedFile) => {
              return {
                name: acceptedFile.name,
                file: acceptedFile,
                preview: URL.createObjectURL(acceptedFile),
              };
            })
        );
      });
    },
  });

  const removeFile = (fileName: string) => {
    setFiles((prev) => {
      const index = prev.findIndex(
        (p: FileEnvelopeType) => p.name === fileName
      );
      if (index > -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }
      return prev;
    });
  };

  const changeValue = (value: any) => {
    dispatch(
      assetSlice.actions.selectedAssetPropertySet({
        property: "input." + type + ".files",
        value: value,
      })
    );
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    const update = async (files: FileEnvelopeType[]) => {
      const value = [];
      for (const fe of files) {
        const base64 = await toBase64(fe.file);
        value.push({
          action: fe.action,
          name: fe.file.name,
          base64: base64,
          type: type,
          mimeType: fe.file.type,
        });
      }
      changeValue(value);
    };

    update(files);
  }, [files]);

  useEffect(() => {
    dispatch(
      assetSlice.actions.selectedAssetPropertySet({
        property: "input." + type + ".filesToDelete",
        value: filesToDelete,
      })
    );
  }, [filesToDelete]);

  if (!asset) {
    return <></>;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {asset.files
          .filter(
            (f: AssetFile) => f.type === type && !filesToDelete.includes(f.id)
          )
          .map((file: AssetFile) => {
            return (
              <Box
                key={file.id}
                sx={{
                  mb: 1,
                  mr: 1,
                  bgcolor: grey[100],
                  p: 1,
                  borderRadius: "4px",
                }}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    borderRadius: "4px",
                    width: 114,
                    height: 114,
                    boxSizing: "border-box",
                  }}
                >
                  <img
                    alt={file.filename}
                    style={{
                      display: "block",
                      width: "100%",
                      height: "100%",
                      objectPosition: "center",
                      objectFit: "cover",
                      borderRadius: 4,
                    }}
                    src={
                      process.env.REACT_APP_ENDPOINT_FILES +
                      "/" +
                      file.path +
                      "/thumbnails/" +
                      file.filename
                    }
                  />
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      setFilesToDelete((prev) => {
                        return [...prev, file.id];
                      });
                    }}
                    size={"small"}
                    color={"error"}
                    variant="text"
                    startIcon={<DeleteIcon />}
                    sx={{ mt: 1 }}
                    fullWidth
                  >
                    Odstranit
                  </Button>
                </Box>
              </Box>
            );
          })}
        {files.map((file: FileEnvelopeType) => {
          return (
            <Box
              key={file.name}
              sx={{
                mb: 1,
                mr: 1,
                bgcolor: grey[100],
                p: 1,
                borderRadius: "4px",
              }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  borderRadius: "4px",
                  width: 114,
                  height: 114,
                  boxSizing: "border-box",
                }}
              >
                <img
                  alt={file.name}
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    objectPosition: "center",
                    objectFit: "cover",
                    borderRadius: 4,
                  }}
                  src={file.preview}
                  // Revoke data uri after image is loaded
                  onLoad={() => {
                    if (file.preview != null) {
                      URL.revokeObjectURL(file.preview);
                    }
                  }}
                />
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    removeFile(file.name);
                  }}
                  size={"small"}
                  color={"error"}
                  variant="text"
                  startIcon={<DeleteIcon />}
                  sx={{ mt: 1 }}
                  fullWidth
                >
                  Odstranit
                </Button>
              </Box>
            </Box>
          );
        })}
      </Box>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <Button
          fullWidth={true}
          color={"secondary"}
          variant={"outlined"}
          onClick={open}
          startIcon={<FileUpload />}
        >
          Nahrát{" "}
          {(files.length > 0 ||
            asset.files.filter(
              (f) => f.type === type && !filesToDelete.includes(f.id)
            ).length > 0) && <>další </>}{" "}
          {assetFileRelationTypeCode2Label[type]}
        </Button>
      </div>
    </>
  );
};

export default AssetDetailFieldFile;
