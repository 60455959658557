import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListConfigType, UnitFilterType } from "../../../types";
import { buildInitialFilter } from "../builders/filter.builder";

type StateType = {
  filter: UnitFilterType;
  listConfig: ListConfigType;
};

const initialState: StateType = {
  filter: buildInitialFilter(),
  listConfig: {
    page: 0,
    pageSize: 25,
    sortBy: null,
    sortByDirection: "ASC",
  },
};

const unitSlice = createSlice({
  name: "unit",
  initialState: initialState,
  reducers: {
    unitFilterSet: (
      state: StateType,
      action: PayloadAction<UnitFilterType>
    ) => {
      state.filter = action.payload;
      state.listConfig = { ...state.listConfig, page: 0 };
    },
    unitFilterPropertySet: (
      state: StateType,
      action: PayloadAction<{ property: string; value: any }>
    ) => {
      if (!state.filter) {
        state.filter = buildInitialFilter();
      }
      // @ts-ignore
      state.filter[action.payload.property] = action.payload.value;
      state.listConfig = { ...state.listConfig, page: 0 };
    },
    listConfigSet: (state: StateType, action: PayloadAction<any>) => {
      state.listConfig = { ...state.listConfig, ...action.payload };
    },
  },
});

export default unitSlice;
