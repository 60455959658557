//@ts-nocheck

import React from "react";
import { Box, Divider, Tooltip } from "@mui/material";
import ChipUserType from "../../Chip/ChipUserType";
import { useAppSelector } from "../../../../../app/store";
import { selectIssueById } from "../../../selectors/issueSelectors";
import {
  formatDate,
  formatDateDistanceToNow,
} from "../../../../common/utils/dateTime.utils";
import { grey } from "@mui/material/colors";

const Comment = (props: any) => {
  const { comment, isDetail = false } = props;

  return (
    <Box
      variant={"outlined"}
      sx={{
        mb: 1,
        pb: isDetail ? 1 : 0,
        borderBottom: isDetail ? "1px solid #ececec" : 0,
        "&:last-of-type": {
          borderBottom: 0,
        },
      }}
      display="flex"
      alignItems="center"
      className="komentar"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          mr: 1,
        }}
      >
        <Tooltip title={formatDateDistanceToNow(comment.createdAt)}>
          <Box
            sx={{
              mr: 1,
              fontSize: 12,
              color: grey[500],
              whiteSpace: "nowrap",
            }}
          >
            {formatDate(comment.createdAt)}
          </Box>
        </Tooltip>

        <ChipUserType
          userTypeCode={comment.createdBy.type}
          label={comment.createdBy.displayName}
        />
      </Box>

      {isDetail ? (
        <Box
          sx={{
            fontSize: 12,
          }}
        >
          {comment.message}
        </Box>
      ) : (
        <Box sx={{ fontSize: 12, width: "100%", overflow: "hidden" }}>
          <Box
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {comment.message}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const FieldComments = (props: any) => {
  const { issueId, showCount = 2, isDetail = false } = props;

  const issue = useAppSelector((state) => selectIssueById(state, issueId));

  const comments = [...issue.comments].sort((a, b) => {
    return -a.createdAt.localeCompare(b.createdAt);
  });

  if (comments.length === 0) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ p: 2, pb: 0, width: "100%" }}>
        {comments.slice(0, showCount).map((comment) => {
          return (
            <Comment key={comment.id} comment={comment} isDetail={isDetail} />
          );
        })}
        {comments.length - showCount > 0 && (
          <Box
            sx={{
              mr: 1,
              mb: 1,
              fontSize: 12,
              color: grey[500],
            }}
          >
            {comments.length - showCount} předchozí &hellip;
          </Box>
        )}
      </Box>
      <Divider />
    </>
  );
};

export const fieldComments = {
  code: "fieldComments",
  label: "Komentáře",
  valueGetterFn: (obj) => {
    return "";
  },
  component: FieldComments,
};
