import { createBrowserRouter, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import LogInView from "../views/LogInView";
import IndexView from "../views/IndexView";
import Issue from "../features/issue/components/Issue/Issue";
import Revision from "../features/revision/components/Revision/Revision";
import Document from "../features/document/components/Document/Document";
import Asset from "../features/asset/components/Asset/Asset";
import AssetFinderView from "../views/AssetFinderView";

export const ROUTER_PATH_INDEX = "/";
export const ROUTER_PATH_ASSET_FINDER = "/asset-finder";
export const ROUTER_PATH_LOGIN = "/login";

const V1CompatibilityRedirector = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const pathnameParts = window.location.pathname.split("/");
    if (pathnameParts.length === 3 && pathnameParts[1] === "login-qr") {
      navigate(ROUTER_PATH_INDEX + "?a=" + pathnameParts[2]);
    }
    if (pathnameParts.length === 3 && pathnameParts[1] === "service-tickets") {
      navigate(ROUTER_PATH_INDEX + "?a=" + pathnameParts[2]);
    }
  }, [navigate]);

  return <></>;
};

const router = createBrowserRouter([
  {
    path: "/login-qr/:qrHash",
    element: <V1CompatibilityRedirector />,
  },
  {
    path: "/service-tickets/:id",
    element: <V1CompatibilityRedirector />,
  },
  {
    path: "/dashboard",
    element: <IndexView />,
  },
  {
    path: "/issues",
    element: <IndexView />,
  },
  {
    path: "/revisions",
    element: <IndexView />,
  },
  {
    path: "/documents",
    element: <IndexView />,
  },
  {
    path: "/assets",
    element: <IndexView />,
  },
  {
    path: ROUTER_PATH_INDEX,
    element: <IndexView />,
  },
  {
    path: ROUTER_PATH_LOGIN,
    element: <LogInView />,
  },
  {
    path: ROUTER_PATH_ASSET_FINDER,
    element: <AssetFinderView />,
  },
  {
    path: "*",
    element: <IndexView />,
  },
]);

export const indexPathnames = {
  "/": {
    title: "Fixnote",
    component: Issue,
  },
  "/dashboard": {
    title: "Požadavky — Fixnote",
    component: Issue,
  },
  "/issues": {
    title: "Požadavky — Fixnote",
    component: Issue,
  },
  "/revisions": {
    title: "Revize — Fixnote",
    component: Revision,
  },
  "/documents": {
    title: "Dokumenty — Fixnote",
    component: Document,
  },
  "/assets": {
    title: "Zařízení — Fixnote",
    component: Asset,
  },
};

export default router;
