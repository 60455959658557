import React, { useEffect } from "react";
import { UnitType } from "../../../../types";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AssetCreateButton from "../../../asset/components/AssetCreateButton/AssetCreateButton";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  selectAssetsByUnitId,
  selectListFetchingStatus,
} from "../../../asset/selectors/asset.selector";
import assetSlice from "../../../asset/slices/asset.slice";
import { fieldAsset } from "../../../asset/components/fields/fieldAsset";
import { fieldIdentifiers } from "../../../asset/components/fields/fieldIdentifiers";
import { fieldManufacturer } from "../../../asset/components/fields/fieldManufacturer";
import { fieldSupplier } from "../../../asset/components/fields/fieldSupplier";

const TabAssets = (props: { unit: UnitType }) => {
  const { unit } = props;

  const dispatch = useAppDispatch();
  const fetchingStatus = useAppSelector(selectListFetchingStatus);
  const rows = useAppSelector((state) =>
    selectAssetsByUnitId(state, { unitId: unit.id })
  );

  useEffect(() => {
    if (fetchingStatus === "init") {
      dispatch(assetSlice.actions.listFetch());
    }
  }, [fetchingStatus]);

  return (
    <>
      <Box sx={{ textAlign: "right", pb: 1 }}>
        <AssetCreateButton btnProps={{ sx: { mb: 1 } }} />
      </Box>
      <Box>
        <Table padding={"none"}>
          <TableHead>
            <TableRow>
              <TableCell>Zařízení</TableCell>
              <TableCell>Identifikace</TableCell>
              <TableCell>Výrobce a dodavatel</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell
                    onClick={() => {
                      dispatch(assetSlice.actions.selectedAssetSetById(row.id));
                    }}
                  >
                    {React.createElement(fieldAsset.component, {
                      assetId: row.id,
                    })}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      dispatch(assetSlice.actions.selectedAssetSetById(row.id));
                    }}
                  >
                    {React.createElement(fieldIdentifiers.component, {
                      assetId: row.id,
                    })}
                  </TableCell>
                  <TableCell>
                    <Box>
                      {React.createElement(fieldManufacturer.component, {
                        assetId: row.id,
                      })}
                    </Box>
                    <Box>
                      {React.createElement(fieldSupplier.component, {
                        assetId: row.id,
                      })}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default TabAssets;
