import React from "react";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { RootState, useAppSelector } from "../../../../app/store";
import { selectAssetRelationLast } from "../../selectors/asset.selector";
import { shallowEqual } from "react-redux";
import ChipManufacturer from "../../../common/components/Chip/ChipManufacturer";

const Field = ({
  assetId,
  isDetail = false,
}: {
  assetId: string;
  isDetail?: boolean;
}) => {
  const rel = useAppSelector(
    (state: RootState) =>
      selectAssetRelationLast(state, {
        assetId: assetId,
        relationName: "manufacturerRelations",
      }),
    {
      equalityFn: shallowEqual,
    }
  );

  if (!rel) {
    return (
      <Box sx={{ p: 2, width: "100%", display: "flex" }}>
        <Typography
          variant={"body2"}
          sx={{
            fontStyle: "italic",
            color: grey[500],
            fontWeight: 100,
            fontSize: "90%",
          }}
        >
          není uvedeno
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, width: "100%", display: "flex" }}>
      <ChipManufacturer label={rel.manufacturer.title} />
    </Box>
  );
};

export const fieldManufacturer = {
  code: "fieldManufacturer",
  label: "Výrobce",
  valueGetterFn: (obj: any) => {
    return "";
  },
  component: Field,
};
