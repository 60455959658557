//@ts-nocheck

import { useAppSelector } from "../../../../app/store";
import React from "react";
import { selectRevisionById } from "../../selectors/revisionSelectors";
import FieldUnitContent from "../../../enum/components/FieldUnitContent/FieldUnitContent";

const FieldUnit = (props) => {
  const { revisionId } = props;

  const revision = useAppSelector((state) =>
    selectRevisionById(state, revisionId)
  );

  if (!revision || !revision.unitId) {
    return <></>;
  }

  return <FieldUnitContent unitId={revision.unitId} />;
};

export const fieldUnit = {
  code: "fieldUnit",
  label: "Středisko",
  component: FieldUnit,
};
