import { AssetUnitRelation } from "../../../types/asset.types";

export const isOlderThanXDaysOrEmpty = (obj: AssetUnitRelation, x: number) => {
  const DAYS_IN_MS = x * 24 * 60 * 60 * 1000; // 30 dní v milisekundách

  // Zkontrolujeme, zda je pole assetUnitRelationPresences prázdné
  if (
    !obj.assetUnitRelationPresences ||
    obj.assetUnitRelationPresences.length === 0
  ) {
    return true;
  }

  // Nalezneme nejmladší createdAt v poli
  const newestCreatedAt = obj.assetUnitRelationPresences.reduce(
    (newest, current) => {
      const currentCreatedAt = new Date(current.createdAt);
      return currentCreatedAt > newest ? currentCreatedAt : newest;
    },
    new Date(0)
  ); // Inicializace s velmi starým datem

  // Zjistíme, zda je nejmladší createdAt starší než x dní
  const now = new Date();
  // @ts-ignore
  return now - newestCreatedAt > DAYS_IN_MS;
};
