import React, { useEffect, useState } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";

interface ScannerProps {
  onScan: (result: string) => void;
}

const qrcodeRegionId = "html5qr-code-full-region";

const QrCodeScanner = ({ onScan }: ScannerProps) => {
  const [r, setR] = useState<string | null>(null);

  const qrCodeSuccessCallback = (decodedText: any, decodedResult: any) => {
    if (!r && !!decodedText) {
      setR(decodedText);
      onScan(decodedText);
    }
  };

  const qrCodeErrorCallback = (error: any) => { };

  useEffect(() => {
    // when component mounts
    const config = {
      fps: 20,
      qrBox: 500,
      disableFlip: true,
    };
    const verbose = false;

    // Suceess callback is required.
    const html5QrcodeScanner = new Html5QrcodeScanner(
      qrcodeRegionId,
      config,
      verbose
    );
    html5QrcodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback);

    // cleanup function when component will unmount
    return () => {
      html5QrcodeScanner.clear().catch((error: any) => {
        console.error("Failed to clear html5QrcodeScanner. ", error);
      });
    };
  }, []);

  return <div id={qrcodeRegionId} />;
};

export default QrCodeScanner;
