import { AnyAction } from "@reduxjs/toolkit";
import {
  effectIssueListFetchArgsType,
  ListenerApiType,
} from "../../../types/api.types";
import { documentDelete, documentGetAll, documentPost } from "../../../api/api";

import documentSlice from "../slices/document.slice";
import { RootState } from "../../../app/store";
import { selectSelectedDocument } from "../selectors/document.selectors";

export const effectDocumentListFetch = async (
  action: AnyAction,
  listenerApi: ListenerApiType,
  args: effectIssueListFetchArgsType = {
    showFetchingIndicator: true,
    resetList: false,
    queryParams: {},
  }
) => {
  if (args.showFetchingIndicator) {
    listenerApi.dispatch(documentSlice.actions.documentListFetching(true));
  }

  documentGetAll({ queryParams: args.queryParams })
    .then((response: any) => {
      if (response.status === 200) {
        if (args.resetList) {
          listenerApi.dispatch(
            documentSlice.actions.documentListSet(response.data.documents)
          );
        } else {
          listenerApi.dispatch(
            documentSlice.actions.documentListUpsertMany(
              response.data.documents
            )
          );
        }
      }
    })
    .finally(() => {
      listenerApi.dispatch(documentSlice.actions.documentListFetching(false));
    });
};

export const effectSelectedDocumentSubmit = async (
  action: AnyAction,
  listenerApi: ListenerApiType
) => {
  const originalState: RootState = listenerApi.getState() as RootState;
  const document = selectSelectedDocument(originalState);

  if (!!document) {
    const copy = { ...document };
    documentPost({ data: copy }).then((response: any) => {
      if (response.status === 200 || response.status === 201) {
        listenerApi.dispatch(
          documentSlice.actions.documentListUpsertOne(response.data.document)
        );
        listenerApi.dispatch(
          documentSlice.actions.documentListRemoveOne(
            [
              response.data.document.unitId,
              response.data.document.templateId,
            ].join("_")
          )
        );
      }
    });
  }
};

export const effectDocumentDelete = async (
  action: AnyAction,
  listenerApi: ListenerApiType
) => {
  const document = action.payload;

  if (!!document) {
    documentDelete({ documentId: document.documentId }).then(
      (response: any) => {
        if (response.status === 200) {
          listenerApi.dispatch(
            documentSlice.actions.documentListRemoveOne(document.id)
          );
          if (!!response.data.documentTemplate) {
            listenerApi.dispatch(
              documentSlice.actions.documentListUpsertOne(
                response.data.documentTemplate
              )
            );
          }
        }
      }
    );
  }
};
