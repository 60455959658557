//@ts-nocheck

import React from "react";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { selectIssueById } from "../../../selectors/issueSelectors";
import {
  selectBrandByUnitId,
  selectUnitAddressByUnitId,
  selectUnitById,
} from "../../../../enum/selectors/enumSelectors";
import { getBrandLogoUrlByBrandCode } from "../../../../../libraries/brands";
import issueSlice from "../../../slices/issueSlice";

const FieldUnitContent = (props) => {
  const { unitId } = props;

  const dispatch = useAppDispatch();
  const unit = useAppSelector((state) => selectUnitById(state, unitId));
  const brand = useAppSelector((state) => selectBrandByUnitId(state, unitId));
  const unitAddress = useAppSelector((state) =>
    selectUnitAddressByUnitId(state, unitId)
  );

  const handleClick = (e) => {
    e.stopPropagation();
    dispatch(issueSlice.actions.selectedUnitIdSet(unitId));
  };

  if (!unit || !brand) {
    return <></>;
  }

  return (
    <>
      <Box
        p={2}
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Tooltip title="Zobrazit více informací o pobočce">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              "&:hover": {
                cursor: "pointer",
                ".MuiAvatar-root": {
                  borderColor: "rgb(255, 224, 178)",
                },
              },
            }}
            onClick={handleClick}
          >
            <Avatar
              src={getBrandLogoUrlByBrandCode(brand.code)}
              sx={{
                width: 48,
                height: 48,
                border: "6px solid transparent",
                mr: 1,
                transition: "all 300ms",
              }}
            />
            <Box>
              <Typography
                variant="body2"
                sx={{ color: grey[600], fontWeight: 600 }}
              >
                {unit.title}
              </Typography>
              <Typography variant="body2" sx={{ lineHeight: 1.4 }}>
                {unit.subtitle || ""}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      </Box>
    </>
  );
};

const FieldUnit = (props) => {
  const { issueId } = props;

  const issue = useAppSelector((state) => selectIssueById(state, issueId));

  if (!issue || !issue.unitId) {
    return <></>;
  }

  return <FieldUnitContent unitId={issue.unitId} />;
};

export const fieldUnit = {
  code: "fieldUnit",
  label: "Středisko",
  valueGetterFn: (obj) => {
    return "";
  },
  component: FieldUnit,
};
