import { Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { PERMISSION_RIGHT_CODE__createRevision } from "../../../../libraries/enums/permissionRights";
import { buildRevision } from "../../builders/revision.builder";
import revisionSlice from "../../slices/revisionSlice";
import { selectUnitBySelectedUnitId } from "../../../enum/selectors/enumSelectors";

const RevisionCreateButton = (props: any) => {
  const { btnProps = {} } = props;

  const dispatch = useAppDispatch();

  const unit = useAppSelector(selectUnitBySelectedUnitId);
  const rightCreateRevision = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createRevision,
    })
  );

  const handleCreateRevision = () => {
    const revision = buildRevision();
    if (!!unit) {
      revision.unitId = unit.id;
      revision.unitStoreNumber = unit.storeNumber;
    }
    dispatch(revisionSlice.actions.selectedRevisionSet(revision));
    dispatch(revisionSlice.actions.selectedRevisionModeSet("create"));
  };

  if (!rightCreateRevision) {
    return <></>;
  }

  return (
    <>
      <Button
        onClick={handleCreateRevision}
        color={"primary"}
        variant={"contained"}
        startIcon={<AddCircleIcon />}
        size="large"
        {...btnProps}
      >
        Přidat revizi
      </Button>
    </>
  );
};

export default RevisionCreateButton;
