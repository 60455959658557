import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  BrandType,
  FieldManagerType,
  MandantType,
  RevisionCompanyType,
  RevisionSubjectType,
  ServiceCompanyType,
  ServiceTechnicianType,
  UnitAddressType,
  UnitOpeningHourType,
  UnitType,
} from "../../../types";

type StateType = {
  brand: EntityState<BrandType>;
  mandant: EntityState<MandantType>;
  fieldManager: EntityState<FieldManagerType>;
  unitAddress: EntityState<UnitAddressType>;
  unit: EntityState<UnitType>;
  unitOpeningHour: EntityState<UnitOpeningHourType>;
  serviceCompany: EntityState<ServiceCompanyType>;
  serviceTechnician: EntityState<ServiceTechnicianType>;
  revisionCompany: EntityState<RevisionCompanyType>;
  revisionSubject: EntityState<RevisionSubjectType>;
};

export const brandEntityAdapter = createEntityAdapter<BrandType>();
export const mandantEntityAdapter = createEntityAdapter<MandantType>();
export const fieldManagerEntityAdapter =
  createEntityAdapter<FieldManagerType>();
export const unitAddressEntityAdapter = createEntityAdapter<UnitAddressType>();
export const unitEntityAdapter = createEntityAdapter<UnitType>();
export const unitOpeningHourEntityAdapter =
  createEntityAdapter<UnitOpeningHourType>();
export const serviceCompanyEntityAdapter =
  createEntityAdapter<ServiceCompanyType>();
export const serviceTechnicianEntityAdapter =
  createEntityAdapter<ServiceTechnicianType>();
export const revisionCompanyEntityAdapter =
  createEntityAdapter<RevisionCompanyType>();
export const revisionSubjectEntityAdapter =
  createEntityAdapter<RevisionSubjectType>();

const initialState: StateType = {
  brand: brandEntityAdapter.getInitialState(),
  mandant: mandantEntityAdapter.getInitialState(),
  fieldManager: fieldManagerEntityAdapter.getInitialState(),
  unitAddress: unitAddressEntityAdapter.getInitialState(),
  unit: unitEntityAdapter.getInitialState(),
  unitOpeningHour: unitOpeningHourEntityAdapter.getInitialState(),
  serviceCompany: serviceCompanyEntityAdapter.getInitialState(),
  serviceTechnician: serviceTechnicianEntityAdapter.getInitialState(),
  revisionCompany: revisionCompanyEntityAdapter.getInitialState(),
  revisionSubject: revisionSubjectEntityAdapter.getInitialState(),
};

const enumSlice = createSlice({
  name: "enum",
  initialState: initialState,
  reducers: {
    enumsFetch: () => {},
    brandSet: (state: StateType, action: PayloadAction<BrandType[]>) => {
      brandEntityAdapter.setAll(state.brand, action.payload);
    },
    mandantSet: (state: StateType, action: PayloadAction<MandantType[]>) => {
      mandantEntityAdapter.setAll(state.mandant, action.payload);
    },
    fieldManagerSet: (
      state: StateType,
      action: PayloadAction<FieldManagerType[]>
    ) => {
      fieldManagerEntityAdapter.setAll(state.fieldManager, action.payload);
    },
    unitAddressSet: (
      state: StateType,
      action: PayloadAction<UnitAddressType[]>
    ) => {
      unitAddressEntityAdapter.setAll(state.unitAddress, action.payload);
    },
    unitSet: (state: StateType, action: PayloadAction<UnitType[]>) => {
      unitEntityAdapter.setAll(state.unit, action.payload);
    },
    unitOpeningHourSet: (
      state: StateType,
      action: PayloadAction<UnitOpeningHourType[]>
    ) => {
      unitOpeningHourEntityAdapter.setAll(
        state.unitOpeningHour,
        action.payload
      );
    },
    serviceCompanySet: (
      state: StateType,
      action: PayloadAction<ServiceCompanyType[]>
    ) => {
      serviceCompanyEntityAdapter.setAll(state.serviceCompany, action.payload);
    },
    serviceTechnicianSet: (
      state: StateType,
      action: PayloadAction<ServiceTechnicianType[]>
    ) => {
      serviceTechnicianEntityAdapter.setAll(
        state.serviceTechnician,
        action.payload
      );
    },
    revisionCompanySet: (
      state: StateType,
      action: PayloadAction<RevisionCompanyType[]>
    ) => {
      revisionCompanyEntityAdapter.setAll(
        state.revisionCompany,
        action.payload
      );
    },
    revisionSubjectSet: (
      state: StateType,
      action: PayloadAction<RevisionSubjectType[]>
    ) => {
      revisionSubjectEntityAdapter.setAll(
        state.revisionSubject,
        action.payload
      );
    },
  },
});

export default enumSlice;
