import {
  Button,
  ButtonGroup,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import issueSlice from "../../slices/issueSlice";
import { buildIssue } from "../../builders/issue.builder";
import {
  PERMISSION_RIGHT_CODE__createIssue,
  PERMISSION_RIGHT_CODE__createIssueMultiple,
} from "../../../../libraries/enums/permissionRights";
import {
  AddCircle,
  ArrowDropDown,
  ControlPointDuplicate,
} from "@mui/icons-material";
import { useState } from "react";

const IssueCreateButton = () => {
  const dispatch = useAppDispatch();

  const rightCreateIssue = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createIssue,
    })
  );

  const rightCreateIssueMultiple = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createIssueMultiple,
    })
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleCreateIssue = (defaults = {}) => {
    const issue = { ...buildIssue(), ...defaults };
    dispatch(issueSlice.actions.selectedIssueSet(issue));
    dispatch(issueSlice.actions.selectedIssueModeSet("create"));
    handleClose();
  };

  if (!rightCreateIssue) {
    return <></>;
  }

  return (
    <ButtonGroup>
      <Button
        onClick={() => {
          handleCreateIssue();
        }}
        color={"primary"}
        variant={"contained"}
        startIcon={<AddCircle />}
        size="large"
      >
        Přidat požadavek
      </Button>
      {rightCreateIssueMultiple && (
        <>
          <Button
            color={"primary"}
            variant={"contained"}
            size="small"
            onClick={handleOpen}
          >
            <ArrowDropDown />
          </Button>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuList>
              <MenuItem
                onClick={() => {
                  handleCreateIssue({ unitIds: [] });
                }}
              >
                <ListItemIcon>
                  <ControlPointDuplicate fontSize="small" />
                </ListItemIcon>
                <ListItemText>Přidat požadavek na více středisek</ListItemText>
              </MenuItem>
            </MenuList>
          </Popover>
        </>
      )}
    </ButtonGroup>
  );
};

export default IssueCreateButton;
