import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  selectSelectedIssueMode,
  selectSelectedIssueProperty,
} from "../../../selectors/issueSelectors";
import issueSlice from "../../../slices/issueSlice";
import { getIssueTypes } from "../../../../../libraries/enums/issueTypes";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const IssueDetailFieldIssueTypeEditable = () => {
  const dispatch = useAppDispatch();

  const changeValue = (value: any) => {
    dispatch(
      issueSlice.actions.selectedIssuePropertySet({
        property: "typeId",
        value: value,
      })
    );
  };
  const value = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "typeId" })
  );

  const options = getIssueTypes();

  useEffect(() => {
    if (options.length === 1) {
      changeValue(options[0].code);
    }
  }, [options]);

  if (options.length === 1) {
    return <></>;
  }

  return (
    <RadioGroup
      value={value}
      onChange={(event) => {
        changeValue(event.target.value);
      }}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.code}
          value={option.code}
          control={<Radio color="primary" />}
          label={option.label}
          sx={{ mx: 2 }}
        />
      ))}
    </RadioGroup>

    // <Box sx={{ display: "flex", my: 1, justifyContent: "space-between" }}>
    //   {options.map((option) => {
    //     return (
    //       <Box
    //         sx={{ mx: 1 }}
    //         key={option.code}
    //         onClick={() => {
    //           changeValue(option.code);
    //         }}
    //       >
    //         <ChipIssueType
    //           key={option.code}
    //           issueTypeCode={option.code}
    //           label={option.label}
    //           active={true}
    //           selected={value === option.code}
    //         />
    //       </Box>
    //     );
    //   })}
    // </Box>
  );
};

const IssueDetailFieldIssueType = () => {
  const mode = useAppSelector(selectSelectedIssueMode);

  switch (mode) {
    case "create": {
      return <IssueDetailFieldIssueTypeEditable />;
    }
    case "update": {
      return <IssueDetailFieldIssueTypeEditable />;
    }
    default: {
      return <></>;
    }
  }
};

export default IssueDetailFieldIssueType;
