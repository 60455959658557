import {
  Clear,
  FilterList,
  ReportProblem,
  SentimentNeutral,
  SentimentVeryDissatisfied,
} from "@mui/icons-material";
import React from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Popover,
  Switch,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { buildInitialFilter } from "../../builders/filter.builder";
import {
  selectFlattenUnits,
  selectRevisionCompanies,
  selectRevisionSubjects,
} from "../../../enum/selectors/enumSelectors";
import { formatAddress } from "../../../common/utils/address.utils";
import { BrandType } from "../../../../types";
import { getBrandLogoUrlByBrandCode } from "../../../../libraries/brands";
import CloseButton from "../../../common/components/CloseButton/CloseButton";
import { useTheme } from "@mui/material/styles";
import { selectProfile } from "../../../auth/selectors/authSelectors";
import {
  selectRevisionFilterIsEnabled,
  selectRevisionFilterProperty,
} from "../../selectors/revisionSelectors";
import revisionSlice from "../../slices/revisionSlice";
import { getRevisionTypes } from "../../../../libraries/enums/revisionTypes";
import {
  getRevisionExpirationStatusByCode,
  REVISION_EXPIRATION_STATUS_after,
  REVISION_EXPIRATION_STATUS_expiring,
  REVISION_EXPIRATION_STATUS_replaced,
} from "../../../../libraries/enums/revisionExpirationStatuses";
import {
  getActionOfRevisionSubject,
  getFullTitleOfRevisionSubject,
} from "../../../../libraries/enums/revisionSubjects";

const RevisionFilterContentExpirationStatus = () => {
  const property = "expirationStatusIds";
  const dispatch = useAppDispatch();

  const value = useAppSelector((state) =>
    selectRevisionFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      revisionSlice.actions.revisionFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  const items = [
    {
      code: REVISION_EXPIRATION_STATUS_after,
      label: getRevisionExpirationStatusByCode(REVISION_EXPIRATION_STATUS_after)
        .label,
    },
    {
      code: REVISION_EXPIRATION_STATUS_expiring,
      label: getRevisionExpirationStatusByCode(
        REVISION_EXPIRATION_STATUS_expiring
      ).label,
    },
    {
      code: REVISION_EXPIRATION_STATUS_replaced,
      label: "Nahrazené",
    },
  ];

  return (
    <FormControl component="fieldset" margin="normal" fullWidth>
      <FormLabel component="legend">Stav</FormLabel>
      <FormGroup row>
        {items.map((item) => {
          const checked = value.includes(item.code);
          return (
            <FormControlLabel
              key={item.code}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => {
                    if (value.includes(item.code)) {
                      setValue(value.filter((v: any) => v !== item.code));
                    } else {
                      setValue(value.concat(item.code));
                    }
                  }}
                />
              }
              label={item.label}
              labelPlacement="end"
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

const RevisionFilterContentType = () => {
  const property = "typeIds";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectRevisionFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      revisionSlice.actions.revisionFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  return (
    <FormControl component="fieldset" margin="normal" fullWidth>
      <FormLabel component="legend">Typ</FormLabel>
      <FormGroup row>
        {getRevisionTypes().map((item) => {
          const checked = value.includes(item.code);
          return (
            <FormControlLabel
              key={item.code}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => {
                    if (value.includes(item.code)) {
                      setValue(value.filter((v: any) => v !== item.code));
                    } else {
                      setValue(value.concat(item.code));
                    }
                  }}
                />
              }
              label={item.label}
              labelPlacement="end"
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

const RevisionFilterContentUnit = () => {
  const property = "unitIds";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectRevisionFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      revisionSlice.actions.revisionFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  const unitOptions = useAppSelector((state) => selectFlattenUnits(state));

  return (
    <Autocomplete
      multiple={true}
      onChange={(event, newValue) => {
        if (!!newValue) {
          setValue(newValue.map((v) => v.id));
        } else {
          setValue(null);
        }
      }}
      value={unitOptions.filter((u) => value.includes(u.id))}
      options={unitOptions}
      renderOption={(props, option) => {
        return (
          <li key={option.id} {...props}>
            <strong>{option.title}</strong>&nbsp;&nbsp;
            {/* {formatAddress(option.address)} */}
            {option.subtitle || ""}
          </li>
        );
      }}
      groupBy={(option) => {
        // @ts-ignore
        return option.brand.code;
      }}
      renderGroup={(item) => {
        const { group, children } = item;
        const code = group as unknown as Pick<BrandType, "code">;
        const codeString = code as unknown as string;
        return (
          <React.Fragment key={item.key}>
            <Box sx={{ m: 1, display: "flex", alignItems: "center" }}>
              <Avatar sx={{ mx: 1 }} src={getBrandLogoUrlByBrandCode(code)} />
              <Box sx={{ fontWeight: 600 }}>{codeString}</Box>
            </Box>
            <Box sx={{ mb: 2 }}>{children}</Box>
          </React.Fragment>
        );
      }}
      getOptionLabel={(option) =>
        option.title + " " + formatAddress(option.address)
      }
      renderInput={(params) => (
        <TextField {...params} margin="normal" label={"Středisko"} />
      )}
    />
  );
};

const RevisionFilterContentRevisionCompany = () => {
  const property = "revisionCompanyIds";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectRevisionFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      revisionSlice.actions.revisionFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  const options = useAppSelector(selectRevisionCompanies);

  return (
    <Autocomplete
      multiple={true}
      onChange={(event, newValue) => {
        if (!!newValue) {
          setValue(newValue.map((v) => v.id));
        } else {
          setValue(null);
        }
      }}
      value={options.filter((u) => value.includes(u.id))}
      options={options}
      renderOption={(props, option) => {
        return (
          <li key={option.id} {...props}>
            <strong>{option.title}</strong>
          </li>
        );
      }}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField {...params} margin="normal" label={"Revizní společnost"} />
      )}
    />
  );
};

const RevisionFilterContentRevisionSubject = () => {
  const property = "revisionSubjectIds";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectRevisionFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      revisionSlice.actions.revisionFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  const options = useAppSelector(selectRevisionSubjects);

  return (
    <Autocomplete
      multiple={true}
      onChange={(event, newValue) => {
        if (!!newValue) {
          setValue(newValue.map((v) => v.id));
        } else {
          setValue(null);
        }
      }}
      value={options.filter((u) => value.includes(u.id))}
      options={options}
      renderOption={(props, option) => {
        return (
          <li key={option.id} {...props}>
            {getFullTitleOfRevisionSubject(option)}
          </li>
        );
      }}
      groupBy={(option) => {
        // @ts-ignore
        return getActionOfRevisionSubject(option);
      }}
      renderGroup={(item) => {
        const { group, children } = item;
        const code = group as unknown as Pick<BrandType, "code">;
        const codeString = code as unknown as string;
        return (
          <React.Fragment key={item.key}>
            <Box sx={{ m: 1, display: "flex", alignItems: "center" }}>
              <Box sx={{ fontWeight: 600 }}>{codeString}</Box>
            </Box>
            <Box sx={{ mb: 2 }}>{children}</Box>
          </React.Fragment>
        );
      }}
      getOptionLabel={(option) => getFullTitleOfRevisionSubject(option)}
      renderInput={(params) => (
        <TextField {...params} margin={"normal"} label={"Předmět revize"} />
      )}
    />
  );
};

const RevisionFilterContentWithDefectsOnly = () => {
  const property = "withDefectsOnly";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectRevisionFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      revisionSlice.actions.revisionFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            color={"primary"}
            checked={value}
            onChange={(event) => {
              setValue(event.target.checked);
            }}
          />
        }
        label={"Revize pouze se závadami"}
      />
    </FormGroup>
  );
};

const RevisionFilterContent = () => {
  const profile = useAppSelector(selectProfile);

  if (!profile) {
    return <></>;
  }

  return (
    <>
      <RevisionFilterContentType />
      <RevisionFilterContentExpirationStatus />
      <RevisionFilterContentUnit />
      <RevisionFilterContentRevisionCompany />
      <RevisionFilterContentRevisionSubject />
      <RevisionFilterContentWithDefectsOnly />
    </>
  );
};

const RevisionFilter = () => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const filterEnabled = useAppSelector(selectRevisionFilterIsEnabled);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    dispatch(revisionSlice.actions.revisionFilterSet(buildInitialFilter()));
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <ButtonGroup
        orientation={
          useMediaQuery(theme.breakpoints.up("md")) ? "horizontal" : "vertical"
        }
        fullWidth={useMediaQuery(theme.breakpoints.up("md")) ? false : true}
      >
        <Button
          onClick={handleOpen}
          variant={"outlined"}
          color={filterEnabled ? "warning" : "primary"}
          startIcon={<FilterList />}
        >
          Filtrovat
        </Button>
        <Button
          onClick={() => {
            handleReset();
          }}
          variant={"outlined"}
          disabled={!filterEnabled}
          startIcon={<Clear />}
        >
          Zrušit filtr
        </Button>
        <Button
          onClick={() => {
            dispatch(
              revisionSlice.actions.revisionFilterPropertySet({
                property: "expirationStatusIds",
                value: [REVISION_EXPIRATION_STATUS_after],
              })
            );
          }}
          variant={"outlined"}
          startIcon={<SentimentVeryDissatisfied />}
        >
          Zobrazit po expiraci
        </Button>
        <Button
          onClick={() => {
            dispatch(
              revisionSlice.actions.revisionFilterPropertySet({
                property: "expirationStatusIds",
                value: [REVISION_EXPIRATION_STATUS_expiring],
              })
            );
          }}
          variant={"outlined"}
          startIcon={<SentimentNeutral />}
        >
          Zobrazit expirující
        </Button>
        <Button
          onClick={() => {
            dispatch(
              revisionSlice.actions.revisionFilterPropertySet({
                property: "withDefectsOnly",
                value: true,
              })
            );
          }}
          variant={"outlined"}
          startIcon={<ReportProblem />}
        >
          Se závadami
        </Button>
      </ButtonGroup>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            p: 2,
            background: (theme) => `${theme.palette.background.default}`,
            width: {
              xs: "100%",
              md: "40%",
            },
          },
        }}
      >
        <CloseButton onClick={handleClose} />

        <Button
          onClick={() => {
            handleReset();
          }}
          size={"small"}
        >
          Zrušit filtr
        </Button>

        <Divider sx={{ mt: 1, mb: 1 }} />

        <RevisionFilterContent />
      </Popover>
    </>
  );
};

export default RevisionFilter;
