//@ts-nocheck

import React from "react";
import { Chip } from "@mui/material";
import { getIssueStatusByCode } from "../../../../libraries/enums/issueStatuses";

const ChipIssueStatus = (props) => {
  const { issueStatusCode, label, active = true, size = "medium" } = props;

  const issueStatus = getIssueStatusByCode(issueStatusCode);

  if (!issueStatus) {
    return <></>;
  }

  return (
    <Chip
      icon={React.createElement(issueStatus.icon)}
      label={label}
      size={size}
      sx={{
        bgcolor: issueStatus.bgcolor,
        opacity: active ? 1 : 0.2,
        transition: "all 250ms",
      }}
    />
  );
};

export default ChipIssueStatus;
