import IssueList from "../IssueList/IssueList";
import { useEffect } from "react";
import { useAppDispatch } from "../../../../app/store";
import issueSlice from "../../slices/issueSlice";
import IssueCreateButton from "../IssueCreateButton/IssueCreateButton";
import { Grid, Paper } from "@mui/material";
import PageHeading from "../../../common/components/PageHeading/PageHeading";
import { Container } from "@mui/system";
import enumSlice from "../../../enum/slices/enumSlice";
import MarkAsDoneConfirmDialog from "../MarkAsDoneConfirmDialog/MarkAsDoneConfirmDialog";

const Issue = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(enumSlice.actions.enumsFetch());
    dispatch(issueSlice.actions.issueListFetch());
  }, []);

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <PageHeading pageName="Servisní požadavky" />
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={6}
            sx={{
              justifyContent: {
                md: "flex-end",
              },
              alignItems: {
                md: "center",
              },
            }}
          >
            <IssueCreateButton />
          </Grid>

          <Grid item xs={12}>
            <Paper
              sx={{ border: (theme) => `1px solid ${theme.palette.divider}` }}
              elevation={0}
            >
              <IssueList />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <MarkAsDoneConfirmDialog />
    </>
  );
};

export default Issue;
